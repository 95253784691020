import React from 'react';

import { Input } from 'components/bootstrap';
import type { ValidationState } from 'components/common/types';

type ThreatFoxAdapterFieldSetProps = {
  config: {
    include_older_iocs: boolean;
    refresh_interval: number;
    case_insensitive_lookup: boolean;
  };
  updateConfig: (...args: any[]) => void;
  handleFormEvent: (...args: any[]) => void;
  validationState: (...args: any[]) => ValidationState;
  validationMessage: (...args: any[]) => string;
};

const ThreatFoxAdapterFieldSet = ({
  config,
  handleFormEvent,
  validationMessage,
  validationState,
}: ThreatFoxAdapterFieldSetProps) => (
  <fieldset>
    <Input type="checkbox"
           id="include_older_iocs"
           name="include_older_iocs"
           label="Include IOCs Older Than 90 Days"
           checked={config.include_older_iocs}
           onChange={handleFormEvent}
           wrapperClassName="col-md-offset-3 col-md-9" />
    <Input type="number"
           id="refresh_interval"
           name="refresh_interval"
           label="Refresh Interval (seconds)"
           required
           onChange={handleFormEvent}
           help={validationMessage('refresh_interval', 'How often to fetch new data from the source (minimum 3600 seconds).')}
           bsStyle={validationState('refresh_interval')}
           value={config.refresh_interval}
           labelClassName="col-sm-3"
           wrapperClassName="col-sm-9" />
    <Input type="checkbox"
           id="case_insensitive_lookup"
           name="case_insensitive_lookup"
           label="Case Insensitive Lookups"
           checked={config.case_insensitive_lookup}
           onChange={handleFormEvent}
           wrapperClassName="col-md-offset-3 col-md-9" />
  </fieldset>
);

export default ThreatFoxAdapterFieldSet;
