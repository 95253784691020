import React from 'react';

import type { LicenseState } from 'license/types';
import LicenseStatus from 'license/LicenseStatus';

type Props = {
  status: LicenseState,
}

const StatusCell = ({ status }: Props) => (
  <LicenseStatus status={status} />
);

export default StatusCell;
