interface Route<T> {
  INTEGRATIONS: {
    Sophos: {
      [key: string]: T
    },
  },
}

const SophosRoutes: Route<{ index: string }> = {
  INTEGRATIONS: {
    Sophos: {
      ACTIVITYAPI: {
        index: '/integrations/sophos',
      },
    },
  },
};

const ApiRoutes: Route<string> = {
  INTEGRATIONS: {
    Sophos: {
      SAVE_INPUT: '/plugins/org.graylog.enterprise.integrations/sophos/inputs',
      CHECK_CREDENTIALS: '/plugins/org.graylog.enterprise.integrations/sophos/check_credentials',
    },
  },
};

const DocsRoutes: Route<string> = {
  INTEGRATIONS: {
    Sophos: {
      GRAYLOG_Sophos_ACTIVITY_LOG_INPUT: 'integrations/inputs/graylog_sophos_ActivityLog_Input.html#graylog-sophos-activitylog-input',
    },
  },
};

export default SophosRoutes;

export { DocsRoutes, ApiRoutes };
