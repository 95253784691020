import * as React from 'react';
import styled from 'styled-components';

import { Input, Row, Col } from 'components/bootstrap';
import { FormikInput } from 'components/common';

const StyledUl = styled.ul`
  list-style-type: circle;
`;
export const PASSWORD_MIN_LENGTH = 16;

export const validateCloudPasswords = (errors: { [name: string]: string }, password: string, passwordRepeat: string) => {
  const newErrors = { ...errors };
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;

  if (password && password.length < PASSWORD_MIN_LENGTH) {
    newErrors.password = `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
  }

  if (!pattern.test(password)) {
    newErrors.password = 'The password does not match the requirements below.';
  }

  if (password && passwordRepeat) {
    const passwordMatches = password === passwordRepeat;

    if (!passwordMatches) {
      newErrors.password_repeat = 'Passwords do not match';
    }
  }

  return newErrors;
};

type Props = {};

// eslint-disable-next-line no-empty-pattern
const CloudPasswordFormGroup = ({}: Props) => {
  const passwordHelp = () => (
    <>
      Password requirements:
      <StyledUl>
        <li>{`Minimum length: ${PASSWORD_MIN_LENGTH} characters`}</li>
        <li>Contains at least one lower case letter</li>
        <li>Contains at least one upper case letter</li>
        <li>Contains at least one number (0-9)</li>
        <li>Does not contain part of your username</li>
      </StyledUl>
    </>
  );

  return (
    <Input id="password-field"
           label="Password"
           help={passwordHelp()}
           labelClassName="col-sm-3"
           wrapperClassName="col-sm-9">
      <Row className="no-bm">
        <Col sm={6}>
          <FormikInput name="password"
                       id="password"
                       type="password"
                       placeholder="Password"
                       required
                       formGroupClassName="form-group no-bm"
                       wrapperClassName="col-xs-12"
                       minLength={PASSWORD_MIN_LENGTH} />
        </Col>
        <Col sm={6}>
          <FormikInput name="password_repeat"
                       id="password_repeat"
                       type="password"
                       placeholder="Repeat password"
                       formGroupClassName="form-group no-bm"
                       required
                       wrapperClassName="col-xs-12"
                       minLength={PASSWORD_MIN_LENGTH} />
        </Col>
      </Row>
    </Input>
  );
};

export default CloudPasswordFormGroup;
