import * as React from 'react';
import { useRef, useEffect, useContext, useCallback } from 'react';
import styled, { css } from 'styled-components';

import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { Message } from 'views/components/messagelist/Types';
import CopyToClipboardCapture from 'components/common/CopyToClipboardCapture';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import InvalidLicenseWidgetInfo from 'search/InvalidLicenseWidgetInfo';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import type { After, OnLoadMessages, LogViewMessage } from 'logview/types';

import ExportSettingsTimerange from './ExportSettingsTimerange';
import MessageDetailsProvider from './contexts/MessageDetailsProvider';
import LogViewMessageDetails from './LogViewMessageDetails';
import LogViewTable from './LogViewTable';
import LogViewStateProvider from './contexts/LogViewStateProvider';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

export type PageRefs = {
  current: {[pageId: number]: HTMLDivElement | undefined },
}

export type TableRef = {
  current: HTMLDivElement | undefined,
}

const Container = styled(CopyToClipboardCapture)(({ theme }) => css`
  font-size: ${theme.fonts.size.small};
  height: 100%;
  overflow-x: auto;
  position: relative;
`);

const _formatOnCopyToClipboard = (selection: Selection) => selection.toString().split(/\n\u200b\n|\u200c\n/).join(' ');

type Props = {
  config: LogViewWidgetConfig,
  effectiveTimerange: AbsoluteTimeRange,
  initialAfter: After,
  messages: Array<LogViewMessage>, // messages need to be sorted
  editing: boolean,
  setLoadingState: (loading: boolean) => void,
  fields: FieldTypeMappingsList,
  onChangeConfig: (newConfig: LogViewWidgetConfig) => Promise<unknown>
  onLoadMessages: OnLoadMessages, // promise to load more pages, returned messages need to be sorted
  children?: (payload: { resetListState: () => void }) => React.ReactNode,
  onLoadMessage: (message: unknown) => Promise<Message>,
  total?: number,
  enableMessageDetails?: boolean
};

const LogViewWidget = ({
  children, total, effectiveTimerange, onLoadMessage,
  config, initialAfter, messages, setLoadingState, editing,
  fields, onChangeConfig, onLoadMessages, enableMessageDetails = true,
}: Props) => {
  const columns = config.fields;
  const tableRef = useRef<HTMLDivElement | null>(null);
  const pageRefs = useRef<{[pageId: number]: HTMLDivElement | undefined }>({});
  const renderComplete = useContext(RenderCompletionCallback);

  const _onChangeConfig = useCallback((newConfig: LogViewWidgetConfig) => {
    setLoadingState(true);

    return onChangeConfig(newConfig).then(() => setLoadingState(false));
  }, [onChangeConfig, setLoadingState]);

  useEffect(() => { renderComplete(); }, [renderComplete]);

  return (
    <>
      <InvalidLicenseWidgetInfo featureName="log view" />
      <LogViewStateProvider pageRefs={pageRefs}
                            total={total}
                            onLoadMessages={onLoadMessages}
                            initialAfter={initialAfter}
                            effectiveTimerange={effectiveTimerange}
                            messages={messages}
                            tableRef={tableRef}>
        {(({ resetListState }) => (
          <MessageDetailsProvider>
            <ExportSettingsTimerange config={config} />
            <Container formatSelection={_formatOnCopyToClipboard}>
              {children?.({ resetListState })}
              <LogViewTable columns={columns}
                            fields={fields}
                            enableMessageDetails={enableMessageDetails}
                            tableRef={tableRef}
                            pageRefs={pageRefs} />
              <LogViewMessageDetails editing={editing}
                                     onLoadMessage={onLoadMessage}
                                     onChangeConfig={_onChangeConfig}
                                     widgetConfig={config} />
            </Container>
          </MessageDetailsProvider>
        ))}
      </LogViewStateProvider>
    </>
  );
};

export default LogViewWidget;
