import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

const BOX_SIZE = '15px';

type Props = {
  theme: DefaultTheme,
};

// eslint-disable-next-line import/prefer-default-export
export const StyledCheckbox = styled.div(({ theme }: Props) => css`
  position: relative;
  float: left;
  width: ${BOX_SIZE};
  height: ${BOX_SIZE};
  padding-right: ${BOX_SIZE};

  & > input[type="checkbox"] {
    opacity: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: ${BOX_SIZE};
    height: ${BOX_SIZE};
    cursor: pointer;
    z-index: 1;
  }

  & > .styled-checkbox {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${BOX_SIZE};
    height: ${BOX_SIZE};
    border: 2px solid #aaa;
    border-radius: 4px;
    cursor: pointer;
  }

  & > input[type="checkbox"]:checked + .styled-checkbox,
  & > input[type="checkbox"]:indeterminate + .styled-checkbox{
    border: none;
  }

  & > input[type="checkbox"]:checked + .styled-checkbox::after {
    content: '';
    position: absolute;
    top: 0;
    left: 6px;
    width: 6px;
    height: 12px;
    border: 2px solid ${theme.colors.button.primary.background};
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
  }

  & > input[type="checkbox"]:indeterminate + .styled-checkbox::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 2px;
    width: 14px;
    height: 9px;
    border-bottom: 2px solid ${theme.colors.button.primary.background};
  }
`);
