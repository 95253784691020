import * as React from 'react';
import styled, { css } from 'styled-components';
import { PluginStore, PluginManifest } from 'graylog-web-plugin/plugin';

import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts/InvestigationDrawer';
import { useGetInvestigationDetails } from 'security-app/hooks/useInvestigationsAPI';
import { Icon, Spinner } from 'components/common';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import AIReportButton from 'security-app/components/Investigations/AIReport/AIReportButton';
import useIsInvestigationAIReportEnabled
  from 'security-app/components/Investigations/AIReport/hooks/useIsInvestigationAIReportEnabled';

import Details from './Details';
import Evidence from './Evidence';
import Notes from './Notes';

const Backdrop = styled.div<{ $collapsed?: boolean }>(({ $collapsed }) => css`
  position: fixed;
  display: ${$collapsed ? 'none' : 'flex'};
  flex-direction: row;
  justify-content: flex-end;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1038;
`);

const drawerWidth = 500;
const Drawer = styled.div<{ $collapsed?: boolean }>(({ $collapsed, theme }) => css`
  position: fixed;
  top: 50px;
  right: ${$collapsed ? -drawerWidth : '0'}px;
  bottom: 0;
  width: ${drawerWidth}px;
  font-size: .85rem;

  display: flex;
  flex-direction: column;

  background-color: ${theme.colors.global.background};
  border-left: 1px solid ${theme.colors.input.border};

  transition: all 0.3s ease-in-out;

  z-index: 1039;
`);

const DrawerContainer = styled.div`
  padding: 1rem;
  overflow-y: auto;
`;

const controlsWidth = 24;
const Controls = styled.div<{ $collapsed: boolean }>(({ $collapsed, theme }) => css`
  position: absolute;
  top: 7px;
  left: -${controlsWidth}px;
  width: ${controlsWidth}px;
  padding: 8px 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  border: 1px solid ${theme.colors.input.border};
  background-color: ${theme.colors.global.background};
  border-right: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  cursor: pointer;

  & > svg {
    transition: all 0.1s ease-in-out;
  }

  & > .collaps-trigger {
    transform: rotate(${$collapsed ? '180' : '0'}deg);
  }

  & > .close-trigger {
    color: ${theme.colors.brand.primary};
  }
`);

const SectionDivider = styled.hr(({ theme }) => css`
  border-color: ${theme.colors.input.border};
  margin: 16px 0;
`);

const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function InvestigationDrawer() {
  const {
    collapsed,
    setCollapsed,
    showDrawer,
    setShowDrawer,
    selectedInvestigationId,
    setSelectedInvestigationId,
  } = useInvestigationDrawer();
  const sendTelemetry = useSendTelemetry();
  const isAIReportFeatureEnabled = useIsInvestigationAIReportEnabled();
  const { loadingInvestigationDetails, currentInvestigation } = useGetInvestigationDetails(selectedInvestigationId);
  const { activePerspective: { id: activePerspectiveId } } = useActivePerspective();

  React.useEffect(() => setShowDrawer(!!selectedInvestigationId), [setShowDrawer, selectedInvestigationId]);

  const removeLocalStorageInvestigationId = () => {
    localStorage.removeItem('currentInvestigationId');
  };

  React.useEffect(() => {
    if (activePerspectiveId !== 'default' && !!selectedInvestigationId) {
      setShowDrawer(false);
      setCollapsed(true);
      removeLocalStorageInvestigationId();
      setSelectedInvestigationId(null);
    }
  }, [showDrawer, activePerspectiveId, selectedInvestigationId, setCollapsed, setSelectedInvestigationId, setShowDrawer]);

  PluginStore.register(new PluginManifest({}, { 'hooks.logout': [removeLocalStorageInvestigationId] }));

  const onClose = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_DRAWER_CLOSED, {
      app_pathname: 'security',
      app_section: 'investigation',
    });

    setShowDrawer(false);
    setCollapsed(false);
    removeLocalStorageInvestigationId();
    setSelectedInvestigationId(null);
  };

  const onCollapseToggle = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_DRAWER_COLLAPSE_TOGGLED, {
      app_pathname: 'security',
      app_section: 'investigation',
      event_details: {
        collapsed: !collapsed,
      },
    });

    setCollapsed(!collapsed);
  };

  return (
    <>
      <Backdrop $collapsed={collapsed} onClick={() => setCollapsed(true)} />
      <Drawer $collapsed={collapsed}>
        <Controls $collapsed={collapsed}>
          <Icon className="close-trigger"
                name="close"
                title="close drawer"
                onClick={onClose} />
          <Icon className="collaps-trigger"
                name="chevron_right"
                title={`${collapsed ? 'Expand' : 'Collapse'} details`}
                onClick={() => onCollapseToggle()} />
        </Controls>
        {loadingInvestigationDetails || !currentInvestigation
          ? <Spinner text="Loading investigation details..." />
          : (
            <DrawerContainer style={{ overflowY: 'auto' }}>
              <HeaderDiv>
                <h3>Investigation Details</h3>
                {isAIReportFeatureEnabled && (
                  <AIReportButton investigation={currentInvestigation}
                                  small
                                  logsCount={currentInvestigation?.logs?.length} />
                )}
              </HeaderDiv>
              <SectionDivider />
              <Details investigation={currentInvestigation} />
              <SectionDivider />
              <Evidence investigation={currentInvestigation} />
              <SectionDivider />
              <Notes investigation={currentInvestigation} />
            </DrawerContainer>
          )}
      </Drawer>
    </>
  );
}

export default InvestigationDrawer;
