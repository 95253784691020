import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import SophosRoutes from './Routes';

const SophosInputConfiguration = () => {
  const history = useHistory();

  useEffect(() => {
    const url = SophosRoutes.INTEGRATIONS.Sophos.ACTIVITYAPI.index;
    history.push(url);
  }, [history]);

  return null;
};

export default SophosInputConfiguration;
