import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';
import useFeature from 'hooks/useFeature';
import useDataWarehouseConfig from 'data-warehouse/configurations/hooks/useDataWarehouseConfig';
import type { IconName } from 'components/common/Icon';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

export const dataWarehouseLink = {
  link: DATA_WAREHOUSE_ROUTES.SEARCH,
  key: 'data-warehouse',
  icon: 'warehouse' as IconName,
  title: 'Data Warehouse Search',
  useCondition: () => {
    const currentUser = useCurrentUser();
    const userIsPermitted = isPermitted(currentUser.permissions, 'archive:read');

    const featureFlagIsEnabled = useFeature('data_warehouse_search');
    const { isInitialLoading, data } = useDataWarehouseConfig(userIsPermitted);

    if (!userIsPermitted || !featureFlagIsEnabled || isInitialLoading) {
      return false;
    }

    return !!data.active_backend;
  },
};
