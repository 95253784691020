import * as React from 'react';
import styled from 'styled-components';

import { Icon, IfPermitted } from 'components/common';
import { Link } from 'components/common/router';

const SubHeader = styled.div`
  display: inline-flex;
  align-items: center;
`;

const EditLink = styled(Link)(({ theme }) => `
  margin-right: 6px;
  color: ${theme.colors.gray[60]};
`);

type HeaderProps = {
  description?: React.ReactNode,
  editPermissions: Array<string>,
  entityName: string
  link: string,
}

const ContentSelectionSubheader = ({ description, link, editPermissions, entityName }: HeaderProps) => (
  <SubHeader>
    <IfPermitted permissions={editPermissions} anyPermissions>
      <EditLink to={link} target="_blank" title={`Edit actual ${entityName} in new tab`}>
        <Icon name="edit_square" size="sm" />
      </EditLink>
    </IfPermitted>
    {description}
  </SubHeader>
);

export default ContentSelectionSubheader;
