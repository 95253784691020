import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { defaultCompare as naturalSort } from 'logic/DefaultCompare';
import { ButtonGroup, Button, ControlLabel, FormGroup, HelpBlock, Input, Alert } from 'components/bootstrap';
import { TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY } from 'stores/indices/IndicesStore';
import type { RetentionJsonSchema } from 'components/indices/Types';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { ARCHIVE_LICENSE_WARNING_TITLE, ARCHIVE_LICENSE_WARNING_TEXT } from 'archive/constants';

const StyledAlert = styled(Alert)`
  overflow: auto;
`;

type Props = {
  config: {
    max_number_of_indices: number,
    index_action: string,
  },
  jsonSchema?: RetentionJsonSchema
  updateConfig: Function,
  useMaxNumberOfIndices: () => [
    number | undefined,
    React.Dispatch<React.SetStateAction<number>>
  ],
};

const ArchiveRetentionStrategyConfiguration = ({ config, jsonSchema, updateConfig, useMaxNumberOfIndices }: Props) => {
  const [maxNumberOfIndices, setMaxNumberOfIndices] = useMaxNumberOfIndices();

  const [configuration, setConfiguration] = useState(config);
  const {
    values: {
      rotation_strategy_class: rotationStrategyClass,
    },
  } = useFormikContext<any>();

  const onMaxIndicesUpdate = (e) => {
    const { value } = e.target;
    const update = configuration;

    update.max_number_of_indices = value;

    setMaxNumberOfIndices(value);
    setConfiguration(update);
    updateConfig(update);
  };

  const setIndexAction = (type: string) => () => {
    setConfiguration({ ...configuration, index_action: type });
    updateConfig({ ...configuration, index_action: type });
  };

  const indexActionActive = (type) => {
    const { index_action } = configuration;

    return index_action === type;
  };

  const hasValidSchema = () => !!jsonSchema;

  const indexActions = () => {
    if (hasValidSchema()) {
      const newIndexActions = jsonSchema.properties.index_action.enum;
      newIndexActions.sort((a, b) => naturalSort(a.toLowerCase(), b.toLowerCase()));

      return newIndexActions;
    }

    return [];
  };

  // Get all available index actions from the JSON schema.
  const buttons = indexActions()?.map((action) => {
    const isActive = indexActionActive(action);

    return (
      <Button key={`button-${action}`}
              bsSize="small"
              onClick={setIndexAction(action)}
              active={isActive}
              bsStyle={isActive ? 'info' : 'default'}>
        {action}
      </Button>
    );
  });

  return (
    <div>
      <LicenseCheck text={ARCHIVE_LICENSE_WARNING_TEXT}
                    title={ARCHIVE_LICENSE_WARNING_TITLE}
                    bsStyle="danger"
                    licenseSubject={LICENSE_SUBJECTS.archive} />
      <StyledAlert>
        The archive index retention strategy creates an archive for an index before deleting or closing it.
      </StyledAlert>
      {rotationStrategyClass !== TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY && (
      <fieldset>
        <Input type="number"
               id="max-number-of-indices"
               label="Max number of indices"
               onChange={onMaxIndicesUpdate}
               value={maxNumberOfIndices}
               help={<span>Maximum number of indices to keep before <strong>archiving</strong> the oldest ones</span>}
               autoFocus
               required />
      </fieldset>
      )}
      {hasValidSchema() && (
        <FormGroup id="action-after-archive">
          <ControlLabel>Action after archiving index</ControlLabel>
          <ButtonGroup>{buttons}</ButtonGroup>
          <HelpBlock>Please select which action should be performed once an index has been archived.</HelpBlock>
        </FormGroup>
      )}
    </div>
  );
};

export default ArchiveRetentionStrategyConfiguration;
