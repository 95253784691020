import * as React from 'react';
import { useCallback, useState, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import Store from 'logic/local-storage/Store';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_ROUTES, HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY } from 'data-warehouse/Constants';
import DataWarehouseActionModalForm from 'data-warehouse/components/DataWarehouseActionModalForm';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import useUserDateTime from 'hooks/useUserDateTime';
import fetch from 'logic/rest/FetchProvider';
import { Alert } from 'components/bootstrap';
import { Link } from 'components/common/router';
import { handleFailures } from 'data-warehouse/data-warehouse-overview/actions/Utils';
import StreamsContext from 'contexts/StreamsContext';
import type { DataWarehouseActionValues } from 'data-warehouse/Types';

type Props = {
  archiveStreamId: string,
  onToggleModal: () => void
};

const RetrievalArchiveModal = ({
  archiveStreamId,
  onToggleModal,
}: Props) => {
  const queryClient = useQueryClient();
  const [hideHelpText, setHideHelpText] = useState(Store.get(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY) === 'true');
  const { userTimezone } = useUserDateTime();
  const streams = useContext(StreamsContext);

  const availableStreams = streams.map((stream) => ({
    key: stream.title,
    value: stream.id,
  }));

  const hideRetrievalHelp = () => {
    Store.set(HIDE_DATAWAREHOUSE_RETRIEVAL_HELP_TEXT_KEY, 'true');
    setHideHelpText(true);
  };

  const onRestoreSubmit = useCallback((values: DataWarehouseActionValues) => {
    const uriParams = getDataWarehouseUriParams(values, userTimezone);
    const { stream_ids, inclusion_type, field_filters } = values;

    fetch(
      'POST',
      qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.bulk_retrieval().url}?${uriParams}`),
      { stream_ids, inclusion_type, field_filters },
    ).then((response) => {
      handleFailures(response?.failures);
    }).catch((error) => {
      UserNotification.error(`An error occurred while retrieving data. ${error}`);
    }).finally(() => {
      queryClient.invalidateQueries(['data-warehouse']);
      onToggleModal();
    });
  }, [userTimezone, onToggleModal, queryClient]);

  return (
    <DataWarehouseActionModalForm modalTitle="Retrieving from Data Warehouse"
                                  type="retrieval"
                                  availableStreams={availableStreams}
                                  show
                                  onHide={onToggleModal}
                                  submitButtonText="Retrieve"
                                  streamIds={[archiveStreamId]}
                                  onSubmit={onRestoreSubmit}>
      <p>You are about to retrieve data from 1 Data Warehouse.</p>
      {!hideHelpText && (
        <Alert bsStyle="info" onDismiss={hideRetrievalHelp}>
          This will launch a retrieval operation to route messages from the Data Warehouse into the <b>Search Cluster</b>.<br />
          Retrieved messages that were not previously routed to the <b>Search Cluster </b> will count towards <b>Graylog License</b> usage.<br />
          Note that pulling messages from the Data Warehouse may take some time - progress may be tracked on
          the <Link to={DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST}><b>Data Warehouse</b></Link> page.
        </Alert>
      )}
    </DataWarehouseActionModalForm>
  );
};

export default RetrievalArchiveModal;
