import React from 'react';

import { Panel } from 'components/bootstrap';
import type { Forwarder } from 'forwarder/Types';

import ForwarderSelectionList from '../ForwarderSelectionList';

type Props = {
  eventKey: string,
  forwarders: Array<Forwarder>,
  onForwarderSelect: () => void,
};

const SelectNewForwarderStep = ({ eventKey, forwarders, onForwarderSelect }: Props) => (
  <Panel eventKey={eventKey}>
    <Panel.Heading>
      <Panel.Title>
        <Panel.Toggle tabIndex={0}>4. Select Forwarder</Panel.Toggle>
      </Panel.Title>
    </Panel.Heading>
    <Panel.Body collapsible>
      <p>
        Once the new Forwarder is configured as stated above and started, it will register with Graylog
        and appear in the list below.
      </p>
      <ForwarderSelectionList forwarders={forwarders} onForwarderSelect={onForwarderSelect} />
    </Panel.Body>
  </Panel>
);

export default SelectNewForwarderStep;
