import * as React from 'react';
import { useMemo } from 'react';
import { FieldArray, Field, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { useGetAssets } from 'security-app/hooks/useAssetsAPI';
import { Button, HelpBlock, Input } from 'components/bootstrap';
import { IconButton, Select, Spinner } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';

import DataWarehouseFilterValueField from './DataWarehouseFilterValueField';

import type { DataWarehouseActionFormValues } from '../Types';
import useDataWarehouseFilterableField from '../hooks/useDataWarehouseFilterableField';

const MAX_FILTERS = 3;

type Props ={
  availableStreams: Array<{ key: string, value: string }>,
}
const FieldContainer = styled.div`
  flex-basis: 100%;
`;
const RemoveContainer = styled.div`
  flex-basis: content;
  display: flex;
  align-items: center;
`;
const StyledLabel = styled.h5(({ theme }) => css`
  font-weight: bold;
  margin-bottom: ${theme.spacings.xxs};
`);
const List = styled.div`
  display: flex;
  flex-direction: column;
`;
const FilterGroup = styled.div(({ theme }) => css`
  flex-grow: 1;
  display: flex;
  gap: ${theme.spacings.xxs};
`);
const Item = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.xxs};
`);

const DataWarehouseRetrievalFiltersField = ({ availableStreams }: Props) => {
  const { values: { fieldFilters: filters }, setFieldValue } = useFormikContext<DataWarehouseActionFormValues>();
  const { data: filterableFields, isInitialLoading: isLoadingFilterableField } = useDataWarehouseFilterableField();
  const { assets, loadingAssets: isLoadingAssets } = useGetAssets({ page: 1, perPage: 0 });
  const isEmpty = (filters ?? []).length === 0;
  const filterableFieldsOptions = useMemo(() => filterableFields?.sort((s1, s2) => defaultCompare(s1.field_name, s2.field_name))
    .map(({ field_name }) => ({
      value: field_name,
      label: field_name,
    })), [filterableFields]);
  const assetsOptions = useMemo(() => assets?.sort((s1, s2) => defaultCompare(s1.name, s2.name))
    .map(({ id, name }) => ({
      value: id,
      label: name,
    })), [assets]);

  return (
    <FieldArray name="fieldFilters"
                validateOnChange={false}
                render={({ remove, push }) => (
                  <>
                    <StyledLabel>Filter by fields</StyledLabel>
                    <HelpBlock>
                      Here you can add field filters.
                    </HelpBlock>
                    {isLoadingFilterableField && <Spinner />}
                    {(!isEmpty && !isLoadingFilterableField && !isLoadingAssets) && (
                    <List>
                      {filters.map((filter, index) => (
                        /* eslint-disable-next-line react/no-array-index-key */
                        <Item key={`filter-field-${filter.field_name}-${index}`}>
                          <FilterGroup>
                            <Field name={`fieldFilters.${index}.field_name`} required>
                              {({ field: { name, value }, meta: { error } }) => (
                                <FieldContainer>
                                  <Input error={error}
                                         name={name}
                                         id={`field_name-${index}`}>
                                    <Select options={filterableFieldsOptions}
                                            value={value}
                                            inputId={name}
                                            onChange={(newVal) => {
                                              setFieldValue(`fieldFilters.${index}.field_name`, newVal);
                                            }}
                                            placeholder="Field name"
                                            allowCreate={false} />
                                  </Input>
                                </FieldContainer>
                              )}
                            </Field>
                            <DataWarehouseFilterValueField fieldName={`fieldFilters.${index}.value`}
                                                           field={filterableFields.find((field) => field.field_name === filter.field_name)}
                                                           assetsOptions={assetsOptions}
                                                           availableStreams={availableStreams} />
                            <RemoveContainer>
                              {(filters.length > 0) && <IconButton name="delete" onClick={() => (remove(index))} title="Remove field" />}
                            </RemoveContainer>
                          </FilterGroup>
                        </Item>
                      ))}
                    </List>
                    )}
                    <Button disabled={filters?.length === MAX_FILTERS} bsSize="xs" onClick={() => push({ field_name: '', value: '' })} name="plus" title="Add filter field">Add field</Button>
                  </>
                )} />
  );
};

export default DataWarehouseRetrievalFiltersField;
