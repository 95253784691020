import React from 'react';

import CorrelationRule from './CorrelationRule';
import commonStyles from './commonStyles.css';

type CorrelationRulesProps = {
  rules: any[];
  eventDefinitions: any[];
  onChange: (...args: any[]) => void;
  onRemove: (...args: any[]) => void;
};

class CorrelationRules extends React.Component<CorrelationRulesProps, {
  [key: string]: any;
}> {
  renderEventRule = (correlationRule, idx = 0) => {
    const { rules, eventDefinitions, onChange, onRemove } = this.props;

    return (
      <React.Fragment key={correlationRule.id}>
        <CorrelationRule number={idx + 1}
                         correlationRule={correlationRule}
                         eventDefinitions={eventDefinitions}
                         onChange={onChange}
                         onRemove={onRemove} />
        {idx + 1 < rules.length && <p className={commonStyles.followedBy}>Followed by</p>}
      </React.Fragment>
    );
  };

  render() {
    const { rules } = this.props;

    return rules.map(this.renderEventRule);
  }
}

export default CorrelationRules;
