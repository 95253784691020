import * as React from 'react';
import mapValues from 'lodash/mapValues';
import styled from 'styled-components';

import { PaginatedList, Spinner, EntityList, EntityListItem } from 'components/common';
import DataWarehouseBackendButton from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendButton';
import DeleteDataWarehouseAction from 'data-warehouse/data-warehouse-backend-config/backend-overview/actions/DeleteDataWarehouseAction';
import type { Backend } from 'archive/types';
import AppConfig from 'util/AppConfig';
import { ButtonToolbar, Col, Row } from 'components/bootstrap';
import { BACKEND_TYPES } from 'archive/ArchiveStore';
import { ConfigurationWell } from 'components/configurationforms';
import { DEFAULT_PAGE_SIZES } from 'hooks/usePaginationQueryParameter';
import DataWarehouseBackendActivateButton from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendActivateButton';
import useDataWarehouseBackends
  from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackends';
import useDataWarehouseConfig from 'data-warehouse/configurations/hooks/useDataWarehouseConfig';

import DataWarehouseBackendState from './DataWarehouseBackendState';

const isCloud = AppConfig.isCloud();
const StyledRow = styled(Row)`
  dt {
    float: left;
    width: 200px;
    overflow: hidden;
    clear: left;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  dd {
    margin-left: 180px;
    display: inline-block;
  }

  dl {
    margin-bottom: 0;
  }
`;

const BackendConfigOverview = () => {
  const { data: backendsList, isInitialLoading: isLoadingBackends, refetch: refetchDataWarehouseBackend } = useDataWarehouseBackends({ enabled: true });
  const { data: dataWarehouseBackendConfig, isSuccess: isCurrentBackendLoaded, isInitialLoading: isLoadingDataWarehouseConfig } = useDataWarehouseConfig();

  if (isLoadingBackends || isLoadingDataWarehouseConfig) {
    return <Spinner />;
  }

  const formatBackend = (backend: Backend) => {
    const { settings, description, id, title } = backend;
    const isActiveBackend = isCurrentBackendLoaded && dataWarehouseBackendConfig.active_backend === id;

    const actions = (
      !isCloud && (
        <ButtonToolbar>
          <DataWarehouseBackendActivateButton backend={backend} isActiveBackend={isActiveBackend} config={dataWarehouseBackendConfig} />
          <DataWarehouseBackendButton buttonType="menuItem" backendId={id} />
          {!isActiveBackend && <DeleteDataWarehouseAction backend={backend} refetchDataWarehouseBackend={refetchDataWarehouseBackend} />}
        </ButtonToolbar>
      )
    );

    const normalizedSettings = mapValues(settings, (value, key: string) => {
      if (key === 'type') {
        return BACKEND_TYPES[settings[key]];
      }

      if (key === 'aws_secret_access_key') {
        return '***********';
      }

      return value;
    });

    const content = (
      <Col md={12}>
        <StyledRow>
          <Col md={12}>
            <dl>
              <ConfigurationWell id={id}
                                 configuration={normalizedSettings} />
            </dl>
          </Col>
        </StyledRow>
      </Col>
    );

    return (
      <EntityListItem key={`backend-${id || 'newbackend'}`}
                      title={<>{title}{' '}<DataWarehouseBackendState isActiveBackend={isActiveBackend} /></>}
                      description={description}
                      actions={actions}
                      contentRow={content} />
    );
  };

  return (
    <PaginatedList pageSize={DEFAULT_PAGE_SIZES[1]}
                   showPageSizeSelect={false}
                   totalItems={backendsList?.length}>
      <EntityList bsNoItemsStyle="info"
                  noItemsText="There are no backends to display"
                  items={backendsList?.map((backend) => formatBackend(backend))} />
    </PaginatedList>
  );
};

export default BackendConfigOverview;
