import * as React from 'react';
import { useEffect } from 'react';

import { Col, Row } from 'components/bootstrap';
import { LinkToNode, Spinner } from 'components/common';
import AuditLogActions from 'auditlog/AuditLogActions';
import type { AuditLogStatus as AuditLogStatusType } from 'auditlog/AuditLogStore';
import AuditLogStore from 'auditlog/AuditLogStore';
import AuditLogStatusStyle from 'auditlog/AuditLogStatus.css';
import { useStore } from 'stores/connect';

const AuditLogStatus = () => {
  const { auditLogStatus } = useStore(AuditLogStore);

  useEffect(() => {
    AuditLogActions.status();
  }, []);

  const _formatMongodbAuditLog = () => Object.keys(auditLogStatus).sort().map((nodeId) => {
    const nodeStatus = auditLogStatus[nodeId];

    return (
      <div key={nodeId}>
        <h4>Node <LinkToNode nodeId={nodeId} /></h4>
        <dl className={AuditLogStatusStyle.descriptionList}>
          <dt>Message signing status</dt>
          <dd>{nodeStatus.message_signing_enabled ? 'Enabled' : 'Disabled'}</dd>
          <dt>MongoDB collection name</dt>
          <dd><code>{nodeStatus.mongodb_collection_name}</code></dd>
        </dl>
      </div>
    );
  });

  const _formatLog4jAppenderConfiguration = (log4jConfiguration: AuditLogStatusType) => {
    const log4jAppenderConfig = log4jConfiguration.log4j_appender_config || {};
    const appenderNames = Object.keys(log4jAppenderConfig);

    if (appenderNames.length === 0) {
      return (
        <p>
          No appender configuration available.{' '}
          {log4jConfiguration.log4j_enabled
          && (
            <span>
              Please ensure <code>{log4jConfiguration.log4j_logger_name}</code> is correctly configured in the Log4j
              configuration file.
            </span>
          )}
        </p>
      );
    }

    const formattedAppendersConfig = [];

    appenderNames.forEach((name) => {
      const config = log4jAppenderConfig[name];

      if (config.file_name) {
        formattedAppendersConfig.push(
          <dt key={`${name}-key`}>{name}</dt>,
          <dd key={`${name}-value`}>File name: <code>{config.file_name}</code></dd>,
        );

        return;
      }

      formattedAppendersConfig.push(
        <dt key={`${name}-key`}>{name}</dt>,
        <dd key={`${name}-value`}>Type: <code>{config.type}</code></dd>,
      );
    });

    return (
      <dl className={`dl-horizontal ${AuditLogStatusStyle.innerDl}`}>
        {formattedAppendersConfig}
      </dl>
    );
  };

  const _formatLog4jAuditLog = () => Object.keys(auditLogStatus).sort().map((nodeId) => {
    const nodeStatus = auditLogStatus[nodeId];

    return (
      <div key={nodeId}>
        <h4>Node <LinkToNode nodeId={nodeId} /></h4>
        <dl className={AuditLogStatusStyle.descriptionList}>
          <dt>Status</dt>
          <dd>{nodeStatus.log4j_enabled ? 'Enabled' : 'Disabled'}</dd>
          <dt>Message signing status</dt>
          <dd>{nodeStatus.message_signing_enabled ? 'Enabled' : 'Disabled'}</dd>
          <dt>Log4j logger name</dt>
          <dd><code>{nodeStatus.log4j_logger_name}</code></dd>
          <dt>Log4j marker name</dt>
          <dd><code>{nodeStatus.log4j_marker_name}</code></dd>
          <dt>Log4j appender configuration</dt>
          <dd>{_formatLog4jAppenderConfiguration(nodeStatus)}</dd>
        </dl>
      </div>
    );
  });

  if (!auditLogStatus) {
    return <Spinner />;
  }

  const mongodbAuditLog = _formatMongodbAuditLog();
  const log4jAuditLog = _formatLog4jAuditLog();

  return (
    <div className="auditlog-status">
      <Row>
        <Col md={6}>
          <h2>MongoDB Audit Log</h2>
          <p>This audit log stores entries in the same MongoDB server that Graylog uses.</p>
          {mongodbAuditLog}
        </Col>

        <Col md={6}>
          <h2>Log4j 2 Audit Log</h2>
          <p>This audit log stores entries using the Log4j 2 configuration you provided. Check your
            <code>log4j2.xml</code> file for more information.
          </p>
          {log4jAuditLog}
        </Col>
      </Row>
    </div>
  );
};

export default AuditLogStatus;
