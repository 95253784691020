import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedWorkspaceV2App from './EmbeddedWorkspaceV2App';

type ExternalLinkProps = {
  href: string;
  title: string;
};

const ExternalLink = ({
  href,
  title,
}: ExternalLinkProps) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

const WorkspaceV2App = () => (
  <>
    <PageHeader title="Google Workspace">
      <span>This input retrieves log records from <ExternalLink href="https://cloud.google.com/bigquery" title="BigQuery" />{' '}
        using the{' '}
        <ExternalLink href="https://support.google.com/a/topic/9079469" title="Google Workspace logs and reports in BigQuery" />{' '}
        export capability.
      </span>
    </PageHeader>
    <EmbeddedWorkspaceV2App />
  </>
);

export default WorkspaceV2App;
