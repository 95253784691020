import { useQuery } from '@tanstack/react-query';

import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type FetchError from 'logic/errors/FetchError';
import { onError } from 'util/conditional/onError';

export const INVESTIGATION_AI_REPORT_QUOTA_STATUS = 'investigation_ai_report_quota_status';

const getQuotaStatus = () => fetch(
  'GET',
  qualifyUrl(EnterpriseApiRoutes.SecurityApp.Investigations.AIReport.quotaStatus().url),
);

export type AIQuotaStatus = {
  limit?: number,
  used?: number,
  reset_at?: string,
  remaining?: number,
}

export const useAIQuotaStatus = () : {
  data: AIQuotaStatus,
  isLoading: boolean,
  isFetching: boolean,
  isError: boolean,
  refetch: () => void
} => {
  const { data, isFetching, isLoading, isError, refetch } = useQuery<AIQuotaStatus, FetchError>(
    [INVESTIGATION_AI_REPORT_QUOTA_STATUS],
    () => onError(getQuotaStatus(), (errorThrown: FetchError) => {
      if (!(errorThrown.status === 404)) {
        UserNotification.error(`Loading AI Report quota status failed with: ${errorThrown}`);
      }
    }),
  );

  return ({
    data,
    isFetching,
    isLoading,
    isError,
    refetch,
  });
};

export default useAIQuotaStatus;
