import React, { createContext, useState } from 'react';

import type { StepsContextType, Step } from 'enterprise-integrations/types';

export const StepsContext = createContext<StepsContextType | undefined>(undefined);

type StepsProviderProps = {
  children: any;
};

export const StepsProvider = ({
  children,
}: StepsProviderProps) => {
  const [currentStep, setCurrentStep] = useState('authorize');
  const [enabledSteps, enableStep] = useState(['authorize']);
  const [availableSteps, setAvailableStep] = useState([]);

  const isDisabledStep = (step: Step) => {
    if (!enabledSteps || enabledSteps.length === 0) {
      return true;
    }

    return !enabledSteps.includes(step);
  };

  const setEnabledStep = (step: Step) => {
    enableStep([...enabledSteps, step]);
  };

  return (
    <StepsContext.Provider value={{
      availableSteps,
      currentStep,
      enabledSteps,
      isDisabledStep,
      setAvailableStep,
      setCurrentStep,
      setEnabledStep,
    }}>
      {children}
    </StepsContext.Provider>
  );
};
