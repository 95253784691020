import { useQuery } from '@tanstack/react-query';

import type {
  License,
} from 'license/types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { Attribute, SearchParams } from 'stores/PaginationTypes';
import PaginationURL from 'util/PaginationURL';
import FiltersForQueryParams from 'components/common/EntityFilters/FiltersForQueryParams';
import EnterpriseApiRoutes from 'common/ApiRoutes';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};

export const LICENSES_QUERY_KEY = 'licenses';

export const keyFn = (searchParams: SearchParams) => ([LICENSES_QUERY_KEY, searchParams]);

export const fetchLicenses = async (searchParams: SearchParams) => {
  const drawDownLicenseUrl = qualifyUrl(EnterpriseApiRoutes.License.list().url);
  const url = PaginationURL(
    drawDownLicenseUrl,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    { filters: FiltersForQueryParams(searchParams.filters), sort: searchParams.sort.attributeId, order: searchParams.sort.direction });

  return fetch('GET', url).then(
    ({ elements, total, attributes }) => ({
      list: elements,
      pagination: { total },
      attributes,
    }));
};

const useLicenses = (searchParams: SearchParams, { enabled } = { enabled: true }): {
  data: {
    list: Readonly<Array<License>>,
    pagination: { total: number },
    attributes: Array<Attribute>
  },
  isLoading: boolean,
  refetch: () => void,
} => {
  const { data, isLoading, refetch } = useQuery(
    keyFn(searchParams),
    () => defaultOnError(fetchLicenses(searchParams), 'Loading licenses failed with status', 'Could not load licenses'),
    {
      keepPreviousData: true,
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    isLoading,
    refetch,
  });
};

export default useLicenses;
