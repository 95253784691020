import * as React from 'react';
import { useCallback, useContext } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';
import UserNotification from 'util/UserNotification';
import DataWarehouseActionModalForm from 'data-warehouse/components/DataWarehouseActionModalForm';
import useUserDateTime from 'hooks/useUserDateTime';
import { getDataWarehouseUriParams } from 'data-warehouse/logic/Utils';
import { handleFailures } from 'data-warehouse/data-warehouse-overview/actions/Utils';
import StreamsContext from 'contexts/StreamsContext';
import type { DataWarehouseActionValues } from 'data-warehouse/Types';

type Props = {
  archiveStreamId: string
  onToggleModal: () => void
};

const DeleteArchiveModal = ({
  archiveStreamId,
  onToggleModal,
}: Props) => {
  const { userTimezone } = useUserDateTime();
  const queryClient = useQueryClient();
  const streams = useContext(StreamsContext);

  const availableStreams = streams.map((stream) => ({
    key: stream.title,
    value: stream.id,
  }));

  const onDeleteSubmit = useCallback((values: DataWarehouseActionValues) => {
    const uriParams = getDataWarehouseUriParams(values, userTimezone);

    fetch(
      'POST',
      qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.bulk_delete().url}?${uriParams}`),
      { stream_ids: [archiveStreamId], inclusion_type: values.inclusion_type },
    ).then((response) => {
      const failures = response?.failures || [];
      handleFailures(failures, 'deleted');
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting archive. ${error}`);
    }).finally(() => {
      queryClient.invalidateQueries(['data-warehouse']);
      onToggleModal();
    });
  }, [userTimezone, archiveStreamId, queryClient, onToggleModal]);

  return (
    <DataWarehouseActionModalForm modalTitle="Deleting Data Warehouse data! Are you sure?"
                                  type="delete"
                                  show
                                  availableStreams={availableStreams}
                                  onHide={onToggleModal}
                                  submitButtonText="Delete"
                                  hourOnly
                                  streamIds={[archiveStreamId]}
                                  onSubmit={onDeleteSubmit}>
      <p>You are about to delete data from 1 Data Warehouse. This action cannot be undone.</p>
    </DataWarehouseActionModalForm>
  );
};

export default DeleteArchiveModal;
