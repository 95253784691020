import * as React from 'react';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

import Store from 'logic/local-storage/Store';
import { Alert, Button } from 'components/bootstrap';
import { Spinner, ConfirmDialog } from 'components/common';
import { useGetLatestBundle, useInstallHubBundle } from 'illuminate/hooks/useBundlesAPI';

const StyledAlert = styled(Alert)`
  margin-bottom: 0;
  margin-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
`;

const AlertButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
    
  gap: ${({ theme }) => theme.spacings.xs};
`;

type Props = {
  className?: string
}

function NewBundleAlert({ className }: Props) {
  const { version, isNew } = useGetLatestBundle();
  const { installHubBundle, installingHubBundle } = useInstallHubBundle();
  const [showInstallNewBundleConfirm, setShowInstallNewBundleConfirm] = React.useState<boolean>(false);
  const queryClient = useQueryClient();

  const installNewBundle = async () => {
    await installHubBundle({ bundleId: version }, { onSuccess: () => setShowInstallNewBundleConfirm(true) });
  };

  const skipVersion = () => {
    Store.set('skiped-bundle-version', version);
    queryClient.invalidateQueries(['check-for-new-bundle']);
  };

  if (!isNew) return null;

  return (
    <>
      <StyledAlert bsStyle="info" className={className}>
        <Container>
          <div>New Illuminate <b>bundle v{version}</b> ready to install</div>
          <AlertButtonsRow>
            <Button bsStyle="default"
                    bsSize="small"
                    onClick={skipVersion}>
              Skip Version
            </Button>
            <Button bsStyle="info"
                    bsSize="small"
                    onClick={installNewBundle}>
              {installingHubBundle && <Spinner text="" />} Install
            </Button>
          </AlertButtonsRow>
        </Container>
      </StyledAlert>
      <ConfirmDialog show={showInstallNewBundleConfirm}
                     btnConfirmText="Dismiss"
                     onCancel={() => setShowInstallNewBundleConfirm(false)}
                     onConfirm={() => setShowInstallNewBundleConfirm(false)}
                     title="Bundle installed successfully">
        <>
          <p>The new bundle has been installed successfully</p>
          <p>To activate it, select the new version from the dropdown menu</p>
        </>
      </ConfirmDialog>
    </>
  );
}

export default NewBundleAlert;
