import React, { createContext, useContext, useState } from 'react';

const PaginationContext = createContext(null);
const PaginationUpdateContext = createContext(null);

type Props = {
  initPage?: number;
  initQuery?: string;
  initPerPage?: number;
  initFilters?: { [key: string]: string };
  children: React.ReactNode
};

export type LocalPagination = {
  page: number,
  perPage: number,
  query: string | undefined,
  orderBy: string | undefined,
  direction: string | undefined,
  filters: { [key: string]: string },
}

export function PaginationProvider({ initPage = 1, initPerPage = 10, initFilters = {}, initQuery, children }: Props) {
  const [localPagination, setLocalPagination] = useState<LocalPagination>({
    page: initPage,
    perPage: initPerPage,
    query: initQuery,
    orderBy: undefined,
    direction: undefined,
    filters: initFilters,
  });

  return (
    <PaginationContext.Provider value={localPagination}>
      <PaginationUpdateContext.Provider value={setLocalPagination}>
        {children}
      </PaginationUpdateContext.Provider>
    </PaginationContext.Provider>
  );
}

export function usePagination() {
  return useContext(PaginationContext);
}

export function useSetPagination() {
  return useContext(PaginationUpdateContext);
}
