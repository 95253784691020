import React, { useContext, useState } from 'react';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';

import { ApiRoutes } from './Routes';

import ValidatedInput from '../common/ValidatedInput';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';
import formValidation from '../common/utils/formValidation';
import handleFileUpload from '../helpers/fileReader';

type StepAuthorizeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepAuthorize = ({
  onSubmit,
  onChange,
}: StepAuthorizeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const handleSubmit = () => {
    fetch<{ result: string }>('POST', qualifyUrl(ApiRoutes.INTEGRATIONS.WorkspaceV2.CHECK_CREDENTIALS), {
      service_account_key: formData?.serviceAccountKey?.fileContent || '',
    }).then((result) => {
      if (result.result === 'valid') {
        setFormError(null);
        onSubmit();
      } else {
        setFormError({
          full_message: result.result,
          nice_message:
              'Unable to connect to Google Workspace using provided configuration.',
        });
      }
    })
      .catch((err) => {
        setFormError({
          full_message: err.message,
          nice_message:
            'Unable to connect to Google Workspace using provided configuration.',
        });
      });
  };

  const onFileUpload = (e) => {
    e.persist();
    handleFileUpload(e.target.files[0], (fileName, fileContent) => onChange(e, { fileName, fileContent }));
  };

  const serviceAccountKey = formData.serviceAccountKey?.fileContent as { fileName: string };

  const accountKeyHelpText = serviceAccountKey?.fileName ? `Currently using file: ${serviceAccountKey.fileName}.` : 'Service account key JSON.';

  const isFormValid = formValidation.isFormValid(
    ['workspaceName', 'serviceAccountKey', 'workspaceDatasetName'],
    formData,
  );

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify &amp; Proceed"
              disabled={isFormValid}
              title=""
              className="col-md-7"
              error={formError}
              description="">
      <ValidatedInput id="workspaceName"
                      type="text"
                      fieldData={formData.workspaceName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      required />
      <ValidatedInput id="serviceAccountKey"
                      type="file"
                      onChange={onFileUpload}
                      fieldData={formData.serviceAccountKey}
                      label="Service Account Key"
                      help={accountKeyHelpText}
                      accept=".json"
                      required />
      <ValidatedInput id="workspaceDatasetName"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.workspaceDatasetName}
                      help="The BigQuery Dataset Name where workspace events are exported."
                      label="BigQuery Dataset Name"
                      required />
    </FormWrap>
  );
};

export default StepAuthorize;
