import type { PluginExports } from 'graylog-web-plugin/plugin';

import AuditLogPage from 'auditlog/AuditLogPage';
import AuditLogConfigurationPage from 'auditlog/AuditLogConfigurationPage';

const pluginExports: PluginExports = {
  routes: [
    { path: '/system/auditlog', component: AuditLogPage },
    { path: '/system/auditlog/configuration', component: AuditLogConfigurationPage },
  ],
};

export default pluginExports;
