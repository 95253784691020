import * as React from 'react';
import { forwardRef, useMemo } from 'react';
import type { FormikErrors } from 'formik';

import AsyncQueryInput from 'views/components/searchbar/queryinput/AsyncQueryInput';
import type { TimeRange, NoTimeRangeOverride } from 'views/logic/queries/Query';
import type View from 'views/logic/views/View';
import type { Completer, FieldTypes } from 'views/components/searchbar/SearchBarAutocompletions';
import SearchBarAutoCompletions from 'views/components/searchbar/SearchBarAutocompletions';
import type { AutoCompleter, Command, Editor } from 'views/components/searchbar/queryinput/ace-types';
import type { QueryValidationState } from 'views/components/searchbar/queryvalidation/types';
import FieldNameCompletion from 'views/components/searchbar/completions/FieldNameCompletion';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

const defaultCompleterFactory = (...args: ConstructorParameters<typeof SearchBarAutoCompletions>) => new SearchBarAutoCompletions(...args);

const completers = [new FieldNameCompletion([])];

const useCompleter = ({ completerFactory, fieldTypes }: Pick<Props, 'completerFactory' | 'fieldTypes'>) => {
  const fields = useMemo(() => {
    const allFieldsByName = Object.fromEntries(fieldTypes.map((field) => [field.name, field]));

    return { all: allFieldsByName, query: allFieldsByName };
  }, [fieldTypes]);

  return useMemo(() => completerFactory?.(completers ?? [], undefined, [], fields, undefined, undefined),
    [completerFactory, fields]);
};

type Props = {
  completerFactory?: (
    completers: Array<Completer>,
    timeRange: TimeRange | NoTimeRangeOverride | undefined,
    streams: Array<string>,
    fieldTypes: FieldTypes,
    userTimezone: string,
    view: View | undefined,
  ) => AutoCompleter,
  value: string,
  name: string,
  isValidating: boolean,
  error?: QueryValidationState,
  warning?: QueryValidationState,
  onChange: (changeEvent: { target: { value: string, name: string } }) => Promise<string>,
  onExecute: (query: string) => void,
  validate: () => Promise<FormikErrors<{}>>,
  commands?: Array<Command>,
  disableExecution?: boolean,
  placeholder: string,
  fieldTypes: Array<FieldTypeMapping>
}

const DataWarehouseQueryInput = forwardRef<Editor, Props>(({
  value, onChange, placeholder,
  isValidating,
  warning,
  error,
  disableExecution,
  validate,
  name,
  completerFactory = defaultCompleterFactory,
  onExecute,
  commands,
  fieldTypes,
}, ref) => {
  const completer = useCompleter({ completerFactory, fieldTypes });

  return (
    <AsyncQueryInput value={value}
                     completer={completer}
                     ref={ref}
                     name={name}
                     onChange={onChange}
                     placeholder={placeholder}
                     error={error}
                     isValidating={isValidating}
                     warning={warning}
                     disableExecution={disableExecution}
                     validate={validate}
                     onExecute={onExecute}
                     commands={commands} />
  );
});

export default DataWarehouseQueryInput;
