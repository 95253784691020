import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface DataTieringConfig {
    readonly index_lifetime_min: string;
    readonly index_lifetime_max: string;
    readonly type: string;
}
interface Message {
    readonly processing_time: string;
    readonly source_input_id: string;
    readonly sequence_nr: number;
    readonly streams: Stream[];
    readonly fields_entries: unknown[];
    readonly is_source_inet_address: boolean;
    readonly field_names: string[];
    readonly receive_time: string;
    readonly message_id: string;
    readonly journal_offset: number;
    readonly source: string;
    readonly message: string;
    readonly inet_address: string;
    readonly message_queue_id: Object;
    readonly filter_out: boolean;
    readonly size: number;
    readonly index_sets: IndexSet[];
    readonly stream_ids: string[];
    readonly field_count: number;
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly complete: boolean;
    readonly timestamp: string;
}
interface StreamRule {
    readonly field: string;
    readonly stream_id: string;
    readonly description: string;
    readonly id: string;
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly inverted: boolean;
    readonly type: 'EXACT' | 'REGEX' | 'GREATER' | 'SMALLER' | 'PRESENCE' | 'CONTAINS' | 'ALWAYS_MATCH' | 'MATCH_INPUT';
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly content_pack: string;
    readonly value: string;
}
interface IndexSetConfig {
    readonly index_template_name: string;
    readonly index_optimization_max_num_segments: number;
    readonly replicas: number;
    readonly field_type_refresh_interval: string;
    readonly description: string;
    readonly index_optimization_disabled: boolean;
    readonly creation_date: string;
    readonly field_type_profile: string;
    readonly title: string;
    readonly index_wildcard: string;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly writable: boolean;
    readonly shards: number;
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly index_analyzer: string;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly index_template_type: string;
    readonly rotation_strategy_class: string;
    readonly id: string;
    readonly index_prefix: string;
    readonly regular: boolean;
    readonly data_tiering: DataTieringConfig;
    readonly index_match_pattern: string;
}
interface RotationStrategyConfig {
    readonly type: string;
}
interface ResultMessage {
    readonly index: string;
    readonly highlight_ranges: {
        readonly [_key: string]: unknown[];
    };
    readonly message: Message;
}
interface MessageParseRequest {
    readonly codec: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly remote_address: string;
    readonly message: string;
}
interface CustomFieldMapping {
    readonly field: string;
    readonly type: string;
}
interface Output {
    readonly creator_user_id: string;
    readonly configuration: {
        readonly [_key: string]: Object;
    };
    readonly created_at: string;
    readonly id: string;
    readonly type: string;
    readonly title: string;
    readonly content_pack: string;
}
interface Object {
}
interface Stream {
    readonly outputs: Output[];
    readonly paused: boolean;
    readonly description: string;
    readonly title: string;
    readonly content_pack: string;
    readonly stream_rules: StreamRule[];
    readonly index_set_id: string;
    readonly index_set: IndexSet;
    readonly matching_type: 'AND' | 'OR';
    readonly remove_matches_from_default_stream: boolean;
    readonly disabled: boolean;
    readonly categories: string[];
    readonly validations: {
        readonly [_key: string]: Validator;
    };
    readonly id: string;
    readonly fields: {
        readonly [_key: string]: Object;
    };
    readonly default_stream: boolean;
}
interface MessageTokens {
    readonly tokens: string[];
}
interface Validator {
}
interface IndexSet {
    readonly write_index_alias: string;
    readonly managed_indices: string[];
    readonly active_write_index: string;
    readonly all_index_aliases: {
        readonly [_key: string]: string[];
    };
    readonly up: boolean;
    readonly index_prefix: string;
    readonly index_wildcard: string;
    readonly newest_index: string;
    readonly config: IndexSetConfig;
}
/**
 * Parse a raw message
 */
export function parse(JSONbody: MessageParseRequest): Promise<ResultMessage> {
    return __request__('POST', '/messages/parse', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Analyze a message string
 * @param index The index the message containing the string is stored in.
 * @param analyzer The analyzer to use.
 * @param string The string to analyze.
 */
export function analyze(index: string, string: string, analyzer?: string): Promise<MessageTokens> {
    return __request__('GET', `/messages/${index}/analyze`, null, { 'analyzer': analyzer, 'string': string }, {
        'Accept': ['application/json']
    });
}
/**
 * Get a single message.
 * @param index The index this message is stored in.
 */
export function search(index: string, messageId: string): Promise<ResultMessage> {
    return __request__('GET', `/messages/${index}/${messageId}`, null, {}, {
        'Accept': ['application/json']
    });
}
