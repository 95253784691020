import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import {
  DATA_WAREHOUSE_API_ROUTES,
  DATA_WAREHOUSE_BACKEND_QUERY_KEYS,
} from 'data-warehouse/Constants';
import type { Backend } from 'archive/types';
import { defaultOnError } from 'util/conditional/onError';

const INITIAL_DATA = [];

type Options = {
  enabled: boolean,
}

const refetchInterval = 5000;

const fetchConfig = () => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backend.base().url));

const useDataWarehouseBackends = ({ enabled }: Options = { enabled: true }): {
  data: Array<Backend>,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery(
    [...DATA_WAREHOUSE_BACKEND_QUERY_KEYS],
    () => defaultOnError(fetchConfig(), 'Loading Data Warehouse backends failed with status', 'Could not load Data Warehouse backends.'),
    {
      keepPreviousData: true,
      refetchInterval,
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data: data ?? INITIAL_DATA,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouseBackends;
