import * as React from 'react';

import { FormSubmit } from 'components/common';

type Props = {
  action: 'create' | 'edit',
  disabled?: boolean,
  formElementId: string,
  isLoading?: boolean
  onCancel: () => void,
};

const ReportContentsToolbar = ({ action, formElementId, isLoading = false, onCancel, disabled = false }: Props) => {
  const submitButtonText = action === 'create' ? 'Create report' : 'Update report';
  const submitLoadingText = action === 'create' ? 'Creating report...' : 'Updating report...';

  return (
    <FormSubmit isAsyncSubmit
                submitButtonText={submitButtonText}
                submitLoadingText={submitLoadingText}
                disabledSubmit={disabled}
                isSubmitting={isLoading}
                formId={formElementId}
                onCancel={onCancel} />

  );
};

export default ReportContentsToolbar;
