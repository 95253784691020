import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import * as Immutable from 'immutable';
import { useFormikContext } from 'formik';

import type { Sort, SearchParams, Attributes } from 'stores/PaginationTypes';
import fetch from 'logic/rest/FetchProvider';
import URLUtils from 'util/URLUtils';
import useTableLayout from 'components/common/EntityDataTable/hooks/useTableLayout';
import useUpdateUserLayoutPreferences from 'components/common/EntityDataTable/hooks/useUpdateUserLayoutPreferences';
import type { PaginatedResponse } from 'components/common/PaginatedEntityTable/useFetchEntities';
import useFetchEntities from 'components/common/PaginatedEntityTable/useFetchEntities';
import type { UrlQueryFilters } from 'components/common/EntityFilters/types';
import { useTableEventHandlers } from 'components/common/EntityDataTable';
import { Spinner, PaginatedList, SearchForm, NoSearchResult, Pluralize } from 'components/common';
import TableFetchContextProvider from 'components/common/PaginatedEntityTable/TableFetchContextProvider';
import PaginationURL from 'util/PaginationURL';
import { Table } from 'components/bootstrap';
import type { WidgetState } from 'views/logic/widgets/Widget';
import Widget from 'views/logic/widgets/Widget';
import Parameter from 'views/logic/parameters/Parameter';
import type { WidgetRef } from 'report/types';
import type { WidgetSummary } from 'report/AvailableWidgetsStore';
import ModalSubmit from 'components/common/ModalSubmit';
import FiltersForQueryParams from 'components/common/EntityFilters/FiltersForQueryParams';
import BulkSelectCheckBox from 'report/report-creation/content/BulkSelectCheckbox';
import type { AvailableDashboards } from 'report/report-creation/content/types';
import DashboardRow from 'report/report-creation/content/DashboardRow';
import FiltersBar from 'report/report-creation/content/filters/FiltersBar';

const DEFAULT_LAYOUT = {
  entityTableId: 'report_widgets',
  defaultPageSize: 14,
  defaultSort: { attributeId: 'title', direction: 'asc' } as Sort,
  defaultDisplayedAttributes: ['title', 'widget_title', 'owner'],
};

type AvailableDashboardsResponse = {
  id: string,
  title: string,
  widgets: Array<WidgetSummary>,
  owner: string,
  search_id: string,
  last_updated_at: Date,
}

type PaginatedAvailableWidgetsResponse = {
  pagination: {
    count: number,
    total: number,
    page: number,
    per_page: number,
  },
  query: string,
  attributes: Attributes,
  elements: Array<AvailableDashboardsResponse>,
};

const availableWidgetsUrls = '/plugins/org.graylog.plugins.report/widgets/paginated';

const fetchAvailableWidgets = (searchParams: SearchParams): Promise<PaginatedResponse<AvailableDashboards>> => {
  const url = PaginationURL(
    availableWidgetsUrls,
    searchParams.page,
    searchParams.pageSize,
    searchParams.query,
    {
      sort: searchParams.sort.attributeId,
      order: searchParams.sort.direction,
      filters: FiltersForQueryParams(searchParams.filters),
    });

  return fetch('GET', URLUtils.qualifyUrl(url))
    .then(({ elements: list, ...rest }: PaginatedAvailableWidgetsResponse) => ({
      ...rest,
      list: list.map(({ widgets, ...dashboard }) => ({
        ...dashboard,
        widgets: widgets.map((widget) => ({
          id: widget.id,
          title: widget.title,
          type: widget.type,
          config: Widget.fromJSON(widget as unknown as WidgetState).config,
          parameters: widget.parameters.map((p) => Parameter.fromJSON(p)),
          eligible: widget.eligible,
        })),
      })),
    }));
};

export const KEY_PREFIX = ['reports', 'widgets'];
export const keyFn = (searchParams: SearchParams) => [...KEY_PREFIX, searchParams];

const INITIAL_DATA: PaginatedResponse<AvailableDashboards> = {
  pagination: { total: 0 },
  list: [],
  attributes: [],
};
const SearchRow = styled.div`
  margin-bottom: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const humanName = 'Report Widgets';

type Props = {
  onClose: () => void,
  widgets: Array<WidgetRef>,
  onSubmit: (newWidgets: Array<WidgetRef>) => Promise<void>,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const HorizontalBar = styled.div(({ theme }) => css`
  width: 100%;
  border-top: ${theme.colors.gray[90]} 2px solid;
  margin: 5px 15px 10px -15px;
`);

const AddWidgetForm = ({ onClose, onSubmit, widgets: disabledWidgets }: Props) => {
  const { isSubmitting } = useFormikContext();
  const [urlQueryFilters, setUrlQueryFilters] = useState<UrlQueryFilters>(Immutable.OrderedMap());
  const [query, setQuery] = useState<string>(undefined);
  const { layoutConfig, isInitialLoading: isLoadingLayoutPreferences } = useTableLayout(DEFAULT_LAYOUT);
  const [pagination, setPagination] = useState({ page: 1, pageSize: layoutConfig.pageSize });
  const { mutate: updateTableLayout } = useUpdateUserLayoutPreferences(DEFAULT_LAYOUT.entityTableId);
  const fetchOptions = useMemo(() => ({
    query,
    page: pagination.page,
    pageSize: layoutConfig.pageSize,
    sort: layoutConfig.sort,
    filters: urlQueryFilters,
  }), [layoutConfig.pageSize, layoutConfig.sort, pagination.page, query, urlQueryFilters]);
  const fetchKey = useMemo(() => keyFn(fetchOptions), [fetchOptions]);
  const resetPage = useCallback(() => setPagination((prev) => ({ ...prev, page: 1 })), []);

  const {
    data: paginatedEntities = INITIAL_DATA,
    isInitialLoading: isLoadingEntities,
    refetch,
  } = useFetchEntities<AvailableDashboards>({ fetchKey, searchParams: fetchOptions, enabled: !isLoadingLayoutPreferences, fetchEntities: fetchAvailableWidgets, humanName });

  const onChangeFilters = useCallback((newUrlQueryFilters: UrlQueryFilters) => {
    resetPage();
    setUrlQueryFilters(newUrlQueryFilters);
  }, [resetPage]);
  const [widgetSelection, setWidgetSelection] = useState<Array<WidgetRef>>([]);
  const _onSubmit = useCallback(() => onSubmit(disabledWidgets.concat(widgetSelection)), [disabledWidgets, onSubmit, widgetSelection]);

  const {
    onSearch,
    onSearchReset,
  } = useTableEventHandlers({
    appSection: `${DEFAULT_LAYOUT.entityTableId}-list`,
    paginationQueryParameter: { ...pagination, resetPage, setPagination: (payload) => setPagination((prev) => ({ ...prev, ...payload })) },
    updateTableLayout,
    setQuery,
  });

  if (isLoadingLayoutPreferences || isLoadingEntities) {
    return <Spinner />;
  }

  const { list, pagination: { total }, attributes } = paginatedEntities;

  return (
    <Container>
      <TableFetchContextProvider refetch={refetch} searchParams={fetchOptions} attributes={attributes}>
        <PaginatedList pageSize={pagination.pageSize}
                       activePage={pagination.page}
                       useQueryParameter={false}
                       showPageSizeSelect={false}
                       totalItems={total}
                       onChange={(newPage, newPageSize) => setPagination({ page: newPage, pageSize: newPageSize })}>
          <FiltersBar attributes={attributes} filters={urlQueryFilters} setFilters={onChangeFilters} />
          <HorizontalBar />
          <SearchRow>
            <SearchForm onSearch={onSearch}
                        onReset={onSearchReset}
                        query={query} />
          </SearchRow>
          <TableContainer>
            {list?.length === 0 ? (
              <NoSearchResult>No dashboards/widgets have been found.</NoSearchResult>
            ) : (
              <Table>
                <thead>
                  <tr>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th>
                      <BulkSelectCheckBox dashboards={list}
                                          onChange={setWidgetSelection}
                                          selected={widgetSelection}
                                          disabledWidgets={disabledWidgets} />
                    </th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th />
                    <th>Name</th>
                    <th>Created by</th>
                    <th>Last Update</th>
                  </tr>
                </thead>

                <tbody>
                  {list.map((dashboard) => (
                    <DashboardRow key={`dashboard-${dashboard.id}`}
                                  dashboard={dashboard}
                                  checked={widgetSelection}
                                  widgetsInReport={disabledWidgets}
                                  onChange={setWidgetSelection} />
                  ))}
                </tbody>
              </Table>
            )}
          </TableContainer>
        </PaginatedList>
      </TableFetchContextProvider>
      <ModalSubmit submitButtonText={<>Add {widgetSelection.length !== 0 && `${widgetSelection.length} `}<Pluralize singular="widget" plural="widgets" value={widgetSelection.length} /></>}
                   displayCancel
                   onCancel={onClose}
                   onSubmit={_onSubmit}
                   disabledSubmit={widgetSelection.length === 0}
                   isAsyncSubmit
                   isSubmitting={isSubmitting}
                   submitLoadingText="Adding widgets ..." />
    </Container>
  );
};

export default AddWidgetForm;
