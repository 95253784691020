import React from 'react';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import useLicenseCheck from 'license/useLicenseCheck';
import Icon from 'components/common/Icon';
import Widget from 'views/logic/widgets/Widget';
import useCurrentUser from 'hooks/useCurrentUser';
import { isPermitted } from 'util/PermissionsMixin';

import InvestigationsVisualization from './InvestigationsVisualization';
import InvestigationsWidgetEdit from './InvestigationsWidgetEdit/InvestigationsWidgetEdit';
import InvestigationsWidget from './logic/InvestigationsWidget';
import { PAGINATION } from './Constants';
import AddInvestigationsWidgetActionHandler, { CreateInvestigationsWidget } from './logic/AddInvestigationsWidgetActionHandler';
import InvestigationsListConfigGenerator from './logic/searchtypes/InvestigationsListConfigGenerator';
import InvestigationsListHandler from './logic/searchtypes/InvestigationsListHandler';

Widget.registerSubtype(InvestigationsWidget.type, InvestigationsWidget);

const investigationsSearchWidgets: PluginExports = {
  enterpriseWidgets: [
    {
      type: 'INVESTIGATIONS',
      displayName: 'Investigations',
      defaultHeight: 4,
      defaultWidth: 6,
      hasEditSubmitButton: true,
      visualizationComponent: InvestigationsVisualization,
      editComponent: InvestigationsWidgetEdit,
      searchTypes: InvestigationsListConfigGenerator,
      titleGenerator: () => InvestigationsWidget.defaultTitle,
      needsControlledHeight: () => false,
      searchResultTransformer: (data: Array<unknown>) => data?.[0],
    },
  ],
  widgetCreators: [{
    title: 'Investigations Overview',
    func: CreateInvestigationsWidget,
    icon: () => <Icon name="extension" />,
  }],
  creators: [{
    type: 'investigations' as const,
    title: 'Investigations Overview',
    func: AddInvestigationsWidgetActionHandler,
    useCondition: () => {
      const { security } = useLicenseCheck();
      const user = useCurrentUser();

      return security?.isValid && isPermitted(user.permissions, ['investigations:read']);
    },
  }],
  searchTypes: [
    {
      type: InvestigationsWidget.type,
      handler: InvestigationsListHandler,
      defaults: {
        page: PAGINATION.INITIAL_PAGE,
        per_page: PAGINATION.PER_PAGE,
      },
    },
  ],
};

export default investigationsSearchWidgets;
