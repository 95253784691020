import __request__ from 'routing/request';
interface PaginationInfo {
    readonly per_page: number;
    readonly total: number;
    readonly count: number;
    readonly page: number;
}
interface PageListResponse_IndexFieldTypeProfileWithUsages {
    readonly total: number;
    readonly pagination: PaginationInfo;
    readonly defaults: EntityDefaults;
    readonly query: string;
    readonly elements: IndexFieldTypeProfileWithUsages[];
    readonly attributes: EntityAttribute[];
    readonly sort: string;
    readonly order: 'asc' | 'desc';
}
interface IndexFieldTypeProfileData {
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly name: string;
    readonly description: string;
}
interface EntityAttribute {
    readonly filterable: boolean;
    readonly related_property: string;
    readonly related_collection: string;
    readonly hidden: boolean;
    readonly sortable: boolean;
    readonly id: string;
    readonly type: 'STRING' | 'DATE' | 'DOUBLE' | 'INT' | 'LONG' | 'OBJECT_ID' | 'BOOLEAN';
    readonly title: string;
    readonly searchable: boolean;
    readonly filter_options: FilterOption[];
}
interface FilterOption {
    readonly title: string;
    readonly value: string;
}
interface IndexFieldTypeProfile {
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly name: string;
    readonly description: string;
    readonly id: string;
}
interface IndexFieldTypeProfileIdAndName {
    readonly name: string;
    readonly id: string;
}
type IndexFieldTypeProfileIdAndNameArray = IndexFieldTypeProfileIdAndName[];
interface CustomFieldMapping {
    readonly field: string;
    readonly type: string;
}
interface Sorting {
    readonly id: string;
    readonly direction: 'ASC' | 'DESC';
}
interface EntityDefaults {
    readonly sort: Sorting;
}
interface IndexFieldTypeProfileWithUsages {
    readonly custom_field_mappings: CustomFieldMapping[];
    readonly index_set_ids: string[];
    readonly name: string;
    readonly description: string;
    readonly id: string;
}
type stringArray = string[];
/**
 * Updates existing profile
 */
export function update(profile?: IndexFieldTypeProfile): Promise<void> {
    return __request__('PUT', '/system/indices/index_sets/profiles', profile, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Creates a new profile
 */
export function create(profileData?: IndexFieldTypeProfileData): Promise<IndexFieldTypeProfile> {
    return __request__('POST', '/system/indices/index_sets/profiles', profileData, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets list of all profiles (their ids and names only)
 */
export function getAll(): Promise<IndexFieldTypeProfileIdAndNameArray> {
    return __request__('GET', '/system/indices/index_sets/profiles/all', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Gets profile by id
 * @param sort The field to sort the result on
 * @param order The sort direction
 */
export function getPage(sort: 'name' = 'name', page: number = 1, per_page: number = 50, query?: string, filters?: stringArray, order: 'asc' | ' desc' = 'asc'): Promise<PageListResponse_IndexFieldTypeProfileWithUsages> {
    return __request__('GET', '/system/indices/index_sets/profiles/paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'order': order }, {
        'Accept': ['application/json']
    });
}
/**
 * Gets profile by id
 */
export function retrieveById(profile_id?: string): Promise<IndexFieldTypeProfileWithUsages> {
    return __request__('GET', `/system/indices/index_sets/profiles/${profile_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Removes a profile
 */
export function remove(profile_id?: string): Promise<void> {
    return __request__('DELETE', `/system/indices/index_sets/profiles/${profile_id}`, null, {}, {
        'Accept': ['application/json']
    });
}
