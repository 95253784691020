import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Alert, Col, Modal, Input } from 'components/bootstrap';
import { Spinner } from 'components/common';

import FileSystemRepositoryModalForm from './FileSystemRepositoryModalForm';
import S3RepositoryModalForm from './S3RepositoryModalForm';

import useDataTiering from '../hooks/useDataTiering';

type Props = {
  show: boolean,
  hideModal: () => void,
}

const RepositoryLocationSelect = styled(Col)(({ theme }) => css`
  display: flex;
  align-items: center;
  font-size: ${theme.fonts.size.large};
  gap: ${theme.spacings.md};
  margin-top: ${theme.spacings.md};  
  margin-bottom: ${theme.spacings.md};
  padding-left: ${theme.spacings.lg};
  padding-right: ${theme.spacings.lg};
`);

const LocationHeading = styled.p`
  font-weight: bold;
  margin-bottom: 0;  
`;

const CreateRepositoryModal = ({ hideModal, show }: Props) => {
  const { readiness } = useDataTiering();
  const [selectedRepoLocation, setSelectedRepoLocation] = useState<string | undefined>(undefined);

  const repositoryLocationLabels = {
    s3: 'S3',
    fs: 'FS',
  };

  useEffect(() => {
    if (!readiness.isLoading && readiness.data?.details.supported_repository_types) {
      setSelectedRepoLocation(readiness.data?.details.supported_repository_types[0]);
    }
  }, [readiness.isLoading, readiness.data?.details.supported_repository_types]);

  const repositoryForm = () => {
    if (readiness.isLoading) return <Spinner text="Fetching configuration information..." />;

    if (readiness.data?.details.supported_repository_types.length === 0) {
      return (
        <Alert bsStyle="info">
          No supported warm storage repository types. Please configure a warm storage repository location.
        </Alert>
      );
    }

    return (
      <>
        {readiness.data?.details.supported_repository_types.length > 1 && (
          <RepositoryLocationSelect>
            <LocationHeading>Warm Storage Repository Location:</LocationHeading>
            {readiness.data?.details.supported_repository_types.map((type) => (
              <Input checked={selectedRepoLocation === type}
                     formGroupClassName=""
                     id="repository_type"
                     key={type}
                     label={repositoryLocationLabels[type]}
                     onChange={() => setSelectedRepoLocation(type)}
                     type="radio"
                     value={type} />
            ))}
          </RepositoryLocationSelect>
        )}

        {selectedRepoLocation === 's3' && (
        <S3RepositoryModalForm hideModal={hideModal} />
        )}

        {selectedRepoLocation === 'fs' && (
        <FileSystemRepositoryModalForm hideModal={hideModal} />
        )}
      </>
    );
  };

  return (
    <Modal show={show}
           title="Create Warm Storage Repository"
           bsSize="large"
           onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Warm Storage Repository</Modal.Title>
      </Modal.Header>

      {repositoryForm()}
    </Modal>
  );
};

export default CreateRepositoryModal;
