import React from 'react';

import type { LicenseViolations, LicenseVersion } from 'license/types';
import LicenseState from 'license/LicenseState';

type Props = {
  violations: LicenseViolations,
  version?: LicenseVersion
}

const StateCell = ({ violations, version }: Props) => (
  <LicenseState violations={violations} version={version} />
);

export default StateCell;
