import type { SophosInputCreateRequest, SophosGenericInputCreateRequest } from './types';

import type { FormDataType } from '../common/utils/types';

export const toSophosInputCreateRequest = ({
  sophosName,
  sophosClientId,
  sophosClientSecret,
  sophosThrottleEnabled,
  pollingInterval,
  ingestAlerts,
}: FormDataType): SophosInputCreateRequest => ({
  name: sophosName.value,
  sophos_client_id: sophosClientId.value,
  sophos_client_secret: sophosClientSecret.value,
  throttling_allowed: !!sophosThrottleEnabled?.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  ingest_alerts: ingestAlerts.value,
});

export const toGenericSophosInputCreateRequest = ({
  sophosName,
  sophosClientId,
  sophosClientSecret,
  sophosThrottleEnabled,
  pollingInterval,
  ingestAlerts,
}: FormDataType): SophosGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.sophos.SophosInput',
  title: sophosName.value,
  global: false,
  configuration: {
    sophos_client_id: sophosClientId.value,
    sophos_client_secret: sophosClientSecret.value,
    throttling_allowed: !!sophosThrottleEnabled?.value,
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    ingest_alerts: ingestAlerts.value,
  },
});
