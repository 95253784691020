import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import BundleProvider from 'illuminate/context/BundleContext';

import ImproveCoverageModal from './ImproveCoverageModal';
import type { CoverageEntity } from './types';

const StyledButton = styled(Button)`
  align-self: center;

  @media print {
    display: none;
  }
`;

type Props = {
  variant?: 'large'
  className?: string,
  coverageEntity?: CoverageEntity
  isTactic?: boolean,
}

const ImproveCoverageButton = ({ className = undefined, variant = undefined, coverageEntity = undefined, isTactic = undefined }: Props) => {
  const sendTelemetry = useSendTelemetry();
  const [showCoverageModal, setShowCoverageModal] = useState(false);

  const openCoverageModal = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.THREAT_COVERAGE.IMPROVE_COVERAGE_EXPLANATION_OPENED, {
      app_pathname: 'security',
      app_section: 'threat-coverage',
    });

    setShowCoverageModal(true);
  };

  return (
    <>
      <StyledButton bsStyle="info"
                    bsSize="xs"
                    onClick={openCoverageModal}
                    className={className}>
        {variant === 'large' && <>Improve coverage</>}<Icon name="deployed_code_update" />
      </StyledButton>
      {showCoverageModal && (
        <BundleProvider>
          <ImproveCoverageModal onHide={() => setShowCoverageModal(false)}
                                isTactic={isTactic}
                                coverageEntity={coverageEntity} />
        </BundleProvider>
      )}
    </>
  );
};

export default ImproveCoverageButton;
