import React from 'react';

import { Alert } from 'components/bootstrap';
import { Spinner } from 'components/common';

import useDataTiering from './hooks/useDataTiering';

const WarmTierReadinessInfo = () => {
  const { readiness } = useDataTiering();

  if (readiness.isLoading) return <Spinner text="Fetching readiness information..." />;

  if (readiness.data?.ready) return null;

  return (
    <Alert bsStyle="info" title="Cluster not ready for Warm Tier">
      Your OpenSearch cluster is not fully configured to use the Warm Tier.

      {!readiness.data?.details.supported_by_search_version && (' Your Open Search version does not support Searchable Snapshots.')}

      {!readiness.data?.details.restored_snapshots_assignable && (' Restored snapshots are not assignable.')}

      {readiness.data?.details.supported_repository_types.length === 0 && (' There are no repository locations configured.')}
    </Alert>
  );
};

export default WarmTierReadinessInfo;
