import * as React from 'react';
import { createContext, useState } from 'react';

type FieldData = {
  defaultValue?: any,
  value: any,
  error?: string,
  dirty?: boolean,
  fileName?: string,
  fileContent?: string
};

export type FormData = { [key: string]: FieldData};

type ContextType = {
  formData: FormData,
  setFormData: (id: string, fieldData: FieldData) => void,
  clearField: (id: string) => void,
};

export const FormDataContext = createContext<ContextType>({
  formData: {},
  setFormData: () => {},
  clearField: () => {},
});

type Props = {
  initialFormData?: FormData
  children: React.ReactNode,
};

export const FormDataProvider = ({ initialFormData = {}, children }: Props) => {
  const [formData, updateState] = useState(initialFormData);

  const setFormData = (id, fieldData) => {
    updateState({
      ...formData,
      [id]: {
        ...formData[id],
        ...fieldData,
        dirty: true,
      },
    });
  };

  const clearField = (id) => {
    if (Object.keys(formData).find((field) => field === id)) {
      delete formData[id];
      updateState(formData);
    }
  };

  return (
    <FormDataContext.Provider value={{ formData, setFormData, clearField }}>
      {children}
    </FormDataContext.Provider>
  );
};

export default FormDataProvider;
