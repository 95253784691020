import * as React from 'react';
import styled from 'styled-components';
import upperFirst from 'lodash/upperFirst';
import { useContext } from 'react';

import { ControlLabel, Button, Table } from 'components/bootstrap';
import { MarkdownPreview } from 'components/common/MarkdownEditor';
import { Spinner, Markdown, Timestamp } from 'components/common';
import { useGetEntityTypes } from 'security-app/hooks/useSecurityEventsAPI';
import EventDefinitionPriorityEnum from 'logic/alerts/EventDefinitionPriorityEnum';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import type { SecurityEventAPIType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';
import useCurrentUser from 'hooks/useCurrentUser';
import EventDetailsContext from 'security-app/components/SecurityEvents/Alerts/EventDetails/EventDetailsContext';
import { TD } from 'security-app/components/common';

import AssociatedAssetInformation from './AssociatedAssetInformation';

type SigmaEventDefinitionConfig = EventDefinition['config'] & {
  sigma_rule_id: string,
};

export const LEFT_COLUMN_WIDTH = '52%';
export const RIGHT_COLUMN_WIDTH = '48%';

const Row = styled.div<{ $gap?: string, $justify?: string, $align?: string, $orientation?: 'horz' | 'vert' }>`
  display: flex;
  flex-direction: ${({ $orientation }) => ($orientation === 'vert' ? 'column' : 'row')};
  justify-content: ${({ $justify }) => ($justify || 'flex-start')};
  align-items: ${({ $align }) => ($align || 'stretch')};
  gap: ${({ $gap }) => ($gap || '0.5rem')};
`;

const Col = styled.div<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => ($gap || '0.5rem')};
`;

const DetailsBox = styled(Col)`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
`;

const StyledControlLabel = styled(ControlLabel)`
  margin: 0;
`;

type Props = {
  securityEvent: SecurityEventAPIType,
  eventDefinitions: { [key: string]: EventDefinition },
  orientation?: 'horz' | 'vert',
};

function EventDetails({ securityEvent, eventDefinitions, orientation = 'horz' }: Props) {
  const { permissions } = useCurrentUser();
  const { entityTypes, loadingEntityTypes } = useGetEntityTypes();
  const { setModal, setEntity } = useModalContext();
  const eventDetailsContext = useContext(EventDetailsContext);

  const canManageSecurityEvents = React.useMemo(() => (
    permissions.includes('security_event:edit') || permissions.includes('*')
  ), [permissions]);

  const openMDEditor = () => {
    setEntity(securityEvent);
    setModal('NOTES-EDITOR');
  };

  const editEventDefinition = () => {
    const eventDefinition = eventDefinitions[securityEvent.event.event_definition_id];

    if (eventDefinition.config.type === 'sigma-v1') {
      setEntity((eventDefinition.config as SigmaEventDefinitionConfig).sigma_rule_id);
      setModal('EDIT-SIGMA-EVENT-DEFINITION');
    } else {
      setEntity(eventDefinition);
      setModal('EVENT-DEFINITION');
    }
  };

  const { title: eventDefinitionTitle, remediation_steps } = eventDefinitions[securityEvent.event.event_definition_id] || { title: securityEvent.event.event_definition_id };

  const formatFields = (groupByFields: { [key: string]: string }) => {
    const fields = Object.keys(groupByFields);
    if (!fields.length) return <i>none</i>;

    return (
      <Row>
        {fields.map((key: string) => (
          <React.Fragment key={key}>
            <small>{key}:</small>
            <small>{groupByFields[key]}</small>
          </React.Fragment>
        ))}
      </Row>
    );
  };

  return (
    <>
      <Row $gap={orientation === 'vert' ? '1rem' : '3rem'} $orientation={orientation}>
        <Row>
          <StyledControlLabel>ID:</StyledControlLabel>
          <span>{securityEvent.event_id}</span>
        </Row>
        <Row>
          <StyledControlLabel>Event Key:</StyledControlLabel>
          <span>{securityEvent.event.key ? securityEvent.event.key : <i>none</i>}</span>
        </Row>
        <Row $align="flex-end">
          <StyledControlLabel>Group-By Fields:</StyledControlLabel>
          <span>{formatFields(securityEvent.event.group_by_fields)}</span>
        </Row>
      </Row>
      {securityEvent.event.associated_assets.length > 0 && (
        <Row>
          <StyledControlLabel>Associated Assets:</StyledControlLabel>
          <AssociatedAssetInformation identifiers={securityEvent.event.associated_assets} />
        </Row>
      )}
      <Row $orientation={orientation}>
        <Col style={{ width: orientation === 'vert' ? '100%' : LEFT_COLUMN_WIDTH }}>
          {eventDefinitions[securityEvent.event.event_definition_id] && (
            <DetailsBox>
              <Row $justify={eventDetailsContext.hideEventDefinitionEdit ? 'flex-start' : 'space-between'}>
                <strong>Event Definition</strong>
                {(canManageSecurityEvents && !eventDetailsContext.hideEventDefinitionEdit) ? (
                  <Button bsSize="small" bsStyle="success" onClick={editEventDefinition}>Edit</Button>
                ) : <TD />}
              </Row>
              <span>{eventDefinitionTitle}</span>
            </DetailsBox>
          )}
          <DetailsBox style={{ flexGrow: 1 }}>
            <Row $justify={eventDetailsContext.hideNotesEdit ? 'flex-start' : 'space-between'}>
              <strong>Notes</strong>
              {(canManageSecurityEvents && !eventDetailsContext.hideNotesEdit) ? (
                <Button bsSize="small"
                        bsStyle="success"
                        onClick={openMDEditor}>{!securityEvent.notes ? 'Add' : 'Edit'}
                </Button>
              ) : <TD />}
            </Row>
            <Markdown text={securityEvent.notes || '_No notes_'} />
          </DetailsBox>
          <DetailsBox style={{ flexGrow: 2 }}>
            <strong>Remediation Steps</strong>
            <MarkdownPreview show
                             withFullView
                             noBorder
                             noBackground
                             value={remediation_steps || '_No remediation steps_'} />
          </DetailsBox>
        </Col>
        <Col style={{ width: orientation === 'vert' ? '100%' : RIGHT_COLUMN_WIDTH }}>
          <DetailsBox style={{ flexGrow: 1 }}>
            <strong>Details</strong>
            <Table condensed striped>
              <tbody>
                <tr>
                  <td>Status</td>
                  <td>{upperFirst(securityEvent.status.toLowerCase())}</td>
                </tr>
                <tr>
                  <td>Priority</td>
                  <td>{upperFirst(EventDefinitionPriorityEnum.properties[securityEvent.event.priority].name)}</td>
                </tr>
                <tr>
                  <td>Timestamp</td>
                  <td aria-label="Updated Time"><Timestamp dateTime={securityEvent.event.timestamp} /></td>
                </tr>
                <tr>
                  <td>Event Key</td>
                  <td>{securityEvent.event.key || <i>none</i>}</td>
                </tr>
                <tr>
                  <td>Event Definition</td>
                  <td>
                    <Row $gap="1rem" $align="center">
                      <span>{eventDefinitionTitle}</span>
                      <small><i>({loadingEntityTypes ? <Spinner text="" /> : entityTypes[securityEvent.event.event_definition_type]})</i></small>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <td>Risk Score</td>
                  <td>{securityEvent.event.scores?.normalized_risk || <i>none</i>}</td>
                </tr>
                {(securityEvent.event.alert && securityEvent.event.timerange_start) && (
                  <tr>
                    <td>Aggregation time range</td>
                    <td><Timestamp dateTime={securityEvent.event.timerange_start} /> - <Timestamp dateTime={securityEvent.event.timerange_end} /></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </DetailsBox>
        </Col>
      </Row>
    </>
  );
}

export default EventDetails;
