import * as React from 'react';
import type Immutable from 'immutable';
import type { PluginExports } from 'graylog-web-plugin/plugin';

import { DATA_WAREHOUSE_ROUTES, DATA_WAREHOUSE_ENABLED, DATAWAREHOUSE_ATTRIBUTE_NAME, DATAWAREHOUSE_ATTRIBUTES } from 'data-warehouse/Constants';
import DataWarehousesPage from 'data-warehouse/DataWarehousesPage';
import DataWarehouseBackendPage from 'data-warehouse/data-warehouse-backend-config/DataWarehouseBackendPage';
import { isPermitted } from 'util/PermissionsMixin';
import StreamDataWarehouse from 'data-warehouse/stream/StreamDataWarehouse';
import DataWarehouseJobs from 'data-warehouse/components/DataWarehouseJobs';
import dataWarehouseCustomColumnRenderer from 'data-warehouse/DataWarehouseColumnRenderer';
import DataWarehouseConfigPage from 'data-warehouse/DataWarehouseConfigPage';
import StreamIndexSetDataWarehouseWarning from 'data-warehouse/stream/StreamIndexSetDataWarehouseWarning';
import DataWarehouseStreamDeleteWarning from 'data-warehouse/stream/DataWarehouseStreamDeleteWarning';
import { fetchStreamDataWarehouse } from 'data-warehouse/hooks/useStreamDatawarehouse';
import { fetchStreamDataWarehouseStatus } from 'data-warehouse/hooks/useStreamDataWarehouseStatus';
import StreamIlluminateProcessingSection from 'data-warehouse/stream/StreamIlluminateProcessingSection';
import validLicensePresent from 'license/ValidLicensePresent';
import DataWarehouseQueryValidation from 'data-warehouse/DataWarehouseQueryValidation';
import DataWarehouseJournal from 'data-warehouse/components/DataWarehouseJournal';
import DataWarehouseStatus from 'data-warehouse/components/DataWarehouseStatus';
import DataWarehouseSearchPage from 'data-warehouse/search/DataWarehouseSearchPage';
import App from 'routing/App';
import { dataWarehouseLink } from 'data-warehouse/search/Constants';

const getStreamDataWarehouseTableElements = (permissions: Immutable.List<string>) => {
  if (!isPermitted(permissions, 'archive:read')) {
    return null;
  }

  return {
    attributeName: DATAWAREHOUSE_ATTRIBUTE_NAME,
    attributes: DATAWAREHOUSE_ATTRIBUTES,
    columnRenderer: dataWarehouseCustomColumnRenderer,
  };
};

const dataWarehouseExport: PluginExports = {
  'views.elements.validationErrorExplanation': [
    (props) => (validLicensePresent() ? <DataWarehouseQueryValidation {...props} /> : null),
  ],
  routes: DATA_WAREHOUSE_ENABLED ? [
    {
      path: DATA_WAREHOUSE_ROUTES.ARCHIVE.LIST,
      component: DataWarehousesPage,
    },
    {
      path: DATA_WAREHOUSE_ROUTES.BACKEND,
      component: DataWarehouseBackendPage,
    },
    {
      path: DATA_WAREHOUSE_ROUTES.CONFIGURATION,
      component: DataWarehouseConfigPage,
    },
    {
      path: DATA_WAREHOUSE_ROUTES.SEARCH,
      component: DataWarehouseSearchPage,
      parentComponent: App,
    },
  ] : [],

  dataWarehouse: DATA_WAREHOUSE_ENABLED ? [
    {
      DataWarehouseJournal,
      DataWarehouseStatus,
      StreamDataWarehouse,
      DataWarehouseJobs,
      StreamIndexSetDataWarehouseWarning,
      fetchStreamDataWarehouse,
      fetchStreamDataWarehouseStatus,
      DataWarehouseStreamDeleteWarning,
      getStreamDataWarehouseTableElements,
      StreamIlluminateProcessingSection,
    },
  ] : [],
  'views.searchDataSources': DATA_WAREHOUSE_ENABLED ? [dataWarehouseLink] : [],
};

export default dataWarehouseExport;
