import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import type { FilterableFieldType } from 'data-warehouse/hooks/useDataWarehouseFilterableField';
import StreamFilterFieldValue from 'data-warehouse/components/StreamFilterFieldValue';
import AssetsFilterFieldValue from 'data-warehouse/components/AssetsFilterFieldValue';

type Props = {
  field: FilterableFieldType,
  fieldName: string,
  availableStreams: Array<{ key: string, value: string }>,
  assetsOptions: Array<{ label: string, value: string }>,
}

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

function DataWarehouseFilterValueField({ field, fieldName, availableStreams, assetsOptions }: Props) {
  if (field?.field_name === 'streams') {
    return (<StreamFilterFieldValue fieldName={fieldName} availableStreams={availableStreams} />);
  }

  if (field?.field_name === 'associated_assets') {
    return (<AssetsFilterFieldValue fieldName={fieldName} assetsOptions={assetsOptions} />);
  }

  return (
    <Field name={fieldName} required>
      {({ field: { name, value, onChange }, meta: { error } }) => (
        <FieldContainer>
          <Input type="text"
                 id={name}
                 name={name}
                 error={error}
                 placeholder="Field value"
                 required
                 onChange={onChange}
                 value={value} />
        </FieldContainer>
      )}
    </Field>
  );
}

export default DataWarehouseFilterValueField;
