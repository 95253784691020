import * as React from 'react';

import type { BackendReportWidget, AvailableWidgetPreview, WidgetValueSummary } from 'report/types';
import { isReportBackendWidget } from 'report/typeGuards/reportingWidget';
import PrintWidget from 'common/components/widgetRenderers/PrintWidget';
import { reportWidgetHeaderId } from 'report/Constants';

type Props = {
  description?: string,
  height?: number,
  hideDescription: boolean,
  hideQuery: boolean,
  interactive?: boolean
  limitHeight?: boolean
  showCaption?: boolean,
  showHandle?: boolean,
  showHeading?: boolean,
  title?: string,
  value: WidgetValueSummary,
  widget: BackendReportWidget | AvailableWidgetPreview,
  width?: number,
}

const ReportingWidget = ({
  description,
  height,
  hideDescription,
  hideQuery,
  interactive = true,
  limitHeight = false,
  showCaption = true,
  showHandle = true,
  showHeading = true,
  title,
  value,
  widget,
  width,
}: Props) => {
  const isBackendWidget = isReportBackendWidget(widget);
  const widgetId = isBackendWidget ? widget.dashboard_widget_id : widget.id;
  const headerId = isBackendWidget ? reportWidgetHeaderId(widget.dashboard_id, widgetId) : undefined;
  const _hideDescription = hideDescription || !isBackendWidget;

  return (
    <PrintWidget<BackendReportWidget | AvailableWidgetPreview, WidgetValueSummary> widget={widget}
                                                                                   widgetId={widgetId}
                                                                                   value={value}
                                                                                   showCaption={showCaption}
                                                                                   showHeading={showHeading}
                                                                                   showHandle={showHandle}
                                                                                   height={height}
                                                                                   headerId={headerId}
                                                                                   width={width}
                                                                                   interactive={interactive}
                                                                                   limitHeight={limitHeight}
                                                                                   hideDescription={_hideDescription}
                                                                                   hideQuery={hideQuery}
                                                                                   title={title}
                                                                                   description={description} />
  );
};

export default ReportingWidget;
