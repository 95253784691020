import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import Switch from 'components/common/Switch';
import BigLabel from 'report/report-creation/BigLabel';

type Props = {
  name: string,
  label: string,
  disabled?: boolean,
}
const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const FormikSwitch = ({ name, label, disabled = false }: Props) => (
  <OptionContainer>
    <Field name={name} type="checkbox">
      {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
        <>
          <BigLabel htmlFor={name}>{label}</BigLabel>
          <Switch name={name}
                  onChange={(e) => setFieldValue(name, e.target.checked)}
                  checked={value}
                  disabled={disabled}
                  id={name} />
          {error ? <span>{error}</span> : null}
        </>
      )}
    </Field>
  </OptionContainer>
);

export default FormikSwitch;
