import React from 'react';

type GreyNoiseFullIPAdapterSummaryProps = {
  dataAdapter?: {
    config: {};
    updateConfig: (...args: any[]) => void;
    handleFormEvent: (...args: any[]) => void;
    validationState: (...args: any[]) => void;
    validationMessage: (...args: any[]) => void;
  };
};

class GreyNoiseFullIPAdapterSummary extends React.Component<GreyNoiseFullIPAdapterSummaryProps, {
  [key: string]: any;
}> {
  render() {
    return (
      <dl>
        <dt>API Token</dt>
        <dd>******</dd>
      </dl>
    );
  }
}

export default GreyNoiseFullIPAdapterSummary;
