import * as React from 'react';
import { useEffect, useCallback } from 'react';
import styled from 'styled-components';

import QueryHelper from 'components/common/QueryHelper';
import { Alert, DropdownButton, MenuItem } from 'components/bootstrap';
import { PaginatedList, SearchForm } from 'components/common';
import withPaginationQueryParameter from 'components/common/withPaginationQueryParameter';
import AuditLogEntriesTable from 'auditlog/AuditLogEntriesTable';
import AuditLogActions from 'auditlog/AuditLogActions';
import AuditLogStore from 'auditlog/AuditLogStore';
import style from 'auditlog/AuditLogEntriesStyle.lazy.css';
import type { PaginationQueryParameterResult } from 'hooks/usePaginationQueryParameter';
import { useStore } from 'stores/connect';

const PAGE_SIZES = [20, 50, 100, 200, 500];

const queryExamples = (
  <>
    <p>
      Find audit log messages created after the given date:<br />
      <code>{'timestamp:>=2017-03-23'}</code><br />
    </p>
    <p>
      Find audit log messages where the action is <code>create</code> or <code>delete</code>:<br />
      <code>action:create action:delete</code><br />
      <code>action:create,delete</code>
    </p>
  </>
);

const fieldMap = {
  actor: 'User that triggered the audit event',
  namespace: 'Namespace of the audit event; might be different in plugins',
  object: 'Object of the audit event; what has been changed',
  action: 'Name of the action that has been executed on the object',
  success_status: 'If the action failed or succeeded',
  message: 'The actual audit event message',
  timestamp: 'Timestamp of the audit event',
};

const queryHelpComponent = (
  <QueryHelper entityName="audit event" example={queryExamples} commonFields={[]} fieldMap={fieldMap} />
);

type Props = {
  paginationQueryParameter: PaginationQueryParameterResult,
}

const StyledDropdownButton = styled(DropdownButton)`
  margin-left: 5;
`;

const AuditLogEntries = ({ paginationQueryParameter: { page, pageSize, resetPage } }: Props) => {
  useEffect(() => {
    style.use();

    return () => style.unuse();
  }, []);

  useEffect(() => {
    AuditLogActions.entriesPaginated(page, pageSize);
  }, [page, pageSize]);

  const { paginatedEntries } = useStore(AuditLogStore);

  const _onPageChange = useCallback((newPage, newPageSize) => {
    if (paginatedEntries?.query) {
      AuditLogActions.searchPaginated(newPage, newPageSize, paginatedEntries.query);
    } else {
      AuditLogActions.entriesPaginated(newPage, newPageSize);
    }
  }, [paginatedEntries?.query]);

  const _onSearch = useCallback((query, cb) => {
    resetPage();
    AuditLogActions.searchPaginated(1, pageSize, query).then(() => cb());
  }, [pageSize, resetPage]);

  const _onReset = useCallback(() => {
    resetPage();
    AuditLogActions.entriesPaginated(1, pageSize);
  }, [pageSize, resetPage]);

  if (!paginatedEntries) {
    return (
      <Alert bsStyle="info">
        Loading audit log entries...
      </Alert>
    );
  }

  return (
    <div className="auditlog-entries">
      <h2>
        Audit Log Entries
        <span>&nbsp;<small>{paginatedEntries.total} total</small></span>
      </h2>

      <div className="auditlog-entries-content">
        <PaginatedList totalItems={paginatedEntries.total}
                       pageSizes={PAGE_SIZES}
                       onChange={_onPageChange}>
          <SearchForm onSearch={_onSearch}
                      onReset={_onReset}
                      queryWidth={500}
                      useLoadingState
                      queryHelpComponent={queryHelpComponent}>
            <StyledDropdownButton id="export-entries-dropdown" title="Export Results">
              <MenuItem href={AuditLogStore.getExportURL('json', paginatedEntries.query)} target="_blank">JSON</MenuItem>
              <MenuItem href={AuditLogStore.getExportURL('csv', paginatedEntries.query)} target="_blank">CSV</MenuItem>
            </StyledDropdownButton>
          </SearchForm>
          <AuditLogEntriesTable entries={paginatedEntries.entries} />
        </PaginatedList>
      </div>
    </div>
  );
};

export default withPaginationQueryParameter(AuditLogEntries, { pageSizes: PAGE_SIZES });
