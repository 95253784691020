import * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { OrderedMap } from 'immutable';

import ActiveFilters from 'components/common/EntityFilters/ActiveFilters';
import type { Attributes } from 'stores/PaginationTypes';
import type { UrlQueryFilters, Filters, Filter } from 'components/common/EntityFilters/types';
import FilterDropdown from 'report/report-creation/content/filters/FilterDropdown';
import useFiltersWithTitle from 'components/common/EntityFilters/hooks/useFiltersWithTitle';
import Button from 'components/bootstrap/Button';
import Icon from 'components/common/Icon';

type Props = {
  attributes: Attributes,
  filters: UrlQueryFilters | undefined,
  setFilters: (urlQueryFilters: UrlQueryFilters) => void,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 10px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const ActiveFilterContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-left: 5px;
`;

const ClearAllButton = styled(Button)`
  margin-left: 5px;
`;

const FiltersBar = ({ attributes, filters, setFilters }: Props) => {
  const {
    data: activeFilters,
    onChange: onChangeFiltersWithTitle,
  } = useFiltersWithTitle(
    filters,
    attributes,
    !!attributes,
  );
  const onChangeFilters = useCallback((newFilters: Filters) => {
    const newUrlQueryFilters = newFilters.entrySeq()
      .reduce((col, [attributeId, filterCol]) => (
        col.set(attributeId, [...(col.get(attributeId) ?? []), ...filterCol.map(({ value }) => value)])
      ), OrderedMap<string, Array<string>>());

    onChangeFiltersWithTitle(newFilters, newUrlQueryFilters);
    setFilters(newUrlQueryFilters);
  }, [onChangeFiltersWithTitle, setFilters]);

  const onChangeFilter = useCallback((attributeId: string, prevValue: string, newFilter: Filter) => {
    const filterGroup = activeFilters.get(attributeId);
    const updatedFilterGroup = filterGroup.map(((oldFilter) => (oldFilter.value === prevValue ? newFilter : oldFilter)));

    onChangeFilters(activeFilters.set(attributeId, updatedFilterGroup));
  }, [activeFilters, onChangeFilters]);

  const onCreateFilter = useCallback((attributeId: string, filter: Filter) => {
    onChangeFilters(OrderedMap(activeFilters).set(
      attributeId,
      [...(activeFilters?.get(attributeId) ?? []), filter],
    ));
  }, [activeFilters, onChangeFilters]);

  const onDeleteFilter = useCallback((attributeId: string, filterId: string) => {
    const filterGroup = activeFilters.get(attributeId);
    const updatedFilterGroup = filterGroup.filter(({ value }) => value !== filterId);

    return onChangeFilters(updatedFilterGroup.length
      ? activeFilters.set(attributeId, updatedFilterGroup)
      : activeFilters.remove(attributeId));
  }, [activeFilters, onChangeFilters]);

  const clearFilters = useCallback(() => onChangeFilters(OrderedMap()), [onChangeFilters]);

  return (
    <Container>
      <Column>
        Filters:
      </Column>
      <Column>
        <Row>
          <FilterDropdown attribute="owner"
                          filterableAttributes={attributes}
                          activeFilters={activeFilters}
                          onCreateFilter={onCreateFilter}>
            <Icon name="account_circle" />Created By
          </FilterDropdown>
          <FilterDropdown attribute="_id"
                          filterableAttributes={attributes}
                          activeFilters={activeFilters}
                          onCreateFilter={onCreateFilter}>
            <Icon name="team_dashboard" /> Dashboard
          </FilterDropdown>
          <ClearAllButton onClick={clearFilters} bsSize="small">Clear All</ClearAllButton>
        </Row>
        {activeFilters ? (
          <Row>
            <ActiveFilterContainer>
              <ActiveFilters filters={activeFilters}
                             attributes={attributes}
                             onChangeFilter={onChangeFilter}
                             onDeleteFilter={onDeleteFilter}
                             filterValueRenderers={{}} />
            </ActiveFilterContainer>
          </Row>
        ) : null}
      </Column>
    </Container>
  );
};

export default FiltersBar;
