import * as React from 'react';
import { useMemo, useCallback } from 'react';
import Immutable from 'immutable';

import type { AbsoluteTimeRange, TimeRange } from 'views/logic/queries/Query';
import type { Message } from 'views/components/messagelist/Types';
import type { FieldTypeMappingsList } from 'views/logic/fieldtypes/types';
import LogViewWidget from 'logview/components/LogViewWidget';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import WidgetFrame from 'views/components/widgets/WidgetFrame';
import { WidgetHeader } from 'views/components/widgets';
import WidgetContainer from 'views/components/WidgetContainer';
import { fetchSearchResults, PAGE_SIZE } from 'data-warehouse/search/useSearchResults';
import type { LogViewMessage } from 'logview/types';

import type LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const messageListAfter = (messages: Array<LogViewMessage>) => {
  if (messages?.length === 0 || messages?.length < PAGE_SIZE) {
    return undefined;
  }

  const lastMessage = messages[0];

  return ({
    timestamp: lastMessage.message.timestamp,
    id: lastMessage.message._id,
  });
};

type Props = {
  searchFilters: { stream: string, timerange: TimeRange, queryString: string },
  config: LogViewWidgetConfig,
  messages: Array<LogViewMessage>,
  effectiveTimerange: AbsoluteTimeRange,
  editing: boolean,
  setLoadingState: (loading: boolean) => void,
  fields: FieldTypeMappingsList,
  onChangeConfig: (newConfig: LogViewWidgetConfig) => Promise<void>,
  messageFields: Array<string>,
};

const DWLogViewWidget = ({ config, effectiveTimerange, messages, setLoadingState, editing, fields, onChangeConfig, searchFilters, messageFields }: Props) => {
  const fieldTypes = useMemo(() => ({ all: fields ?? Immutable.List(), queryFields: Immutable.Map<string, FieldTypeMappingsList>() }), [fields]);
  const initialAfter = useMemo(() => messageListAfter(messages), [messages]);
  const onLoadMessages = useCallback(async (searchAfter: { timestamp: string, id: string }) => {
    const prevMessages = await fetchSearchResults({ ...searchFilters, searchAfter, fields: messageFields });

    return {
      messages: prevMessages,
      after: messageListAfter(prevMessages),
    };
  }, [messageFields, searchFilters]);
  const onLoadMessage = useCallback((message: { message: { [fieldName: string]: unknown, }}) => (
    Promise.resolve({ formatted_fields: message.message, filtered_fields: message.message, fields: message.message } as unknown as Message)
  ), []);

  return (
    <FieldTypesContext.Provider value={fieldTypes}>
      <WidgetContainer className="widgetFrame" isFocused>
        <WidgetFrame widgetId="data-wearhouse-log-view-widget">
          <WidgetHeader title="Log view"
                        hideDragHandle
                        loading={false}
                        editing={editing}
                        onRename={() => {}} />
          <LogViewWidget config={config}
                         effectiveTimerange={effectiveTimerange}
                         initialAfter={initialAfter}
                         enableMessageDetails={false}
                         messages={messages}
                         onLoadMessages={onLoadMessages}
                         editing={editing}
                         onLoadMessage={onLoadMessage}
                         setLoadingState={setLoadingState}
                         fields={fields}
                         onChangeConfig={onChangeConfig} />
        </WidgetFrame>
      </WidgetContainer>
    </FieldTypesContext.Provider>
  );
};

export default DWLogViewWidget;
