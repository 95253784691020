import { useEffect } from 'react';

import useHistory from 'routing/useHistory';

import workspaceV2Routes from './Routes.js';

type WorkspaceV2InputConfigurationProps = {
  url?: string;
};

// https://stackoverflow.com/questions/52912238/render-methods-should-be-a-pure-function-of-props-and-state
const WorkspaceV2InputConfiguration = ({
  url = workspaceV2Routes.INTEGRATIONS.WorkspaceV2.ACTIVITYAPI.index,
}: WorkspaceV2InputConfigurationProps) => {
  const history = useHistory();

  useEffect(() => {
    history.push(url);
  }, [history, url]);

  return null;
};

export default WorkspaceV2InputConfiguration;
