import * as React from 'react';

import { Modal } from 'security-app/components/common';
import { Spinner } from 'components/common';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';
import EventDefinitionFormContainer from 'components/event-definitions/event-definition-form/EventDefinitionFormContainer';

import DefinitionFormControls from './DefinitionFormControls';

type Props = {
  eventDefinition?: EventDefinition,
  loading?: boolean,
  onClose: () => void,
  action?: 'create' | 'edit'
  onSubmit: () => void,
  onCancel: () => void,
};

function DefinitionFormModal({ eventDefinition, loading = false, onClose, action = 'edit', onSubmit, onCancel }: Props) {
  return (
    <Modal show
           onClose={onClose}
           maxWidth="1500px"
           title={`${eventDefinition?.id ? 'Edit' : 'New'} Event Definition`}>
      {loading ? <Spinner text="Loading event definition" /> : (
        <EventDefinitionFormContainer eventDefinition={eventDefinition}
                                      action={action}
                                      onCancel={onCancel}
                                      formControls={DefinitionFormControls}
                                      onSubmit={onSubmit} />
      )}
    </Modal>
  );
}

export default DefinitionFormModal;
