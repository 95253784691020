import React, { useState, useMemo } from 'react';

import OidcBackendMetaContext from 'authentication/components/oidc/config/components/OidcBackendMetaContext';

type Props = {
  children: React.ReactNode,
  defaultValue: { backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean},
};

const OidcBackendMetaProvider = ({ children, defaultValue }: Props) => {
  const [contextValue, setContextValue] = useState<{ backendId: string, backendGroupSyncIsActive: boolean, licenseIsValid: boolean } | undefined>(defaultValue);
  const oidcBackendMetaContextValue = useMemo(() => ({ ...contextValue, setContextValue }), [contextValue]);

  return (
    <OidcBackendMetaContext.Provider value={oidcBackendMetaContextValue}>
      {children}
    </OidcBackendMetaContext.Provider>
  );
};

export { OidcBackendMetaContext };
export default OidcBackendMetaProvider;
