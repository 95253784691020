import * as React from 'react';

import { singleton } from 'logic/singleton';
import type { LogViewMessage } from 'logview/types';

export interface MessageDetailsContextValue {
  activeMessageDetails: LogViewMessage,
  setActiveMessageDetailsId: (messageId: LogViewMessage['message']['_id']) => void,
  selectNextMessage: () => void | undefined,
  selectPrevMessage: () => void | undefined,
}

const MessageDetailsContext = React.createContext<MessageDetailsContextValue | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewMessageDetailsContext', () => MessageDetailsContext);
