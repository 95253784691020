import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Icon } from 'components/common';

import useFetch from './common/hooks/useFetch';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import type { FormData } from './context/FormData';
import { toO365InputCreateRequest } from './common/formDataAdapter';

import FormWrap from '../common/FormWrap';

const Container = styled.div(({ theme }) => css`
  border: 1px solid ${theme.colors.variant.darkest.default};
  margin: 25px 0;
  padding: 15px;
  border-radius: 4px;
`);

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(({ theme }) => css`
  list-style: none;
  margin: 0 0 25px 10px;
  padding: 0;

  li {
    padding: 5px;

    &:nth-of-type(odd) {
      background-color: ${theme.colors.table.row.backgroundStriped};
    }
  }

  strong::after {
    content: ':';
    margin-right: 5px;
  }
`);

type Props = {
  onSubmit: (data?: FormData) => void,
  externalInputSubmit?: boolean
};

const StepReview = ({ onSubmit, externalInputSubmit = false }: Props) => {
  const [formError, setFormError] = useState(null);
  const { formData } = useContext(FormDataContext);

  const throttleEnabled = !!formData.o365ThrottleEnabled?.value;
  const dropSensitiveDlpLogs = !!formData.o365DropSensitiveDlpLogs.value;
  const storeFullMessage = !!formData.o365StoreFullMessage?.value;

  const [saveInput, setSaveInput] = useFetch(
    null,
    () => onSubmit(),
    'POST',
    toO365InputCreateRequest(formData),
  );

  useEffect(() => {
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: <span>We were unable to save your Input, please try again in a few moments.</span>,
      });
    }
  }, [saveInput.error, setSaveInput]);

  const handleSubmit = () => {
    if (externalInputSubmit) {
      onSubmit(formData);

      return;
    }

    setSaveInput(ApiRoutes.INTEGRATIONS.O365.SAVE_INPUT);
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Save and Start Input"
              loading={saveInput.loading}
              error={formError}
              description="Check out everything below to make sure it&apos;s correct, then click the button below to complete your O365 setup!">

      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name</strong>
            <span>{formData.o365Name.value}</span>
          </li>
          <li>
            <strong>Subscribed To</strong>
            <span>{formData.contents.value}</span>
          </li>
          <li>
            <strong>Subscription Plan</strong>
            <span>{formData.subscriptionType.value}</span>
          </li>
          <li>
            <strong>Polling Interval (Minutes)</strong>
            <span>{formData.pollingInterval.value}</span>
          </li>
          <li>
            <strong>Enable Throttling</strong>
            <span><Icon name={throttleEnabled ? 'check_circle' : 'cancel'} /></span>
          </li>
          <li>
            <strong>Drop Sensitive DLP Logs</strong>
            <span><Icon name={dropSensitiveDlpLogs ? 'check_circle' : 'cancel'} /></span>
          </li>
          <li>
            <strong>Store Full Message</strong>
            <span><Icon name={storeFullMessage ? 'check_circle' : 'cancel'} /></span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

export default StepReview;
