import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_CONFIG_QUERY_KEYS } from 'data-warehouse/Constants';

const fetchDataWarehouseConfig = () => fetch('GET', qualifyUrl(`${DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.config.base().url}`));
export type DataWarehouseConfig = {
  active_backend: string,
  iceberg_commit_interval: string,
  iceberg_target_file_size: number,
  parquet_row_group_size: number,
  parquet_page_size: number,
  journal_reader_batch_size: number,
  optimize_job_enabled: boolean,
  optimize_job_interval: string,
  parallel_retrieval_enabled: boolean,
  retrieval_convert_threads: number,
  retrieval_convert_batch_size: number,
  retrieval_inflight_requests: number,
  retrieval_bulk_batch_size: number,
};

const useDataWarehouseConfig = (enabled = true): {
  data: DataWarehouseConfig;
  refetch: () => void;
  isInitialLoading: boolean,
  isSuccess: boolean,
  error: Error,
} => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<DataWarehouseConfig, Error>(
    [...DATA_WAREHOUSE_CONFIG_QUERY_KEYS],
    () => fetchDataWarehouseConfig(),
    {
      keepPreviousData: true,
      enabled,
    },
  );

  return ({
    data: data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  });
};

export default useDataWarehouseConfig;
