import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';

import { Button, Table, Well } from 'components/bootstrap';
import NumberUtils from 'util/NumberUtils';
import type { EstimateType } from 'data-warehouse/Types';

type Props = {
  estimate: EstimateType;
}

const StyledWell = styled(Well)`
  margin-top: 0;
`;

const StyledP = styled.p`
  font-weight: bold;
`;

const ArchiveSizeEstimate = ({ estimate }: Props) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <StyledP>Estimation:</StyledP>
      <StyledWell bsSize="small" className="configuration-well react-configuration-well">
        <ul>
          <li>Message count: {estimate?.row_count}</li>
          <li>Compressed Size: {NumberUtils.formatBytes(estimate?.total_size_bytes)}</li>
        </ul>
        {Object.keys(estimate?.size_estimates).length > 1 && (
        <>
          <Button bsSize="xs" bsStyle="link" onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'More details'}</Button>
          {showMore && (
          <Table condensed striped>
            <thead>
              <th>Stream title</th>
              <th>Message count</th>
              <th>Size</th>
            </thead>
            <tbody>
              {Object.keys(estimate?.size_estimates).map((id) => (
                <tr key={id}>
                  <td>{estimate?.size_estimates?.[id].archive_name}</td>
                  <td>{estimate?.size_estimates?.[id].row_count}</td>
                  <td>{NumberUtils.formatBytes(estimate?.size_estimates?.[id]?.size_bytes)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          )}
        </>
        )}
      </StyledWell>
    </>
  );
};

export default ArchiveSizeEstimate;
