import * as React from 'react';

import type { DataWarehouse } from 'data-warehouse/Types';
import RestoredArchivesCell from 'data-warehouse/data-warehouse-overview/cells/RestoredArchivesCell';
import Routes from 'routing/Routes';
import type { ColumnRenderers } from 'components/common/EntityDataTable';
import { Timestamp } from 'components/common';
import { Link } from 'components/common/router';

import DaysOfData from './cells/DaysOfData';

const customColumnRenderers = (): ColumnRenderers<DataWarehouse> => ({
  attributes: {
    last_known_stream_title: {
      renderCell: (_last_known_stream_title: string, datawarehouse) => datawarehouse.last_known_stream_title,
      width: 0.2,
    },
    stream: {
      renderCell: (_last_known_stream_title: string, datawarehouse) => (
        <Link to={`${Routes.stream_view(datawarehouse.stream_id)}?segment=destinations`}>{datawarehouse.last_known_stream_title}</Link>
      ),
      width: 0.2,
    },
    days_of_data: {
      renderCell: (_days_of_data: string, datawarehouse) => <DaysOfData datawarehouse={datawarehouse} />,
      width: 0.1,
    },
    message_count: {
      width: 0.1,
    },
    restored_indices: {
      renderCell: (_restored_indices: string, archive) => <RestoredArchivesCell archive={archive} />,
      width: 0.1,
    },
    timestamp_from: {
      renderCell: (_timestamp_from: string, archive) => archive?.timestamp_from
        && <Timestamp dateTime={archive.timestamp_from} />,
      width: 0.1,
    },
    timestamp_to: {
      renderCell: (_timestamp_to: string, archive) => archive?.timestamp_to
        && <Timestamp dateTime={archive.timestamp_to} />,
      width: 0.1,
    },
  },
});

export default customColumnRenderers;
