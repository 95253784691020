import __request__ from 'routing/request';
interface CreateClientCertRequest {
    readonly principal: string;
    readonly certificate_lifetime: string;
    readonly password: string;
    readonly role: string;
}
/**
 * Creates a client certificate
 */
export function createClientCert(request: CreateClientCertRequest): Promise<unknown> {
    return __request__('POST', '/ca/clientcert', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * removes the cert and the user from the role
 */
export function deleteClientCert(role: string, principal: string): Promise<unknown> {
    return __request__('DELETE', `/ca/clientcert/${role}/${principal}`, null, {}, {
        'Accept': ['application/json']
    });
}
