import * as React from 'react';
import styled, { css } from 'styled-components';

import { Link } from 'components/common/router';
import { Alert } from 'components/bootstrap';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';
import DocsHelper from 'util/DocsHelper';
import useUsageWarningAcknowledgementMutation from 'license/notifications/hooks/useUsageWarningAcknowledgementMutation';

const StyledAlert = styled(Alert)(({ theme }) => css`
  margin-bottom: ${theme.spacings.md};
`);

type Props = {
  remainingVolume: number,
}

const LicenseUsageWarningNotification = ({ remainingVolume } : Props) => {
  const currentUser = useCurrentUser();
  const { acknowledgeNotifcation } = useUsageWarningAcknowledgementMutation();

  const onDismiss = () => { acknowledgeNotifcation(); };

  const bsStyle = remainingVolume <= 40 ? 'warning' : 'info';

  if (remainingVolume === undefined) return null;

  return (
    <StyledAlert bsStyle={bsStyle} title="Graylog License Volume" onDismiss={onDismiss}>
      <p>
        Notification that your {isPermitted(currentUser?.permissions, 'licenses:create') ? (<Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Graylog License</Link>) : ('Graylog License')} has less than {remainingVolume}% of its volume entitlement remaining.{' '}
        <br />
        <span>
          The <Link to={DocsHelper.toString(DocsHelper.PAGES.LICENSE_MANAGEMENT)}>Licenses Page</Link> details which Enterprise features will be disabled when no valid Graylog License is present.
          Please contact your Graylog Account Manager for further information and renewals.
        </span>
      </p>
    </StyledAlert>
  );
};

export default LicenseUsageWarningNotification;
