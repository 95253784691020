import React from 'react';

import type { LicenseState } from 'license/types';
import { Label } from 'components/bootstrap';
import { LICENSE_STATUS } from 'license/constants';

type Props = {
  status: LicenseState,
}

const LicenseStatus = ({ status }: Props) => {
  switch (status) {
    case LICENSE_STATUS.active:
      return <Label bsStyle="success">{LICENSE_STATUS.active}</Label>;
    case LICENSE_STATUS.queued:
      return <Label bsStyle="primary">{LICENSE_STATUS.queued}</Label>;
    case LICENSE_STATUS.expired:
      return <Label bsStyle="warning">{LICENSE_STATUS.expired}</Label>;
    case LICENSE_STATUS.inactive:
      return <Label bsStyle="default">{LICENSE_STATUS.inactive}</Label>;
    default:
      return null;
  }
};

export default LicenseStatus;
