import React, { createContext, useState } from 'react';

type AdvancedOptions = {
  isAdvancedOptionsVisible: boolean,
  setAdvancedOptionsVisibility: (visible: boolean) => void,
}

export const AdvancedOptionsContext = createContext<AdvancedOptions>(undefined);

type AdvancedOptionsProviderProps = {
  children: any;
};

export const AdvancedOptionsProvider = ({
  children,
}: AdvancedOptionsProviderProps) => {
  const [isAdvancedOptionsVisible, setAdvancedOptionsVisibility] = useState(false);

  return (
    <AdvancedOptionsContext.Provider value={{
      isAdvancedOptionsVisible,
      setAdvancedOptionsVisibility,

    }}>
      {children}
    </AdvancedOptionsContext.Provider>
  );
};
