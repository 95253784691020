import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import ArchiveActions from 'archive/ArchiveActions';
import { defaultOnError } from 'util/conditional/onError';

import type { Query, PaginatedArchives } from '../types';

export const PAGE_SIZES = [20, 50, 100, 200, 500];
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_QUERY = {
  query: '',
  per_page: DEFAULT_PAGE_SIZE,
  page: 1,
};
export const DEFAULT_PAGINATION = {
  ...DEFAULT_QUERY,
  count: 0,
  total: 0,
};

export const urlPrefix = '/plugins/org.graylog.plugins.archive';
export const urlSuffix = '/cluster/archives/catalog';
export const ARCHIVE_QUERY_KEY = 'archives';

const refetchInterval = 5000;

type Result = {
  data: PaginatedArchives,
  isLoading: boolean,
  refetch: () => unknown,
};

const useIsPeriodicalRequest = (nextQueryParams: Query) => {
  const prevQueryParams = useRef(nextQueryParams);

  useEffect(() => {
    prevQueryParams.current = nextQueryParams;
  }, [nextQueryParams]);

  return prevQueryParams.current === nextQueryParams;
};

const useArchivesQuery = (queryParams: Query): Result => {
  const { page, per_page: perPage, query } = queryParams;

  const isPeriodicalRequest = useIsPeriodicalRequest(queryParams);

  return useQuery(
    [ARCHIVE_QUERY_KEY, { queryParams }],
    () => defaultOnError(ArchiveActions.searchPaginated(page, perPage, query, isPeriodicalRequest), 'Loading archives failed with status'),
    {
      retry: 0,
      refetchInterval: refetchInterval,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'error'],
      initialData: {
        archives: [],
        archives_context: {},
        backends_context: {},
        ...DEFAULT_PAGINATION,
      },
    });
};

export default useArchivesQuery;
