import styled from 'styled-components';

const DataWell = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacings.md};
  background-color: ${({ theme }) => theme.colors.global.background};
`;

export default DataWell;
