import React, { useCallback, useState } from 'react';

import { Button, MenuItem } from 'components/bootstrap';
import { LinkContainer } from 'components/common/router';
import Routes from 'routing/Routes';
import type { DataWarehouse } from 'data-warehouse/Types';
import { MoreActions } from 'components/common/EntityDataTable';
import { DATA_WAREHOUSE_ROUTES } from 'data-warehouse/Constants';
import RetrievalArchiveButton from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveButton';
import RetrievalArchiveModal from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveModal';
import DeleteArchiveModal from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveModal';
import DeleteArchiveButton from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveButton';

const EntityActions = ({ archiveStreamId }: { archiveStreamId: string }) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleRetrievalModal = () => setShowRetrievalModal((cur) => !cur);
  const toggleDeleteModal = () => setShowDeleteModal((cur) => !cur);

  return (
    <>
      <LinkContainer to={`${Routes.stream_view(archiveStreamId)}?segment=destinations`}>
        <Button bsStyle="info" bsSize="xsmall">Data Routing</Button>
      </LinkContainer>
      <MoreActions>
        <MenuItem href={`${DATA_WAREHOUSE_ROUTES.SEARCH}?streams=${archiveStreamId}`}>
          Search in warehouse
        </MenuItem>
        <RetrievalArchiveButton onClick={toggleRetrievalModal} />
        <DeleteArchiveButton onClick={toggleDeleteModal} />
      </MoreActions>
      {showRetrievalModal && (
        <RetrievalArchiveModal archiveStreamId={archiveStreamId}
                               onToggleModal={toggleRetrievalModal} />
      )}
      {showDeleteModal && (
        <DeleteArchiveModal archiveStreamId={archiveStreamId}
                            onToggleModal={toggleDeleteModal} />
      )}
    </>
  );
};

const useTableElements = () => {
  const entityActions = useCallback((dataWarehouse: DataWarehouse) => (
    <EntityActions archiveStreamId={dataWarehouse.stream_id} />
  ), []);

  return {
    entityActions,
  };
};

export default useTableElements;
