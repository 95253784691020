import React, { useContext, useState } from 'react';

import Select from 'components/common/Select';
import { Input } from 'components/bootstrap';

import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';

import FormWrap from '../common/FormWrap';

type StepSubscribeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepSubscribe = ({
  onSubmit,
  onChange,
}: StepSubscribeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError] = useState(null);

  const {
    pollingInterval,
  } = formData;

  const availableLog = [
    { label: 'VPC Flow', value: 'VPC_FLOW_LOGS' },
    { label: 'Firewall', value: 'FIREWALL_LOGS' },
    { label: 'Audit Data Access', value: 'AUDIT_LOGS_DATA_ACCESS' },
    { label: 'Audit Activity', value: 'AUDIT_LOGS_ACTIVITY' },
    { label: 'Audit System Event', value: 'AUDIT_LOGS_SYSTEM_EVENT' },
    { label: 'Audit Policy Denied', value: 'AUDIT_LOGS_POLICY_DENIED' }];

  const [logTypes, setLogTypes] = useState('');
  const [logTypeVal, setLogTypeVal] = useState(false);

  const handleSubmit = () => {
    if (logTypes.length === 0) {
      setLogTypeVal(true);

      return;
    }

    formData.logs.value = logTypes;
    onSubmit();
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              description="">
      <label className="control-label" htmlFor="logs">Logs Types to Collect</label>
      <Select multi
              name="logs"
              id="logs"
              onChange={(selected) => { setLogTypes(selected); setLogTypeVal(false); }}
              options={availableLog}
              value={logTypes}
              matchProp="label" />
      {logTypeVal && logTypes.length === 0 && <label htmlFor="pollingInterval">Select at least one</label>}
      <span className="help-block">The activity log for the above log types will be fetched.</span>

      <Input id="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue}
             min="5"
             max="60"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new data in GCP. The smallest allowable interval is 5 minute."
             label="Polling Interval" />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
