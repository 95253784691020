import React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';

type Props = {
  text: string,
  severity?: 'warning' | 'info',
}

const StyledText = styled.p`
  margin-bottom: 0;
`;

const Violation = ({ text, severity }: Props) => {
  switch (severity) {
    case 'warning':
      return (<StyledText><Icon name="warning" className="text-danger" /> {text}</StyledText>);
    case 'info':
      return (<StyledText>{text}</StyledText>);
    default:
      return (<StyledText><Icon name="check" className="text-success" /> {text}</StyledText>);
  }
};

export default Violation;
