import React from 'react';

import ProductLink from 'components/enterprise/ProductLink';
import type { LicenseSubject } from 'license/types';
import LicenseCheck from 'license/LicenseCheck';

type Props = {
  children?: React.ReactNode
  href?: string
  clusterId?: string
  licenseSubject?: LicenseSubject,
}

const EnterpriseProductLink = ({ children = null, clusterId = null, href = '', licenseSubject = '/license/enterprise' }: Props) => (
  <LicenseCheck licenseSubject={licenseSubject} displayLicenseWarning={false} hideChildren>
    <ProductLink href={href} clusterId={clusterId}>
      {children}
    </ProductLink>
  </LicenseCheck>
);

export default EnterpriseProductLink;
