import { useQuery } from '@tanstack/react-query';

import EnterpriseApiRoutes from 'common/ApiRoutes';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import UserNotification from 'util/UserNotification';
import type { DrawDownLicenseTraffic } from 'license/types';
import type FetchError from 'logic/errors/FetchError';
import { onError } from 'util/conditional/onError';

export const DRAWDOWN_LICENSE_REMAINING_TRAFFIC_QUERY_KEY = 'drawdown_license_remaining_traffic';

const getTraffic = (graphDays: number = 30) => fetch(
  'GET',
  qualifyUrl(EnterpriseApiRoutes.License.drawDownRemainingTraffic(graphDays).url),
);

const useDrawDownLicenseRemainingTraffic = (graphDays: number = 30): {
  data: DrawDownLicenseTraffic,
  isLoading: boolean,
  isFetching: boolean,
  isError: boolean,
  refetch: () => void
} => {
  const { data, isFetching, isLoading, isError, refetch } = useQuery<DrawDownLicenseTraffic, FetchError>(
    [DRAWDOWN_LICENSE_REMAINING_TRAFFIC_QUERY_KEY, graphDays],
    () => onError(getTraffic(graphDays), (errorThrown: FetchError) => {
      if (!(errorThrown.status === 404)) {
        UserNotification.error(`Loading drawDown license remaining traffic failed with: ${errorThrown}`);
      }
    }),
  );

  return ({
    data,
    isFetching,
    isLoading,
    isError,
    refetch,
  });
};

export default useDrawDownLicenseRemainingTraffic;
