import * as React from 'react';
import { useState } from 'react';

import { Modal } from 'components/bootstrap';
import { Icon } from 'components/common';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';

import IlluminateBundleInstall from './IlluminateBundleInstall';
import IlluminateBundleEnable from './IlluminateBundleEnable';
import StepNavigation from './StepNavigation';
import type { StepKey } from './StepsOverview';
import StepsOverview, { STEPS } from './StepsOverview';
import IlluminatePacksEnable from './IlluminatePacksEnable';
import SigmaRulesEnable from './SigmaRulesEnable';

import type { CoverageEntity } from '../types';

type Props = {
  onHide: () => void,
  coverageEntity?: CoverageEntity
  isTactic?: boolean,
}

const ImproveCoverageModal = ({ onHide, coverageEntity = undefined, isTactic = undefined }: Props) => {
  const [step, setStep] = useState<StepKey>(STEPS.overview.key);
  const sendTelemetry = useSendTelemetry();

  const onNextClicked = (nextStep: StepKey) => {
    setStep(nextStep);

    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.THREAT_COVERAGE.IMPROVE_COVERAGE_STEP_NEXT, {
      app_pathname: 'security',
      app_section: 'threat-coverage',
      event_details: { step: nextStep },
    });
  };

  return (
    <Modal show
           onHide={onHide}
           bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          {STEPS[step].title(coverageEntity, isTactic)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 'overview' && <StepsOverview setStep={setStep} coverageEntity={coverageEntity} isTactic={isTactic} />}
        {step === STEPS.illuminateBundleInstall.key && (
          <>
            <StepNavigation setStep={setStep}
                            nextButtonText={<>Enable Illuminate Bundle <Icon name="arrow_right_alt" /></>}
                            onClickNext={() => onNextClicked(STEPS.illuminateBundleEnable.key)} />
            <IlluminateBundleInstall />
          </>
        )}
        {step === STEPS.illuminateBundleEnable.key && (
          <>
            <StepNavigation setStep={setStep}
                            nextButtonText={<>Enable Illuminate Packs <Icon name="arrow_right_alt" /></>}
                            onClickNext={() => onNextClicked(STEPS.illuminatePacksEnable.key)} />
            <IlluminateBundleEnable />
          </>
        )}
        {step === STEPS.illuminatePacksEnable.key && (
          <>
            <StepNavigation setStep={setStep}
                            nextButtonText={<>Enable Sigma Rules <Icon name="arrow_right_alt" /></>}
                            onClickNext={() => onNextClicked(STEPS.sigmaRulesEnable.key)} />
            <IlluminatePacksEnable />
          </>
        )}
        {step === STEPS.sigmaRulesEnable.key && (
          <>
            <StepNavigation setStep={setStep} nextButtonText="Close modal" onClickNext={onHide} />
            <SigmaRulesEnable coverageEntity={coverageEntity} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImproveCoverageModal;
