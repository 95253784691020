import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

const urlPrefix = '/plugins/org.graylog.plugins.securityapp.investigations/investigations/summary/acceptance';

export type AcceptanceStateJson = {
  accepted: boolean,
  acceptance_details: string,
  terms: {
    'version': string,
    'date': string,
    'text': string,
  }
}

export type AcceptanceState = {
  accepted: boolean,
  acceptanceDetails: string,
  terms: {
    'version': string,
    'date': string,
    'text': string,
  }
}

const defaultState: AcceptanceState = {
  accepted: null,
  acceptanceDetails: null,
  terms: null,
};
const getAcceptanceState = (): Promise<AcceptanceState> => fetch<AcceptanceStateJson>(
  'GET',
  qualifyUrl(urlPrefix),
).then((state: AcceptanceStateJson) => ({
  ...state,
  acceptanceDetails: state.acceptance_details,
}));

const useAcceptanceState = ():{
  data: AcceptanceState,
  isLoading: boolean,
} => {
  const {
    data,
    isLoading,
  } = useQuery<AcceptanceState, Error>(
    ['investigation-ai-acceptance-by-user'],
    () => defaultOnError(getAcceptanceState(), 'Fetching acceptance status failed with status', 'Could not fetch acceptance status'),
    {
      cacheTime: 0,
    });

  return {
    data: data ?? defaultState,
    isLoading,
  };
};

export default useAcceptanceState;
