import type { PluginExports } from 'graylog-web-plugin/plugin';

import CoreSigmaModal from './CoreSigmaModal';

const sigmaBindings: PluginExports = {
  'eventDefinitions.components.editSigmaModal': [
    {
      component: CoreSigmaModal,
      key: 'coreSigmaModal',
    },
  ],
};

export default sigmaBindings;
