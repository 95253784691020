import * as React from 'react';
import styled from 'styled-components';

import { Affix, Pluralize } from 'components/common';
import type { ReportCreate, Report } from 'report/types';
import Logo from 'report/common/ReportLogo';

const ReportPreview = styled.div`
  font-size: 14px;
  margin-top: 15px;
  width: 95%;
`;

const DescriptionPreview = styled.dd`
  white-space: pre-wrap;
`;

type Props = {
  report: ReportCreate | Report,
  reportLogo?: string
  children?: React.ReactNode
}

const ReportSummary = ({ report, reportLogo = null, children = [] }: Props) => {
  const numberWidgets = report.widgets.length;
  // report.logo contains the edited logo that will be sent to the server
  // reportLogo contains the existing logo for a report, as we fetch that in a separate API call.

  return (
    <Affix position={{ top: 190, right: 48 }}>
      <div>
        <h3>Report summary</h3>
        <p>
          This is a summary of contents included in the report. You may modify them later if necessary.
        </p>
        <ReportPreview>
          <dl>
            <dt>Title</dt>
            <dd>{report.title || 'No report title given.'}</dd>
            <dt>Subtitle</dt>
            <dd>{report.subtitle || 'No report subtitle given.'}</dd>
            <dt>Logo</dt>
            <dd>
              {reportLogo
                ? <Logo imageSrc={reportLogo} />
                : 'No report logo given.'}
            </dd>
            <dt>Description</dt>
            <DescriptionPreview>{report.description || 'No report description given.'}</DescriptionPreview>
            <dt>Widgets</dt>
            <dd>
              {numberWidgets
                ? (
                  <span>{numberWidgets} <Pluralize singular="widget" plural="widgets" value={numberWidgets} /> currently
                    included in the report.
                  </span>
                )
                : <span>No widgets currently included in the report.</span>}
            </dd>
          </dl>
          {children}
        </ReportPreview>
      </div>
    </Affix>
  );
};

export default ReportSummary;
