import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import { Link, LinkContainer } from 'components/common/router';
import { OverlayTrigger, IfPermitted, RelativeTime } from 'components/common';
import { Button, DropdownButton, MenuItem, BootstrapModalConfirm } from 'components/bootstrap';
import Routes from 'routing/Routes';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import ForwarderForm from './ForwarderForm';
import ForwardersContext from './ForwardersContext';

import type { InputProfile, Forwarder } from '../Types';
import { ForwardersActions } from '../stores/ForwardersStore';
import ForwarderStateBadge from '../states/ForwarderStateBadge';
import ForwarderMetricContainer from '../metrics/ForwarderMetricContainer';

const StyledBadge = styled.div`
  & > .label {
    display: block;
    line-height: inherit;
  }
`;

const StyledButton = styled(Button)`
  padding: 0 15px;
`;

type Props = {
  forwarder: Forwarder,
  inputProfile?: InputProfile,
};

const ForwarderEntry = ({ forwarder, inputProfile }: Props) => {
  const { id, title, description, hostname, version, last_seen_at: lastSeenAt } = forwarder;
  const [showForwarderDeleteModal, setShowForwarderDeleteModal] = useState<boolean>(false);
  const [showRemoveConfigModal, setShowRemoveConfigModal] = useState<boolean>(false);
  const sendTelemetry = useSendTelemetry();

  const { getForwarderContext } = useContext(ForwardersContext);
  const { state: forwarderState } = getForwarderContext(id);

  const _openConfirmation = () => setShowForwarderDeleteModal(true);
  const _closeConfirmation = () => setShowForwarderDeleteModal(false);

  const _openRemoveConfirmation = () => setShowRemoveConfigModal(true);
  const _closeRemoveConfirmation = () => setShowRemoveConfigModal(false);

  const _onDelete = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.FORWARDER.FORWARDER_DELETED, {
      app_pathname: 'forwarder',
      app_section: 'input-profile',
    });

    ForwardersActions.delete(id).then(() => {
      _closeConfirmation();
    });
  };

  const _onRemoveConfiguration = () => {
    ForwardersActions.removeConfiguration(forwarder).then(() => _closeRemoveConfirmation());
  };

  const maybeClippedDescription = description && description.length > 35 ? `${description.slice(0, 35)}…` : description;

  return (
    <tr>
      <td>
        <Link to={Routes.pluginRoute('SYSTEM_FORWARDERS_FORWARDERID')(id)}>
          {title}
        </Link>
      </td>
      <td>
        <OverlayTrigger overlay={<>Last seen <RelativeTime dateTime={lastSeenAt} /></>}
                        placement="top">
          <StyledBadge>
            <ForwarderStateBadge forwarderId={forwarder.id} />
          </StyledBadge>
        </OverlayTrigger>
      </td>
      <td>
        {description === maybeClippedDescription
          ? description
          : (
            <OverlayTrigger overlay={description} placement="top">
              <span>{maybeClippedDescription}</span>
            </OverlayTrigger>
          )}
      </td>
      <td>{hostname}</td>
      <td>{version || 'N/A'}</td>
      <td>{inputProfile ? (
        <Link to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES_INPUTPROFILEID')(inputProfile.id)}>{inputProfile.title}
        </Link>
      ) : 'No input profile'}
      </td>
      <td>
        <ForwarderMetricContainer forwarderId={forwarder.id} />
      </td>
      <td>
        {!inputProfile
          ? (
            <>
              <IfPermitted permissions="forwarders:edit">
                <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_CONFIGURE_FORWARDERID')(id)}>
                  <StyledButton bsSize="xsmall" bsStyle="primary">Configure</StyledButton>
                </LinkContainer>
              </IfPermitted>
              {forwarderState && forwarderState !== 'CONNECTED' && (
                <>
                  &nbsp;
                  <IfPermitted permissions="forwarders:delete">
                    <Button bsSize="xsmall" bsStyle="danger" onClick={_openConfirmation}>Delete</Button>
                  </IfPermitted>
                </>
              )}
            </>
          )
          : (
            <>
              <IfPermitted permissions="forwarders:edit">
                <ForwarderForm forwarder={forwarder} buttonBsSize="xsmall" buttonBsStyle="primary" />
              </IfPermitted>
              &nbsp;
              <span key="split-button-default">
                <DropdownButton bsSize="xsmall"
                                id="forwarder-item"
                                title="Choose action"
                                bsStyle="default"
                                pullRight>
                  <MenuItem href={Routes.pluginRoute('SYSTEM_FORWARDERS_FORWARDERID')(id)} eventKey="1">
                    View
                  </MenuItem>
                  <IfPermitted permissions="forwarders:delete">
                    <MenuItem eventKey="2" onClick={_openRemoveConfirmation}>Remove configuration</MenuItem>
                  </IfPermitted>
                </DropdownButton>{' '}
              </span>
            </>
          )}
        <BootstrapModalConfirm showModal={showForwarderDeleteModal}
                               title="Delete forwarder"
                               onConfirm={_onDelete}
                               onCancel={_closeConfirmation}>
          {`Are you sure you want to delete forwarder "${title}"? This will remove all inputs running on this forwarder.`}
        </BootstrapModalConfirm>
        <BootstrapModalConfirm showModal={showRemoveConfigModal}
                               title="Remove configuration"
                               onConfirm={_onRemoveConfiguration}
                               onCancel={_closeRemoveConfirmation}>
          {`Are you sure you want to remove the configuration of forwarder "${title}"? This will remove all inputs running on this forwarder.`}
        </BootstrapModalConfirm>
      </td>
    </tr>
  );
};

export default ForwarderEntry;
