import * as React from 'react';

import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import { Col, Row } from 'components/bootstrap';
import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import LicenseCheck from 'license/LicenseCheck';
import { LICENSE_SUBJECTS } from 'license/constants';

import ForwarderInputProfile from './forwarders/ForwarderInputProfile';
import { ForwardersActions } from './stores/ForwardersStore';
import ForwarderDetailsComponent from './forwarders/ForwarderDetailsComponent';
import SingleForwarderProvider from './forwarders/SingleForwarderProvider';
import ForwardersContext from './forwarders/ForwardersContext';
import withIsPermitted from './util/withIsPermitted';
import type { Forwarder } from './Types';
import ForwarderPageNavigation from './ForwarderPageNavigation';

type Props = {
  params: {
    forwarderId: string,
  },
};

const requiredPermissions = ({ params }) => [`forwarders:read:${params.forwarderId}`, 'inputprofiles:read', 'forwarderinputs:read'];

const ForwarderPage = ({ params }: Props) => {
  const _handleInputProfileSwitch = (forwarder: Forwarder) => (nextInputProfileId: string) => {
    const updatedForwarder = {
      input_profile_id: nextInputProfileId,
      title: forwarder.title,
      description: forwarder.description,
    };

    ForwardersActions.update(forwarder.id, updatedForwarder).then(() => {
      ForwardersActions.get(params.forwarderId);
    });
  };

  return (
    <SingleForwarderProvider forwarderId={params.forwarderId}>
      <ForwardersContext.Consumer>
        {({ forwarders }) => {
          const forwarder = forwarders[0];

          if (!forwarder) {
            return <Spinner />;
          }

          return (
            <DocumentTitle title={`Forwarder: ${forwarder.title}`}>
              <ForwarderPageNavigation />
              <PageHeader title={`Forwarder: ${forwarder.title}`}
                          documentationLink={{
                            title: 'Input profile documentation',
                            path: ForwarderDocs.INPUT_PROFILE,
                          }}>
                <span>Overview of {forwarder.title} Forwarder, including the Input Profile it is using.</span>
              </PageHeader>
              <LicenseCheck licenseSubject={LICENSE_SUBJECTS.forwarder}
                            displayWarningContainer
                            featureName="Forwarder"
                            bsStyle="danger" />
              <Row className="content">
                <ForwarderDetailsComponent forwarder={forwarder}
                                           onUpdate={() => {}} />
              </Row>
              <Row className="content">
                <Col md={12}>
                  <ForwarderInputProfile forwarder={forwarder}
                                         inputProfileId={forwarder.input_profile_id}
                                         onInputProfileSwitch={_handleInputProfileSwitch(forwarder)} />
                </Col>
              </Row>
            </DocumentTitle>
          );
        }}
      </ForwardersContext.Consumer>
    </SingleForwarderProvider>
  );
};

export default withParams(withIsPermitted(ForwarderPage, requiredPermissions));
