import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { styled } from 'styled-components';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import isEqual from 'lodash/isEqual';
import * as Immutable from 'immutable';

import { DocumentTitle, Spinner } from 'components/common';
import type { FormValues } from 'data-warehouse/search/SearchBar';
import SearchBar from 'data-warehouse/search/SearchBar';
import Sidebar from 'data-warehouse/search/Sidebar';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchConfiguration from 'hooks/useSearchConfiguration';
import type { TimeRange } from 'views/logic/queries/Query';
import { DEFAULT_TIMERANGE } from 'views/Constants';
import useSearchResults from 'data-warehouse/search/useSearchResults';
import AutoRefreshProvider from 'views/components/contexts/AutoRefreshProvider';
import { Alert, Row } from 'components/bootstrap';
import { normalizeFromSearchBarForBackend } from 'views/logic/queries/NormalizeTimeRange';
import useUserDateTime from 'hooks/useUserDateTime';
import { onInitializingTimerange } from 'views/components/TimerangeForForm';
import LogViewWidgetConfig from 'logview/logic/LogViewWidgetConfig';
import DWLogViewWidget from 'logview/components/DWLogViewWidget';
import SearchFeatureExplanation from 'data-warehouse/search/SearchFeatureExplanation';
import useFieldTypes from 'data-warehouse/search/useFieldTypes';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

const DEFAULT_WIDGET_FIELDS = ['http_method', 'action', 'controller', 'took_ms'];
const FIXED_WIDGET_FIELDS = ['id', 'timestamp'];
const PAGE_SIZE = 100;

const StyledPageContentLayout = styled(PageContentLayout)`
  .page-content-grid {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;
const GridContainer = styled.div`
  display: flex;
  height: 100%;
`;

const FullHeightRow = styled(Row)`
  flex: 1;
  overflow: auto;
`;

const StyledAlert = styled(Alert)`
  margin: 0;
`;

type UrlQueryParams = {
  rangetype: string | undefined
  relative: number | undefined,
  from: string | undefined,
  to: string | undefined
  keyword: string | undefined
}

const initialTimeRange = (queryParams: UrlQueryParams): TimeRange => {
  switch (queryParams.rangetype) {
    case 'relative':
      if (queryParams.relative !== undefined) {
        return {
          type: queryParams.rangetype,
          range: queryParams.relative,
        };
      }

      return {
        type: queryParams.rangetype,
        from: queryParams.from ? Number(queryParams.from) : undefined,
        to: queryParams.to ? Number(queryParams.to) : undefined,
      };
    case 'absolute':
      return {
        type: queryParams.rangetype,
        from: queryParams.from,
        to: queryParams.to,
      };
    case 'keyword':
      return {
        type: queryParams.rangetype,
        keyword: queryParams.keyword,
      };
    default:
      return DEFAULT_TIMERANGE;
  }
};

const useSyncUrlQueryParams = (filters: FormValues, setQuery) => {
  useEffect(() => {
    const { timerange, stream, queryString } = filters;

    setQuery({
      from: 'from' in timerange && timerange.from !== undefined ? String(timerange.from) : undefined,
      to: 'to' in timerange && timerange.to !== undefined ? String(timerange.to) : undefined,
      rangetype: timerange.type,
      range: 'range' in timerange ? timerange.range : undefined,
      streams: stream,
      q: queryString,
    });
  }, [filters, setQuery]);
};

const useFilters = () => {
  const { formatTime } = useUserDateTime();

  const [query, setQuery] = useQueryParams({
    rangetype: StringParam,
    from: StringParam,
    to: StringParam,
    range: NumberParam,
    streams: StringParam,
    keyword: StringParam,
    relative: NumberParam,
    q: StringParam,
  });

  const queryStream = query.streams?.split(',')[0];
  const initialFilters = useMemo(() => ({
    timerange: onInitializingTimerange(initialTimeRange(query), formatTime),
    stream: queryStream,
    queryString: query.q,
  }), [formatTime, query, queryStream]);
  const [filters, setFilters] = useState<FormValues>(initialFilters);

  useSyncUrlQueryParams(filters, setQuery);

  return { filters, setFilters };
};

type Props = {
  fieldTypes: Array<FieldTypeMapping>
}

const DataWarehouseSearch = ({ fieldTypes }: Props) => {
  const { userTimezone } = useUserDateTime();
  const [logViewConfig, setLogViewConfig] = useState<LogViewWidgetConfig>(new LogViewWidgetConfig(undefined, Immutable.OrderedSet(DEFAULT_WIDGET_FIELDS), PAGE_SIZE, 'ASC', 'timestamp'));
  const { filters, setFilters } = useFilters();

  const [enableSearchResultFetching, setEnableSearchResultFetching] = useState(false);
  const normalizedSearchFilters = useMemo(() => ({
    ...filters,
    timerange: normalizeFromSearchBarForBackend(filters.timerange, userTimezone),
  }), [filters, userTimezone]);
  const messageFields = useMemo(() => ([...FIXED_WIDGET_FIELDS, ...logViewConfig.fields.toArray()]), [logViewConfig.fields]);
  const { refetch, isFetching, error, data: messages } = useSearchResults(normalizedSearchFilters, [...FIXED_WIDGET_FIELDS, ...logViewConfig.fields.toArray()], enableSearchResultFetching);

  const submitSearchBarForm = useCallback((formValues: FormValues, { resetForm }: { resetForm: (payload: { values: FormValues }) => void }) => {
    setEnableSearchResultFetching(true);

    if (!isEqual(formValues, filters)) {
      setFilters(formValues);
      resetForm({ values: formValues });

      return Promise.resolve();
    }

    return refetch();
  }, [filters, refetch, setFilters]);

  const onChangeConfig = useCallback((newConfig: LogViewWidgetConfig) => {
    setLogViewConfig(newConfig);

    return Promise.resolve();
  }, []);

  return (
    <GridContainer>
      <Sidebar />
      <StyledPageContentLayout>
        <AutoRefreshProvider onRefresh={refetch}>
          <SearchFeatureExplanation />
          <SearchBar onSubmit={submitSearchBarForm}
                     initialValues={filters}
                     fieldTypes={fieldTypes}
                     isLoading={isFetching} />
          <FullHeightRow>
            {isFetching && <Spinner />}
            {!isFetching && !enableSearchResultFetching && (
              <StyledAlert bsStyle="info">Submit the search to see results.</StyledAlert>
            )}
            {(!isFetching && enableSearchResultFetching) && (
              <>
                {messages?.length > 0 ? (
                  <DWLogViewWidget effectiveTimerange={{ from: '', to: '', type: 'absolute' }}
                                   searchFilters={normalizedSearchFilters}
                                   messages={messages}
                                   messageFields={messageFields}
                                   setLoadingState={() => {}}
                                   onChangeConfig={onChangeConfig}
                                   config={logViewConfig}
                                   fields={Immutable.List()}
                                   editing={false} />
                ) : <StyledAlert>No messages have been found for selected filters.</StyledAlert>}
              </>
            )}
            {error && <StyledAlert bsStyle="danger">{error.responseMessage}</StyledAlert>}
          </FullHeightRow>
        </AutoRefreshProvider>
      </StyledPageContentLayout>
    </GridContainer>
  );
};

const DataWarehouseSearchPage = () => {
  const { config } = useSearchConfiguration();
  const { data: fieldTypes, isLoading } = useFieldTypes();

  return (
    <DocumentTitle title="Data Warehouse Search">
      {config && !isLoading ? <DataWarehouseSearch fieldTypes={fieldTypes} /> : <Spinner />}
    </DocumentTitle>
  );
};

export default DataWarehouseSearchPage;
