import React, { useContext, useEffect, useState } from 'react';

import useFetch from './common/hooks/useFetch';
import { ApiRoutes } from './common/Routes';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

import { renderOptions } from '../common/Options';
import FormWrap from '../common/FormWrap';
import ValidatedInput from '../common/ValidatedInput';

type StepAuthorizeProps = {
  onSubmit: (...args: any[]) => void;
  onChange: (...args: any[]) => void;
};

const StepAuthorize = ({
  onSubmit,
  onChange,
}: StepAuthorizeProps) => {
  const { formData } = useContext(FormDataContext);
  const [formError, setFormError] = useState(null);

  const { clientSecret } = formData;

  const subscriptions = [
    { label: 'Enterprise and GCC government plans', value: 'ENTERPRISE' },
    { label: 'GCC High government plan', value: 'GCC_HIGH' },
    { label: 'DoD government plan', value: 'DOD' },
  ];

  const [O365ContentTypesStatus, setO365ContentTypes] = useFetch(
    null,
    onSubmit,
    'POST',
    {
      o365_tenant_id: formData?.tenantId?.value || '',
      o365_client_id: formData?.clientId?.value || '',
      o365_client_secret: formData?.clientSecret?.value || '',
      o365_endpoint: formData?.subscriptionType?.value || '',
    },
  );

  useEffect(() => {
    setO365ContentTypes(null);

    if (O365ContentTypesStatus.error) {
      setFormError({
        full_message: O365ContentTypesStatus.error,
        nice_message: 'Unable to connect to Office 365 using provided configuration.',
      });
    }

    return () => {
      setFormError(null);
    };
  }, [O365ContentTypesStatus.error]);

  const handleSubmit = () => {
    setO365ContentTypes(ApiRoutes.INTEGRATIONS.O365.CONTENT_TYPES);
  };

  const isFormValid = formValidation.isFormValid([
    'o365Name',
    'tenantId',
    'clientId',
    'clientSecret',
    'subscriptionType',
  ], formData);

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Verify Connection &amp; Proceed"
              loading={O365ContentTypesStatus.loading}
              disabled={isFormValid}
              title=""
              error={formError}
              description="">

      <ValidatedInput id="o365Name"
                      type="text"
                      fieldData={formData.o365Name}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      defaultValue={clientSecret?.value}
                      required />

      <ValidatedInput id="tenantId"
                      type="text"
                      fieldData={formData.tenantId}
                      onChange={onChange}
                      label="Directory (tenant) ID"
                      help="The GUID of the tenant to which the content belongs."
                      autoComplete="off"
                      required />

      <ValidatedInput id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      help="The GUID of your application that created the subscription."
                      label="Application (client) ID"
                      required />

      <ValidatedInput id="clientSecret"
                      type="password"
                      onChange={onChange}
                      fieldData={formData.clientSecret}
                      label="Client Secret"
                      help="A secret string that the application uses to prove its identity when requesting a token."
                      required />

      <ValidatedInput type="select"
                      name="subscriptionType"
                      id="subscriptionType"
                      onChange={onChange}
                      fieldData={formData.subscriptionType}
                      help="Office 365 subscription plan for your organization."
                      required
                      label="Subscription Type">
        {renderOptions(subscriptions, 'Choose a Subscription')}
      </ValidatedInput>
    </FormWrap>
  );
};

export default StepAuthorize;
