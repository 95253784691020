import React, { useState, useCallback } from 'react';

import { ButtonToolbar, Table } from 'components/bootstrap';
import { Timestamp } from 'components/common';
import DaysOfData from 'data-warehouse/data-warehouse-overview/cells/DaysOfData';
import type { DataWarehouse } from 'data-warehouse/Types';
import RetrievalArchiveButton from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveButton';
import RetrievalArchiveModal from 'data-warehouse/data-warehouse-overview/actions/RetrievalArchiveModal';
import DeleteArchiveModal from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveModal';
import DeleteArchiveButton from 'data-warehouse/data-warehouse-overview/actions/DeleteArchiveButton';

type Props = {
  dataWarehouse: DataWarehouse,
}

const DataWarehouseSummary = ({ dataWarehouse }: Props) => {
  const [showRetrievalModal, setShowRetrievalModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleRetrievalModal = useCallback(() => setShowRetrievalModal((cur) => !cur), []);
  const toggleDeleteModal = useCallback(() => setShowDeleteModal((cur) => !cur), []);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Message Count</th>
            <th>Days of Data</th>
            <th>From</th>
            <th>To</th>
            <th className="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{dataWarehouse.message_count}</td>
            <td><DaysOfData datawarehouse={dataWarehouse} /></td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td><Timestamp dateTime={dataWarehouse.timestamp_from} /></td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td><Timestamp dateTime={dataWarehouse.timestamp_to} /></td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <td>
              <ButtonToolbar className="pull-right">
                <RetrievalArchiveButton onClick={toggleRetrievalModal} buttonType="button" />
                <DeleteArchiveButton onClick={toggleDeleteModal} buttonType="button" />
              </ButtonToolbar>
            </td>
          </tr>
        </tbody>
      </Table>
      {showRetrievalModal && (
        <RetrievalArchiveModal archiveStreamId={dataWarehouse.stream_id}
                               onToggleModal={toggleRetrievalModal} />
      )}
      {showDeleteModal && (
        <DeleteArchiveModal archiveStreamId={dataWarehouse.stream_id}
                            onToggleModal={toggleDeleteModal} />
      )}
    </>
  );
};

export default DataWarehouseSummary;
