import React from 'react';

type LDAPAdapterSummaryProps = {
  dataAdapter: {
    config: {
      ldap_ip: string;
      base_dn: string;
      ldap_connect_timeout: number;
      user_dn: string;
      user_passwd: any;
      ldap_port: number;
      tls: boolean;
      verify_certificate: boolean;
      ldap_pool_max_connections?: number;
    };
  };
};

const LDAPAdapterSummary = ({
  dataAdapter,
}: LDAPAdapterSummaryProps) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>LDAP IP Address</dt>
      <dd>{config.ldap_ip}</dd>
      <dt>LDAP Port</dt>
      <dd>{config.ldap_port} </dd>
      <dt>LDAP Connect Timeout</dt>
      <dd>{config.ldap_connect_timeout}</dd>
      <dt>LDAP Pool Max Connections</dt>
      <dd>{config.ldap_pool_max_connections}</dd>
      <dt>Base DN</dt>
      <dd>{config.base_dn || 'n/a'}</dd>
      <dt>User DN</dt>
      <dd>{config.user_dn}</dd>
      <dt>User Password</dt>
      <dd>******</dd>
      <dt>Use TLS?</dt>
      <dd>{config.tls ? 'Yes' : 'No'}</dd>
      <dt>Verify certificate?</dt>
      <dd>{config.verify_certificate ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

export default LDAPAdapterSummary;
