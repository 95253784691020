import * as React from 'react';
import type { SyntheticEvent } from 'react';

import { Link } from 'components/common/router';
import { Alert, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import Routes from 'routing/Routes';

type Props = {
  expiresIn: string
  enableReminderButton?: boolean,
  onReminderClick: (event: SyntheticEvent<HTMLButtonElement>) => void
}

const LicenseExpirationWarningNotification = ({ expiresIn, enableReminderButton = true, onReminderClick }: Props) => (
  <Alert bsStyle="warning" className="has-bm" title="Graylog License Expiration Warning">
    <p>
      Your Graylog license will expire in <strong>{expiresIn}</strong>.
      Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
      more information or contact your Graylog account manager.
      {enableReminderButton
          && (
          <Button bsSize="xsmall" className="pull-right" onClick={onReminderClick}>
            <Icon name="close" /> Remind me later
          </Button>
          )}
    </p>
  </Alert>
);

export default LicenseExpirationWarningNotification;
