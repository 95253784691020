import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES } from 'data-warehouse/Constants';
import type FetchError from 'logic/errors/FetchError';
import { defaultOnError } from 'util/conditional/onError';

type Options = {
  enabled: boolean,
}

export type FilterableFieldType = {
  field_name: string,
  class: string,
  in_memory_filter: boolean,
}

const fetchFilterableFields = () => fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.filterableFields().url));

const useDataWarehouseFilterableField = ({ enabled }: Options = { enabled: true }): {
  data: Array<FilterableFieldType>,
  refetch: () => void,
  isInitialLoading: boolean,
} => {
  const { data, refetch, isInitialLoading } = useQuery<Array<FilterableFieldType>, FetchError>(
    ['data-warehouse', 'filterable-field'],
    () => defaultOnError(fetchFilterableFields(), 'Loading Data Warehouse filterable field failed with status', 'Could not load Data Warehouse filterable field.'),
    {
      notifyOnChangeProps: ['data', 'error'],
      enabled,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
  });
};

export default useDataWarehouseFilterableField;
