/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import * as React from 'react';
import { useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';

import ModalButtonToolbar from 'components/common/ModalButtonToolbar';
import { Spinner } from 'components/common';
import { Button } from 'components/bootstrap';
import type { StyleProps } from 'components/bootstrap/Button';

type Scenario = 'submit' | 'submitAndGenerate' | 'submitAndGoToList';

type ActionButtonProps = {
  title: string,
  onClick: () => Promise<void>,
  bsStyle?: StyleProps,
  scenarioIsSubmitting: Scenario | undefined,
  scenario: Scenario
  setScenarioIsSubmitting: React.Dispatch<React.SetStateAction<Scenario>>
}

const ActionButton = ({ title, onClick, bsStyle, setScenarioIsSubmitting, scenario, scenarioIsSubmitting }: ActionButtonProps) => {
  const _onClick = async () => {
    setScenarioIsSubmitting(scenario);

    try {
      await onClick();
    } finally {
      setScenarioIsSubmitting(undefined);
    }
  };

  return (
    <Button onClick={_onClick}
            title={title}
            bsStyle={bsStyle}
            aria-label={title}
            disabled={!!scenarioIsSubmitting}>
      {scenarioIsSubmitting === scenario && <Spinner delay={0} text="" />}
      {title}
    </Button>
  );
};

type Props = {
  className?: string,
  leftCol?: React.ReactNode,
  onSubmit: (event?: SyntheticEvent) => Promise<void>,
  onSubmitAndGenerate: (event?: SyntheticEvent) => Promise<void>,
  onSubmitAndGoToList: (event?: SyntheticEvent) => Promise<void>,
  submitButtonText: React.ReactNode,
};

const ReportActions = ({
  className,
  leftCol,
  submitButtonText,
  onSubmitAndGoToList,
  onSubmit,
  onSubmitAndGenerate,
}: Props) => {
  const title = typeof submitButtonText === 'string' ? submitButtonText : undefined;
  const [scenarioIsSubmitting, setScenarioIsSubmitting] = useState<Scenario | undefined>();
  const PerformButton = useCallback(({ onClick, scenario, title: buttonTitle, bsStyle }: Pick<ActionButtonProps, 'onClick' | 'scenario' | 'title' | 'bsStyle'>) => (
    <ActionButton onClick={onClick}
                  setScenarioIsSubmitting={setScenarioIsSubmitting}
                  scenarioIsSubmitting={scenarioIsSubmitting}
                  scenario={scenario}
                  title={buttonTitle}
                  bsStyle={bsStyle} />
  ), [scenarioIsSubmitting]);

  return (
    <ModalButtonToolbar className={className}>
      {leftCol}
      <PerformButton onClick={onSubmit}
                     scenario="submit"
                     bsStyle="primary"
                     title={title} />
      <PerformButton onClick={onSubmitAndGenerate}
                     scenario="submitAndGenerate"
                     title={`${title} and download`} />
      <PerformButton onClick={onSubmitAndGoToList}
                     scenario="submitAndGoToList"
                     title={`${title} and close`} />
    </ModalButtonToolbar>
  );
};

export default ReportActions;
