import React from 'react';
import styled from 'styled-components';
import defaultTo from 'lodash/defaultTo';
import max from 'lodash/max';
import moment from 'moment';

import { Button, ControlLabel, FormControl, FormGroup, Radio } from 'components/bootstrap';
import { extractDurationAndUnit } from 'components/common/TimeUnitInput';
import { Pluralize, Select, TimeUnitInput } from 'components/common';
import { getValueFromInput } from 'util/FormsUtils';
import withTelemetry from 'logic/telemetry/withTelemetry';
import { getPathnameWithoutId } from 'util/URLUtils';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import withLocation from 'routing/withLocation';

const formatEventDefinitions = (eventDefinitions) => eventDefinitions.map((definition) => ({ label: definition.title, value: definition.id }));

const TIME_UNITS = ['HOURS', 'MINUTES', 'SECONDS'];

const FieldsetRule = styled.fieldset`
  margin-bottom: 10px;
`;

const ComplexRadio = styled(Radio)`
  input[type=radio] {
    bottom: 10px;
  }

  .form-inline {
    margin-bottom: 0;
  }
`;

const InlineSelect = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: middle;
`;

const InlineFormGroup = styled.div`
  margin-bottom: 15px;

  p {
    font-size: 14px;
    font-weight: initial;
  }

  .form-group:first-child, .form-group+*, *+.form-group {
    margin-right: 10px;
  }
}
`;

type CorrelationRuleProps = {
  number: number;
  correlationRule: any;
  eventDefinitions: any[];
  onChange: (...args: any[]) => void;
  onRemove: (...args: any[]) => void;
  sendTelemetry: (...args: any[]) => void;
  location: any;
};

class CorrelationRule extends React.Component<CorrelationRuleProps, {
  [key: string]: any;
}> {
  constructor(props) {
    super(props);

    const notForPeriod = defaultTo(props.correlationRule.not_for_period, 'PT1S');
    const { duration, unit } = extractDurationAndUnit(notForPeriod, TIME_UNITS);

    this.state = {
      occurrence: defaultTo(props.correlationRule.occurrence, 1),
      notForPeriod: notForPeriod,
      notForPeriodDuration: duration,
      notForPeriodUnit: unit,
    };
  }

  propagateChange = (ruleUpdate = {}) => {
    const { correlationRule, onChange } = this.props;
    const nextRule = { ...correlationRule, ...ruleUpdate };
    onChange(correlationRule.id, nextRule);
  };

  handleEventDefinitionChange = (nextEventDefinition) => {
    this.props.sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_EVENT_SELECTED, {
      app_pathname: getPathnameWithoutId(this.props.location.pathname),
      app_section: 'event-definition-condition',
      app_action_value: 'event-select',
    });

    this.propagateChange({ event_creator_equals: nextEventDefinition });
  };

  handleOccurrenceChange = (event) => {
    const value = getValueFromInput(event.target);

    this.props.sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_OCCURRENCE_TIME_CHANGED, {
      app_pathname: getPathnameWithoutId(this.props.location.pathname),
      app_section: 'event-definition-condition',
      app_action_value: 'occurrence-input',
      occurrence: value,
    });

    this.propagateChange({ occurrence: value });
    this.setState({ occurrence: value });
  };

  handleStateLogicChange = (event) => {
    const { occurrence, notForPeriod } = this.state;
    const value = getValueFromInput(event.target);
    let ruleUpdate;

    switch (value) {
      case 'not_and':
        this.props.sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_NO_OCCURRENCE_FOLLOWED_BY_EVENT_SELECTED, {
          app_pathname: getPathnameWithoutId(this.props.location.pathname),
          app_section: 'event-definition-condition',
          app_action_value: 'occurrenceFollowedByEvent-radio-button',
        });

        ruleUpdate = { state_logic: value, occurrence: undefined, not_for_period: undefined };
        break;
      case 'not_for':
        this.props.sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_NO_OCCURRENCE_IN_THE_NEXT_SELECTED, {
          app_pathname: getPathnameWithoutId(this.props.location.pathname),
          app_section: 'event-definition-condition',
          app_action_value: 'noOccurrenceInTheNext-radio-button',
        });

        ruleUpdate = { state_logic: value, occurrence: undefined, not_for_period: notForPeriod };
        break;
      default:
        ruleUpdate = { state_logic: undefined, occurrence: occurrence, not_for_period: undefined };
    }

    this.propagateChange(ruleUpdate);
  };

  handleNotForPeriodChange = (nextValue, nextUnit) => {
    this.props.sendTelemetry(TELEMETRY_EVENT_TYPE.EVENTDEFINITION_CONDITION.CORRELATION_NO_OCCURRENCE_IN_THE_NEXT_UNIT_CHANGED, {
      app_pathname: getPathnameWithoutId(this.props.location.pathname),
      app_section: 'event-definition-condition',
      app_action_value: 'noOccurrenceInTheNextUnit-select',
      new_unit: nextUnit,
    });

    const duration = moment.duration(max([nextValue, 1]), nextUnit).toISOString();
    this.propagateChange({ not_for_period: duration });

    this.setState({
      notForPeriodDuration: nextValue,
      notForPeriodUnit: nextUnit,
      notForPeriod: duration,
    });
  };

  handleRemove = () => {
    const { correlationRule, onRemove } = this.props;
    onRemove(correlationRule.id);
  };

  renderFirstRuleOptions = (correlationRule) => {
    const { occurrence } = this.state;

    return (
      <InlineFormGroup className="form-inline">
        <FormGroup>
          <FormControl.Static>Should occur at least</FormControl.Static>
        </FormGroup>
        <FormControl id={`${correlationRule.id}-occurrence`}
                     componentClass="input"
                     type="number"
                     name="occurrence"
                     value={occurrence}
                     onChange={this.handleOccurrenceChange} />
        <FormGroup>
          <FormControl.Static>
            <Pluralize singular="time" plural="times" value={occurrence} />.
          </FormControl.Static>
        </FormGroup>
      </InlineFormGroup>
    );
  };

  renderRegularRuleOptions = (correlationRule) => {
    const { occurrence, notForPeriodDuration, notForPeriodUnit } = this.state;

    return (
      <FormGroup>
        <ControlLabel>Should</ControlLabel>
        <ComplexRadio id={`${correlationRule.id}-regular-rule-type`}
                      name={`${correlationRule.id}-rule-type`}
                      value="regular"
                      checked={correlationRule.state_logic === undefined || correlationRule.state_logic === null}
                      onChange={this.handleStateLogicChange}>
          <InlineFormGroup className="form-inline">
            <FormGroup>
              <FormControl.Static>Occur at least</FormControl.Static>
            </FormGroup>
            <FormGroup>
              <FormControl id={`${correlationRule.id}-occurrence`}
                           componentClass="input"
                           type="number"
                           name="occurrence"
                           value={occurrence}
                           disabled={correlationRule.state_logic !== undefined && correlationRule.state_logic !== null}
                           onChange={this.handleOccurrenceChange} />
            </FormGroup>
            <FormGroup>
              <FormControl.Static>
                <Pluralize singular="time" plural="times" value={occurrence} />.
              </FormControl.Static>
            </FormGroup>
          </InlineFormGroup>
        </ComplexRadio>

        <Radio id={`${correlationRule.id}-not-and-rule-type`}
               name={`${correlationRule.id}-rule-type`}
               value="not_and"
               checked={correlationRule.state_logic === 'not_and'}
               onChange={this.handleStateLogicChange}>
          Not occur, and it is followed by another Event
        </Radio>

        <ComplexRadio id={`${correlationRule.id}-not-for-rule-type`}
                      name={`${correlationRule.id}-rule-type`}
                      value="not_for"
                      checked={correlationRule.state_logic === 'not_for'}
                      onChange={this.handleStateLogicChange}>
          <InlineFormGroup className="form-inline">
            <FormGroup>
              <FormControl.Static>Not occur in the next</FormControl.Static>
            </FormGroup>
            <FormGroup>
              <TimeUnitInput update={this.handleNotForPeriodChange}
                             value={notForPeriodDuration}
                             unit={notForPeriodUnit}
                             units={TIME_UNITS}
                             enabled={correlationRule.state_logic === 'not_for'}
                             clearable
                             hideCheckbox />
            </FormGroup>
          </InlineFormGroup>
        </ComplexRadio>
      </FormGroup>
    );
  };

  renderRuleOptions = (number, correlationRule) => {
    if (number === 1) {
      return this.renderFirstRuleOptions(correlationRule);
    }

    return this.renderRegularRuleOptions(correlationRule);
  };

  render() {
    const { number, correlationRule, eventDefinitions } = this.props;

    return (
      <FieldsetRule>
        <legend>
          Event #{number}
          {number !== 1 && (
            <span className="pull-right">
              <Button bsSize="xsmall" bsStyle="danger" onClick={this.handleRemove}>Delete</Button>
            </span>
          )}
        </legend>

        <InlineFormGroup className="form-inline">
          <FormGroup>
            <FormControl.Static>Event from</FormControl.Static>
          </FormGroup>
          <InlineSelect>
            <Select id={`${correlationRule.id}-event-definition`}
                    matchProp="label"
                    onChange={this.handleEventDefinitionChange}
                    options={formatEventDefinitions(eventDefinitions)}
                    value={correlationRule.event_creator_equals}
                    required />
          </InlineSelect>
        </InlineFormGroup>

        {this.renderRuleOptions(number, correlationRule)}
      </FieldsetRule>
    );
  }
}

export default withLocation(withTelemetry(CorrelationRule));
