import React from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import {
  SearchBarContainer,
  TimeRangeRow,
  SEARCH_BAR_GAP,
  SearchQueryRow,
  SearchInputAndValidationContainer,
  SearchButtonAndQuery,
} from 'views/components/searchbar/SearchBarLayout';
import TimeRangeFilter from 'views/components/searchbar/time-range-filter';
import type { TimeRange } from 'views/logic/queries/Query';
import StreamFilter from 'data-warehouse/search/StreamFilter';
import RefreshControls from 'views/components/searchbar/RefreshControls';
import SearchButton from 'views/components/searchbar/SearchButton';
import PluggableCommands from 'views/components/searchbar/queryinput/PluggableCommands';
import DataWarehouseQueryInput from 'data-warehouse/search/DataWarehouseQueryInput';
import { Button } from 'components/bootstrap';
import { DEFAULT_TIMERANGE } from 'views/Constants';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

export type FormValues = {
  timerange: TimeRange,
  stream: string | undefined,
  queryString: string | undefined
}

const StreamsAndRefresh = styled.div`
  display: flex;
  gap: ${SEARCH_BAR_GAP};
  flex: 1.5;
`;

const validate = (formValues: FormValues) => {
  const errors = {};

  if (!formValues.stream) {
    return ({ ...errors, stream: 'Stream is required' });
  }

  return errors;
};

type Props = {
  onSubmit: (formValues: FormValues, helpers: { resetForm: (params: { values: FormValues }) => void }) => void,
  initialValues: FormValues,
  isLoading: boolean,
  fieldTypes: Array<FieldTypeMapping>
}

const SearchBar = ({ onSubmit, initialValues, isLoading, fieldTypes }: Props) => (
  <Formik<FormValues> initialValues={initialValues}
                      onSubmit={onSubmit}
                      validate={validate}>
    {({
      dirty,
      isSubmitting,
      isValid,
      isValidating,
      handleSubmit,
      validateForm,
      setValues,
    }) => {
      const disableSearchSubmit = isSubmitting || isValidating || !isValid;

      const resetSearch = () => {
        setValues({
          timerange: DEFAULT_TIMERANGE,
          stream: undefined,
          queryString: undefined,
        }).then(() => {
          handleSubmit();
        });
      };

      return (
        <Form>
          <SearchBarContainer>
            <TimeRangeRow>
              <Field name="timerange">
                {({ field: { name, value, onChange }, meta: { error } }) => (
                  <TimeRangeFilter limitDuration={0}
                                   onChange={(nextTimeRange) => onChange({
                                     target: { value: nextTimeRange, name },
                                   })}
                                   value={value}
                                   hasErrorOnMount={!!error} />
                )}
              </Field>
              <StreamsAndRefresh>
                <StreamFilter />
                <RefreshControls />
              </StreamsAndRefresh>
            </TimeRangeRow>
            <SearchQueryRow>
              <SearchButtonAndQuery>
                <SearchButton disabled={disableSearchSubmit}
                              dirty={dirty}
                              displaySpinner={isSubmitting || isLoading} />
                <SearchInputAndValidationContainer>
                  <Field name="queryString">
                    {({ field: { name, value, onChange }, meta: { error } }) => (
                      <PluggableCommands usage="search_query">
                        {(customCommands) => (
                          <DataWarehouseQueryInput value={value}
                                                   fieldTypes={fieldTypes}
                                                   name={name}
                                                   onChange={onChange}
                                                   placeholder="Type your search query here and press enter. E.g.: http_response_code:403"
                                                   error={error}
                                                   isValidating={isValidating}
                                                   disableExecution={disableSearchSubmit}
                                                   validate={validateForm}
                                                   onExecute={handleSubmit as () => void}
                                                   commands={customCommands} />
                        )}
                      </PluggableCommands>
                    )}
                  </Field>
                </SearchInputAndValidationContainer>
                <Button onClick={resetSearch}>Reset</Button>
              </SearchButtonAndQuery>
            </SearchQueryRow>
          </SearchBarContainer>
        </Form>
      );
    }}
  </Formik>
);

export default SearchBar;
