import React, { createContext, useState } from 'react';

import type { SidebarContextType } from 'enterprise-integrations/types';

export const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

type SidebarProviderProps = {
  children: any;
};

export const SidebarProvider = ({
  children,
}: SidebarProviderProps) => {
  const [sidebar, setSidebar] = useState(<></>);

  const clearSidebar = () => {
    setSidebar(<></>);
  };

  return (
    <SidebarContext.Provider value={{ sidebar, clearSidebar, setSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
