import * as React from 'react';
import styled from 'styled-components';

import { Spinner } from 'components/common';
import { useGetSecurityEvent } from 'security-app/hooks/useSecurityEventsAPI';
import type { EventDefinition } from 'components/event-definitions/event-definitions-types';

import ActionsRow from './ActionsRow';
import EventDetails from './EventDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`;

type Props = {
  securityEventId: string,
  eventDefinitions: { [key: string]: EventDefinition },
  onReplaySearch?: () => void,
  orientation?: 'horz' | 'vert',
};

function Index({ securityEventId, eventDefinitions, onReplaySearch = () => {}, orientation = 'horz' }: Props) {
  const { securityEvent, loadingSecurityEvents } = useGetSecurityEvent(securityEventId);

  return (
    <Container>
      {loadingSecurityEvents ? <Spinner text="Loading Event ..." /> : (
        <>
          <ActionsRow securityEvent={securityEvent} onReplaySearch={onReplaySearch} orientation={orientation} />
          <EventDetails securityEvent={securityEvent} eventDefinitions={eventDefinitions} orientation={orientation} />
        </>
      )}
    </Container>
  );
}

export default Index;
