import React, { useState } from 'react';
import styled from 'styled-components';

import { Button, Panel } from 'components/bootstrap';
import { Spinner } from 'components/common';
import UsersDomain from 'domainActions/users/UsersDomain';
import CreateTokenForm from 'components/users/CreateTokenForm';
import type { Token } from 'stores/users/UsersStore';
import type User from 'logic/users/User';
import type { Input } from 'components/messageloaders/Types';
import AppConfig from 'util/AppConfig';
import ForwarderInputInformation
  from 'forwarder/wizard/select-forwarder-step/start-new-forwarder-steps/ForwarderInputInformation';

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

type Props = {
  eventKey: string,
  token?: Token,
  user?: User,
  onTokenCreate: (token: Token) => void,
  onStepComplete: () => void,
  forwarderInputs?: Array<Input>;
};

const CreateTokenStep = ({ eventKey, token, user, onTokenCreate, onStepComplete, forwarderInputs }: Props) => {
  const [isCreatingToken, setIsCreatingToken] = useState<boolean>(false);

  const noForwarderInputExist = !AppConfig.isCloud() && forwarderInputs?.length <= 0;

  if (!user) {
    return (
      <Panel eventKey={eventKey}>
        <Panel.Heading>
          <Panel.Title>
            <Panel.Toggle tabIndex={0}>2. Create API Token</Panel.Toggle>
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <Spinner />
        </Panel.Body>
      </Panel>
    );
  }

  const handleCreateToken = (tokenName: string) => {
    setIsCreatingToken(true);

    const promise = UsersDomain.createToken(user.id, tokenName);

    promise
      .then(onTokenCreate)
      .finally(() => setIsCreatingToken(false));
  };

  return (
    <Panel eventKey={eventKey}>
      <Panel.Heading>
        <Panel.Title>
          <Panel.Toggle tabIndex={0}>2. Create API Token</Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        {token ? (
          <p>You already created an API Token for this Forwarder, go to the next step to continue.</p>
        ) : (
          <>
            <p>
              Create an API Token to authenticate the Forwarder with Graylog.
            </p>
            {noForwarderInputExist && <ForwarderInputInformation forwarderInputs={forwarderInputs} />}
            <CreateTokenForm disableForm={noForwarderInputExist} creatingToken={isCreatingToken} onCreate={handleCreateToken} />
            <StyledButton disabled={noForwarderInputExist} bsStyle="primary" bsSize="sm" onClick={() => onStepComplete()}>Skip this step (not recommended)</StyledButton>
          </>
        )}
      </Panel.Body>
    </Panel>
  );
};

export default CreateTokenStep;
