import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedWorkspaceApp from './EmbeddedWorkspaceApp';

type ExternalLinkProps = {
  href: string;
  title: string;
};

const ExternalLink = ({
  href,
  title,
}: ExternalLinkProps) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

const WorkspaceApp = () => (
  <>
    <PageHeader title="Google Workspace Integrations">
      <span>This feature retrieves log records from Google Cloud Platform.</span>
      <p>
        You need to have <ExternalLink href="https://workspace.google.com/" title="Google Workspace Account" />,{' '}
      </p>
    </PageHeader>
    <EmbeddedWorkspaceApp />
  </>
);

export default WorkspaceApp;
