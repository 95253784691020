import * as React from 'react';
import styled from 'styled-components';

import useActivePerspective from 'components/perspectives/hooks/useActivePerspective';
import { IfPermitted } from 'components/common';
import { SearchForm } from 'security-app/components/common';
import { usePagination, useSetPagination } from 'security-app/components/common/contexts';

import BulkActions from './BulkActions';
import AddRulesMenu from './AddRulesMenu';

const Container = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .6rem;
`;

type Props = {
  hideSearchForm?: boolean;
  visibleBulkActions?: Array<string>,
};

function SearchRow({ hideSearchForm = false, visibleBulkActions }: Props) {
  const pagination = usePagination();
  const setPagination = useSetPagination();
  const { activePerspective } = useActivePerspective();
  const isSecurityPerspective = activePerspective.id === 'security';

  const onSearch = (query: string = null) => {
    setPagination({ ...pagination, page: 1, query });
  };

  return (
    <Container>
      <IfPermitted permissions="sigma_rules:edit">
        <ButtonsRow>
          <BulkActions visibleBulkActions={visibleBulkActions} />
          {isSecurityPerspective || <AddRulesMenu />}
        </ButtonsRow>
      </IfPermitted>
      {hideSearchForm || (
        <div style={{ marginLeft: 'auto' }}>
          <SearchForm onSearch={onSearch}
                      onReset={onSearch}
                      placeholder="Search for a rule..." />
        </div>
      )}
    </Container>
  );
}

export default SearchRow;
