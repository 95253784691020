import { useQuery } from '@tanstack/react-query';

import type { UserSearchFilter } from 'search-filter/types';
import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import { defaultOnError } from 'util/conditional/onError';

export const urlPrefix = '/plugins/org.graylog.plugins.searchfilters';
export const USER_FILTER_QUERY_KEY = 'user_filter_summary_query_key';

const fetchUserFilter = async (filterId: string): Promise<UserSearchFilter> => {
  const url = qualifyUrl(`${urlPrefix}/search_filters/${filterId}`);

  return fetch('GET', url);
};

const useUserSearchFilterQuery = (filterId: string): { data: UserSearchFilter, isFetching: boolean } => useQuery(
  [USER_FILTER_QUERY_KEY, filterId, 'details'],
  () => defaultOnError(fetchUserFilter(filterId), `Loading search filter with id ${filterId} failed with status`, 'Could not load search filter'),
);

export default useUserSearchFilterQuery;
