import { useQuery } from '@tanstack/react-query';

import { qualifyUrl } from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';

const fieldTypesUrl = qualifyUrl('/plugins/org.graylog.plugins.datawarehouse/data_warehouse/filterable_fields');

type DataWarehouseField = {
  field_name: string,
  class: string
}

const fetchAllFieldTypes = (): Promise<Array<DataWarehouseField>> => fetch('GET', fieldTypesUrl);

const mapFieldType = (fieldType: string) => {
  switch (fieldType) {
    case 'java.util.Collection':
      return 'list<string>';
    case 'java.lang.String':
      return 'string';
    default:
      return 'unknown';
  }
};

const deserializeFieldTypes = (fieldTypes: Array<DataWarehouseField>) => fieldTypes.map(({ field_name: fieldName, class: fieldType }) => FieldTypeMapping.fromJSON({
  name: fieldName,
  type: {
    type: mapFieldType(fieldType),
    properties: [],
    index_names: [],
  },
}));

const useFieldTypes = (): { data: Array<FieldTypeMapping>, refetch: () => void, isLoading?: boolean, isFetching?: boolean } => useQuery(
  ['data-warehouse', 'field-types'],
  () => fetchAllFieldTypes().then(deserializeFieldTypes),
  { staleTime: 30000 },
);

export default useFieldTypes;
