import __request__ from 'routing/request';
interface InputSummary {
    readonly creator_user_id: string;
    readonly node: string;
    readonly name: string;
    readonly created_at: string;
    readonly global: boolean;
    readonly attributes: {
        readonly [_key: string]: Object;
    };
    readonly id: string;
    readonly title: string;
    readonly type: string;
    readonly content_pack: string;
    readonly static_fields: {
        readonly [_key: string]: string;
    };
}
interface InputStatesList {
    readonly states: InputStateSummary[];
}
interface InputStateSummary {
    readonly detailed_message: string;
    readonly started_at: string;
    readonly id: string;
    readonly state: string;
    readonly message_input: InputSummary;
}
interface Object {
}
interface InputCreated {
    readonly id: string;
}
interface InputSetup {
    readonly id: string;
}
interface InputDeleted {
    readonly id: string;
}
/**
 * Get all input states of this node
 */
export function list(): Promise<InputStatesList> {
    return __request__('GET', '/system/inputstates', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Switch specified input to setup mode
 */
export function setup(inputId: string): Promise<InputSetup> {
    return __request__('PUT', `/system/inputstates/setup/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get input state for specified input id on this node
 */
export function get(inputId: string): Promise<InputStateSummary> {
    return __request__('GET', `/system/inputstates/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * (Re-)Start specified input on this node
 */
export function start(inputId: string): Promise<InputCreated> {
    return __request__('PUT', `/system/inputstates/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Stop specified input on this node
 */
export function stop(inputId: string): Promise<InputDeleted> {
    return __request__('DELETE', `/system/inputstates/${inputId}`, null, {}, {
        'Accept': ['application/json']
    });
}
