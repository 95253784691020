export const PADDING_BY_SIZE = {
  sm: { topBottom: 'xxs', leftRight: 'sm' },
  md: { topBottom: 'xs', leftRight: 'sm' },
  lg: { topBottom: 'xs', leftRight: 'md' },
  xl: { topBottom: 'sm', leftRight: 'md' },
};

const baseColorByStyle = (theme: any) => ({
  info: theme.colors.variant[theme.mode].info,
  success: theme.colors.variant[theme.mode].success,
  warning: theme.colors.variant[theme.mode].warning,
  danger: theme.colors.variant[theme.mode].danger,
});

export const getBGColorByVariant = (theme: any, variant: string) => {
  const colorMap = {
    ...baseColorByStyle(theme),
    default: theme.colors.background.secondaryNav,
  };

  return `${colorMap[variant]}${variant === 'default' ? 'ff' : '25'}`;
};

export const getTextColorByVariant = (theme: any, style: string) => {
  const colorMap = {
    ...baseColorByStyle(theme),
    default: theme.colors.text.primary,
  };

  return colorMap[style];
};
