import React, { useEffect, useContext, useState } from 'react';

import { qualifyUrl } from 'util/URLUtils';
import { Input } from 'components/bootstrap';
import Select from 'components/common/Select';
import fetch from 'logic/rest/FetchProvider';

import FormAdvancedOptions from './FormAdvancedOptions';
import { FormDataContext } from './context/FormData';
import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from './types';
import { ApiRoutes } from './common/Routes';
import INITIAL_FORMDATA from './_initialFormData';

import FormWrap from '../common/FormWrap';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = (
  {
    onSubmit,
    onChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);

  const { pollingInterval } = formData;
  const [contentTypes, setContentTypes] = useState(INITIAL_FORMDATA.availableContents.value);
  const { setFormData } = useContext<FormDataContextType>(FormDataContext);

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    fetch('GET', qualifyUrl(ApiRoutes.INTEGRATIONS.Salesforce.GET_LOG_TYPES))
      .then((response) => {
        const region = Object.keys(response).map((key) => ({ label: key, value: response[key] }));

        const sortedRegion = [...region];

        sortedRegion.sort((a, b) => {
          const labelA = a.label.toLowerCase();
          const labelB = b.label.toLowerCase();

          return labelA.localeCompare(labelB);
        });

        setRegions(sortedRegion);
      });
  }, []);

  const handleSubmit = () => {
    if (pollingInterval.value >= 5) {
      setFormError(null);
      onSubmit();
    } else {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message:
          'Minimum allowable polling interval is 5 minutes.',
      });
    }
  };

  const handleContentType = (val: string) => {
    const value = { value: val, dirty: false };
    setFormData('logTypes', value);
    setContentTypes(val);
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              description="">
      <label className="control-label" htmlFor="contents">Logs Types to Collect</label>
      <Select multi
              name="log_types"
              id="log_types"
              options={regions}
              value={contentTypes || ''}
              onChange={handleContentType} />
      <span className="help-block">The activity log for the above content types will be fetched.</span>
      <Input id="pollingInterval"
             name="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue || ''}
             min="1"
             max="60"
             step="1"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new data in Salesforce. The smallest allowable interval is 5 minute."
             label="Polling Interval" />

      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
