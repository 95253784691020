import React, { useContext } from 'react';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';

import type {
  HandleFieldUpdateType,
  FormDataContextType,
  AdvancedOptionsContextType,
  HandleSqsBatchSizeType,
} from '../common/utils/types';
import { FormDataContext } from '../common/context/FormData';
import { AdvancedOptionsContext } from '../common/context/AdvancedOptions';
import AdditionalFields from '../common/AdditionalFields';

interface Props {
  onChange: HandleFieldUpdateType;
  handleSqsMessageBatchSizeChange: HandleSqsBatchSizeType;
}

const StyledAdditionalFields = styled(AdditionalFields)`
    margin: 0 0 35px;
`;

const FormAdvancedOptions = (
  {
    onChange,
    handleSqsMessageBatchSizeChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const { isAdvancedOptionsVisible, setAdvancedOptionsVisibility } = useContext<AdvancedOptionsContextType>(AdvancedOptionsContext);

  const {
    genericS3ThrottleEnabled,
    overrideSource,
    sqsMessageBatchSize,
  } = formData;

  const handleToggle: (visible: boolean) => void = (visible) => {
    setAdvancedOptionsVisibility(visible);
  };

  const internalHandleSqsBatchSizeChange = (e) => {
    const { value } = e.target;
    onChange(e, formData);

    if (value >= 1 && value <= 10) {
      handleSqsMessageBatchSizeChange('');
    } else {
      handleSqsMessageBatchSizeChange('Please select SQS Message Batch Size between 1 - 10.');
    }
  };

  return (
    <StyledAdditionalFields title="Advanced Options"
                            visible={isAdvancedOptionsVisible}
                            onToggle={handleToggle}>

      <Input id="genericS3ThrottleEnabled"
             type="checkbox"
             value="enable-throttling"
             defaultChecked={genericS3ThrottleEnabled?.value}
             onChange={onChange}
             label="Enable Throttling"
             help="If enabled, no new messages will be read from this input until Graylog catches up with its message load. This is typically useful for inputs reading from files or message queue systems like AMQP or Kafka. If you regularly poll an external system, e.g. via HTTP, you normally want to leave this disabled." />
      <Input id="overrideSource"
             type="text"
             value={overrideSource?.value}
             onChange={onChange}
             label="Override Source (optional)"
             help="The source is set to the S3 bucket name by default. Set this if you want to override it with a custom value." />
      <Input id="sqsMessageBatchSize"
             type="number"
             min="1"
             value={sqsMessageBatchSize?.value}
             onChange={internalHandleSqsBatchSizeChange}
             label="SQS Message Batch Size"
             help="The maximum number of messages to query from SQS at a time. The maximum acceptable value is 10." />
    </StyledAdditionalFields>
  );
};

export default FormAdvancedOptions;
