import * as React from 'react';

import { ReadOnlyFormGroup } from 'components/common';

type ScriptNotificationDetailsProps = {
  notification: {
    config: {
      script_path?: string;
      script_args?: string;
      script_send_stdin?: boolean;
      script_timeout?: number;
    };
  };
};

const ScriptNotificationDetails = ({
  notification,
}: ScriptNotificationDetailsProps) => (
  <>
    <ReadOnlyFormGroup label="Script Path" value={notification.config?.script_path} />
    <ReadOnlyFormGroup label="Script Arguments" value={notification.config?.script_args} />
    <ReadOnlyFormGroup label="Send Alert Data Through STDIN" value={notification.config?.script_send_stdin} />
    <ReadOnlyFormGroup label="Script Timeout (milliseconds)" value={notification.config?.script_timeout} />
  </>
);

export default ScriptNotificationDetails;
