import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Spinner from 'components/common/Spinner';
import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import { Col, Row } from 'components/bootstrap';
import useQuery from 'routing/useQuery';
import type { TimeRangeQueryParameter } from 'views/logic/TimeRange';
import { timeRangeFromQueryParameter } from 'views/logic/TimeRange';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';
import useParams from 'routing/useParams';
import _deserializeWidgetConfig from 'common/utils/deserializeWidgetConfig';
import ExportingWidget from 'search/ExportWidgetAction/pdf-export-widget-renderer/ExportingWidget';
import { REPORT_TZ_FALLBACK, WIDGET_WIDTH } from 'report/Constants';
import type { ExportWidgetData, Orientation } from 'search/ExportWidgetAction/types';
import ErrorBoundary from 'export/ErrorBoundary';
import { RenderCompletionObserver } from 'components/visualizations';
import useExportWidget from 'search/ExportWidgetAction/pdf-export-widget-renderer/hooks/useExportWidget';
import ExportErrorPage from 'export/ExportErrorPage';
import WidgetProvidersByType from 'search/ExportWidgetAction/pdf-export-widget-renderer/WidgetProvidersByType';
import PageContextProviders from 'components/page/contexts/PageContextProviders';
import GlobalPDFStyles from 'report/common/GlobalPDFStyles';
import PDFExportThemeProvider from 'common/providers/PDFExportThemeProvider';

const CoverPage = styled.div`
  padding-top: 30%;

  h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 0.8em;
  }

  h2 {
    color: #555 !important;
    font-size: 1.6em;
    text-align: center;
  }
`;

export const Description = styled.p`
  white-space: pre-wrap;
`;

const InvisibleDiv = styled.div`
  display: none;
`;

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 30px
`;

type OptionalNowParameter = {
  now?: string,
};

type ReportRenderPageQuery = ({} | TimeRangeQueryParameter) & OptionalNowParameter;

const extractTimeRangeOverride = (query: ReportRenderPageQuery) => ('rangetype' in query
  ? timeRangeFromQueryParameter(query)
  : undefined);

const referenceTimeFromQueryParameter = (query: OptionalNowParameter) => moment(query.now);

const extractNow = (query: ReportRenderPageQuery) => ('now' in query
  ? referenceTimeFromQueryParameter(query)
  : undefined);

const WIDGET_ASPECT_RATIO = { portrait: 2 * (9 / 23), landscape: 1.5 * (1 / 3) };

const getWidgetSizes = (originalHeight: number, originalWidth: number, orientation: Orientation) => {
  const width = WIDGET_WIDTH[orientation];
  const originalRatio = (originalHeight && originalWidth) ? originalHeight / originalWidth : 0;

  const height = Math.min(originalRatio, WIDGET_ASPECT_RATIO[orientation]) * width;

  return ({ width, height });
};

const RenderedWidget = () => {
  const query = useQuery();
  const [isRenderComplete, setIsRenderComplete] = useState(false);
  const { exportCallId } = useParams();
  const timerangeOverride = useMemo(() => extractTimeRangeOverride(query), [query]);
  const now = useMemo(() => extractNow(query), [query]);
  const { isFetching, widgetData, widgetValues } = useExportWidget(exportCallId, timerangeOverride, now);

  const reportTz = widgetData?.timezone ?? REPORT_TZ_FALLBACK;

  const deserializedWidget = widgetData && ({ ...widgetData, config: _deserializeWidgetConfig<ExportWidgetData>(widgetData) });

  const isDataLoaded = !!widgetData && !!widgetValues?.result && !isFetching;
  const _orientation: Orientation = widgetData?.orientation ?? 'portrait';

  const handleRenderComplete = useCallback(() => {
    setIsRenderComplete(true);
  }, []);

  const { width, height } = getWidgetSizes(widgetData?.position?.height, widgetData?.position?.width, _orientation);

  return isDataLoaded ? (
    <PageContextProviders>
      <AutoRefreshDisabledProvider>
        <UserDateTimeProvider tz={reportTz}>
          <WidgetProvidersByType type={deserializedWidget.type}>
            <GlobalPDFStyles $orientation={_orientation} />
            <Row>
              <Col md={12}>
                <CoverPage>
                  <p className="text-center">All times are displayed in the time zone {reportTz}.</p>
                </CoverPage>
                <Container>
                  <RenderCompletionObserver onRenderComplete={handleRenderComplete}>
                    <ExportingWidget widget={deserializedWidget}
                                     value={widgetValues}
                                     height={height}
                                     width={width} />
                  </RenderCompletionObserver>
                </Container>
                {isRenderComplete && <InvisibleDiv id="render-complete" />}
              </Col>
            </Row>
          </WidgetProvidersByType>
        </UserDateTimeProvider>
      </AutoRefreshDisabledProvider>
    </PageContextProviders>
  ) : <Spinner />;
};

const ExportWidgetPage = () => (
  <ErrorBoundary FallbackComponent={ExportErrorPage}>
    <PDFExportThemeProvider>
      <RenderedWidget />
    </PDFExportThemeProvider>
  </ErrorBoundary>
);

export default ExportWidgetPage;
