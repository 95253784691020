import React from 'react';

import { Container, Section } from 'views/components/sidebar/SidebarNavigation';
import NavItem from 'views/components/sidebar/NavItem';
import { dataWarehouseLink } from 'data-warehouse/search/Constants';

const Sidebar = () => (
  <Container>
    <Section>
      <NavItem isSelected
               ariaLabel={`Open ${dataWarehouseLink.title}`}
               icon={dataWarehouseLink.icon}
               key={dataWarehouseLink.key}
               linkTarget={dataWarehouseLink.link}
               title={dataWarehouseLink.title} />
    </Section>
  </Container>
);

export default Sidebar;
