import * as React from 'react';
import { Field } from 'formik';

import { defaultCompare } from 'logic/DefaultCompare';
import { Select } from 'components/common';
import { Input } from 'components/bootstrap';

type Props = {
  disabled?: boolean,
  value?: Array<string>,
  streams: Array<{ key: string, value: string }>,
  onChange: (newStreamIds: Array<string>) => void,
};

const StreamSelectField = ({ disabled = false, value = [], streams, onChange }: Props) => {
  const selectedStreams = value.join(',');
  const placeholder = 'Select streams the retrieval operation should include.';
  const options = streams.sort(({ key: key1 }, { key: key2 }) => defaultCompare(key1, key2));

  const handleChange = (selected: string) => {
    onChange(selected === '' ? [] : selected.split(','));
  };

  return (
    <Field name="stream_ids">
      {({ name }) => (
        <Input id={name}
               label="Streams">
          <Select placeholder={placeholder}
                  disabled={disabled}
                  displayKey="key"
                  inputId="streams-ids"
                  onChange={handleChange}
                  options={options}
                  multi
                  value={selectedStreams} />
        </Input>
      )}
    </Field>
  );
};

export default StreamSelectField;
