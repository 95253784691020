import React from 'react';

type URLhausAdapterSummaryProps = {
  dataAdapter: {
    config: {
      feed_type: string;
      refresh_interval: number;
      case_insensitive_lookup: boolean;
    };
  };
};

const URLhausAdapterSummary = ({
  dataAdapter,
}: URLhausAdapterSummaryProps) => {
  const { config } = dataAdapter;

  return (
    <dl>
      <dt>URLhaus Feed Type</dt>
      <dd>{config.feed_type}</dd>
      <dt>Refresh Interval</dt>
      <dd>{config.refresh_interval} seconds </dd>
      <dt>Case Insensitive Lookups</dt>
      <dd>{config.case_insensitive_lookup ? 'Yes' : 'No'}</dd>
    </dl>
  );
};

export default URLhausAdapterSummary;
