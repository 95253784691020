import React, { useContext } from 'react';

import { Spinner } from 'components/common';
import { Label } from 'components/bootstrap';

import ForwardersContext from '../forwarders/ForwardersContext';
import type { ForwarderState } from '../Types';

type ForwarderStateBadgeProps = {
  forwarderId: string;
};

const stateToLabelStyle = (state?: ForwarderState): string => {
  switch (state) {
    case 'RUNNING':
    case 'CONNECTED':
      return 'success';
    case 'FAILED':
    case 'DISCONNECTED':
      return 'danger';
    case 'STARTING':
      return 'info';
    default:
      return 'warning';
  }
};

const stateToLabelText = (state?: ForwarderState): string => {
  switch (state) {
    case 'RUNNING':
      return 'Running';
    case 'FAILED':
      return 'Failed';
    case 'STARTING':
      return 'Starting';
    case 'CONNECTED':
      return 'Connected';
    case 'DISCONNECTED':
      return 'Disconnected';
    case 'STOPPED':
      return 'Not running';
    default:
      return 'Unknown';
  }
};

const ForwarderStateBadge = ({ forwarderId }: ForwarderStateBadgeProps) => {
  const { getForwarderContext } = useContext(ForwardersContext);
  const { state: forwarderState } = getForwarderContext(forwarderId);

  if (!forwarderState) {
    return <Spinner />;
  }

  return (
    <Label bsStyle={stateToLabelStyle(forwarderState)}>{stateToLabelText(forwarderState)}</Label>
  );
};

export default ForwarderStateBadge;
