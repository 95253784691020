import { useContext } from 'react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { useGetLatestBundle } from 'illuminate/hooks/useBundlesAPI';
import { Spinner, Icon, Pluralize } from 'components/common';
import { Button } from 'components/bootstrap';
import { BundleContext } from 'illuminate/context/BundleContext';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import useThreatRelatedIlluminatePacks from './useThreatRelatedIlluminatePacks';
import useThreatRelatedSigmaRules from './useThreatRelatedSigmaRules';

import type { CoverageEntity } from '../types';

export type StepKey = 'overview' | 'illuminateBundleInstall' | 'illuminateBundleEnable' | 'illuminatePacksEnable' | 'sigmaRulesEnable';

export const STEPS: Record<StepKey, { key: StepKey, title: (coverageEntity: CoverageEntity, isTactic: boolean) => React.ReactNode, index?: number }> = {
  overview: {
    key: 'overview',
    title: (coverageEntity, isTactic) => (coverageEntity
      ? <>Improve Threat Coverage For <i>{coverageEntity.name}</i> {isTactic ? 'Tactic' : 'Technique'}</>
      : 'Improve Threat Coverage For All Tactics'),
  },
  illuminateBundleInstall: {
    key: 'illuminateBundleInstall',
    title: () => 'Install latest Illuminate Bundle',
    index: 0,
  },
  illuminateBundleEnable: {
    key: 'illuminateBundleEnable',
    title: () => 'Enable latest Illuminate Bundle',
    index: 1,
  },
  illuminatePacksEnable: {
    key: 'illuminatePacksEnable',
    title: () => 'Enable Illuminate Packs',
    index: 2,
  },
  sigmaRulesEnable: {
    key: 'sigmaRulesEnable',
    title: () => 'Enable Sigma Rules',
    index: 3,
  },
};

const TimelineContainer = styled.div`
  margin-top: 20px;
`;

const StepTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TimelineItem = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

const StepIconContainer = styled.div<{ $isComplete: boolean }>(({ $isComplete, theme }) => css`
  width: 50px;
  color: ${$isComplete ? theme.colors.variant.success : theme.colors.variant.warning};
`);

const StepIcon = ({ isComplete }: { isComplete: boolean }) => (
  <StepIconContainer $isComplete={isComplete}>
    <Icon name={isComplete ? 'check_circle' : 'circle'}
          type="regular"
          size="2x" />
  </StepIconContainer>
);

const LeftCol = styled.div`
  flex: 1;
`;

const StepTitle = ({ title, enableConfBtn, onConfigure, highlightConfBtn, step }: {
  title: React.ReactNode,
  enableConfBtn: boolean,
  onConfigure: () => void,
  highlightConfBtn: boolean
  step: string
}) => {
  const sendTelemetry = useSendTelemetry();

  const _onConfigure = () => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.THREAT_COVERAGE.IMPROVE_COVERAGE_STEP_OPENED, {
      app_pathname: 'security',
      app_section: 'threat-coverage',
      event_details: { step },
    });

    onConfigure();
  };

  return (
    <StepTitleContainer>
      {title}
      {enableConfBtn && (
        <Button bsSize="xsmall" bsStyle={highlightConfBtn ? 'info' : 'default'} onClick={_onConfigure}>
          configure
        </Button>
      )}
    </StepTitleContainer>
  );
};

const useLatestBundleIsEnabled = () => {
  const { bundles, bundleVersion, loadingBundles } = useContext(BundleContext);
  const latestBundleVersion = bundles?.length > 0 ? bundles[bundles.length - 1].version : 'Unknown';

  return ({
    // The backend is providing the bundles sorted by release date.
    latestBundleIsEnabled: (bundleVersion && bundles.length > 0) ? bundles[bundles.length - 1]?.version === bundleVersion : false,
    isLoadingBundles: loadingBundles,
    latestBundleVersion,
  });
};

const useIlluminatePacksAreEnabled = () => {
  const { allPacks, notEnabledPacks, isInitialLoading } = useThreatRelatedIlluminatePacks();

  return ({
    amountAllPacks: allPacks.length,
    amountNotEnabledPacks: notEnabledPacks.length,
    illuminatePacksAreEnabled: allPacks.length > 0 && notEnabledPacks.length === 0,
    isLoadingPacks: isInitialLoading,
  });
};

const useThreatRelatedSigmaRulesAreEnabled = (coverageEntity: CoverageEntity) => {
  const { loadingRules, notEnabledRules, allRules } = useThreatRelatedSigmaRules(coverageEntity);

  return ({
    amountNotEnabledSigmaRules: notEnabledRules.length,
    amountAllSigmaRules: allRules.length,
    sigmaRulesAreEnabled: allRules.length > 0 && notEnabledRules.length === 0,
    isLoadingSigmaRules: loadingRules,
  });
};

const Description = styled.div(({ theme }) => css`
  color: ${theme.colors.text.secondary};
`);

const _activeStepIndex = (latestIlluminateBundleIsInstalled: boolean, latestBundleIsEnabled: boolean, illuminatePacksAreEnabled: boolean) => {
  if (!latestIlluminateBundleIsInstalled) {
    return STEPS.illuminateBundleInstall.index;
  }

  if (!latestBundleIsEnabled) {
    return STEPS.illuminateBundleEnable.index;
  }

  if (!illuminatePacksAreEnabled) {
    return STEPS.illuminatePacksEnable.index;
  }

  return STEPS.sigmaRulesEnable.index;
};

const StepsOverview = ({ setStep, coverageEntity, isTactic }: { setStep: (step: StepKey) => void, coverageEntity: CoverageEntity, isTactic: boolean }) => {
  const { isNew: illuminateUpdateIsAvailable, isInitialLoading } = useGetLatestBundle();
  const { bundleVersion } = useContext(BundleContext);
  const latestIlluminateBundleIsInstalled = !!bundleVersion && !illuminateUpdateIsAvailable;

  const { latestBundleIsEnabled, isLoadingBundles, latestBundleVersion } = useLatestBundleIsEnabled();
  const { illuminatePacksAreEnabled, isLoadingPacks, amountNotEnabledPacks, amountAllPacks } = useIlluminatePacksAreEnabled();
  const { sigmaRulesAreEnabled, isLoadingSigmaRules, amountNotEnabledSigmaRules, amountAllSigmaRules } = useThreatRelatedSigmaRulesAreEnabled(coverageEntity);

  if (isInitialLoading || isLoadingPacks || isLoadingBundles || isLoadingSigmaRules) {
    return <Spinner />;
  }

  const activeStepIndex = _activeStepIndex(latestIlluminateBundleIsInstalled, latestBundleIsEnabled, illuminatePacksAreEnabled);

  return (
    <div>
      <p>
        Follow these steps to increase your threat coverage.
      </p>

      <TimelineContainer>

        <TimelineItem>
          <StepIcon isComplete={latestIlluminateBundleIsInstalled} />
          <LeftCol>
            <StepTitle title={STEPS.illuminateBundleInstall.title(coverageEntity, isTactic)}
                       step={STEPS.illuminateBundleInstall.key}
                       onConfigure={() => setStep(STEPS.illuminateBundleInstall.key)}
                       enableConfBtn={!latestIlluminateBundleIsInstalled}
                       highlightConfBtn={activeStepIndex === STEPS.illuminateBundleInstall.index} />
            <Description>
              {latestIlluminateBundleIsInstalled
                ? <>The latest Illuminate Bundle <b>{latestBundleVersion}</b> has been installed.</>
                : <>There is a new Illuminate Bundle <b>{latestBundleVersion}</b> which should be installed.</>}
            </Description>
          </LeftCol>
        </TimelineItem>
        <TimelineItem>
          <StepIcon isComplete={latestBundleIsEnabled} />
          <LeftCol>
            <StepTitle title={STEPS.illuminateBundleEnable.title(coverageEntity, isTactic)}
                       step={STEPS.illuminateBundleEnable.key}
                       onConfigure={() => setStep(STEPS.illuminateBundleEnable.key)}
                       enableConfBtn={!latestBundleIsEnabled}
                       highlightConfBtn={activeStepIndex === STEPS.illuminateBundleEnable.index} />
            <Description>
              <span>
                {latestBundleIsEnabled
                  ? <>The latest installed Illuminate Bundle <b>{latestBundleVersion}</b> has been enabled.</>
                  : <>Please enable the latest installed Illuminate Bundle <b>{latestBundleVersion}</b></>}
              </span>
            </Description>
          </LeftCol>
        </TimelineItem>
        <TimelineItem>
          <StepIcon isComplete={illuminatePacksAreEnabled} />
          <LeftCol>
            <StepTitle title={STEPS.illuminatePacksEnable.title(coverageEntity, isTactic)}
                       step={STEPS.illuminatePacksEnable.key}
                       onConfigure={() => setStep(STEPS.illuminatePacksEnable.key)}
                       enableConfBtn={!illuminatePacksAreEnabled}
                       highlightConfBtn={activeStepIndex === STEPS.illuminatePacksEnable.index} />

            <Description>
              {amountAllPacks === 0 ? 'Please complete the previous steps first.' : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {illuminatePacksAreEnabled
                    ? 'All related Illuminate Packs, have been enabled.'
                    : <>There <Pluralize singular="is" plural="are" value={amountNotEnabledPacks} /> <b>{amountNotEnabledPacks}</b> threat coverage related Illuminate <Pluralize singular="Pack" plural="Packs" value={amountNotEnabledPacks} /> which <Pluralize singular="needs" plural="need" value={amountNotEnabledPacks} /> to be enabled.</>}
                </>
              )}

            </Description>
          </LeftCol>
        </TimelineItem>

        <TimelineItem>
          <StepIcon isComplete={sigmaRulesAreEnabled} />
          <LeftCol>
            <StepTitle title={STEPS.sigmaRulesEnable.title(coverageEntity, isTactic)}
                       step={STEPS.sigmaRulesEnable.key}
                       onConfigure={() => setStep(STEPS.sigmaRulesEnable.key)}
                       enableConfBtn={!sigmaRulesAreEnabled}
                       highlightConfBtn={activeStepIndex === STEPS.sigmaRulesEnable.index} />
            <Description>
              {amountAllSigmaRules === 0 ? 'Please complete the previous steps first.' : (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {sigmaRulesAreEnabled
                    ? 'All related Sigma Rules have been enabled.'
                    : <>There <Pluralize singular="is" plural="are" value={amountNotEnabledSigmaRules} /> <b>{amountNotEnabledSigmaRules}</b> related Sigma <Pluralize singular="Rule" plural="Rules" value={amountNotEnabledSigmaRules} /> which <Pluralize singular="needs" plural="need" value={amountNotEnabledSigmaRules} /> to be enabled.</>}
                </>
              )}
            </Description>
          </LeftCol>
        </TimelineItem>

      </TimelineContainer>
    </div>
  );
};

export default StepsOverview;
