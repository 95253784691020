import * as React from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonGroup } from 'components/bootstrap';
import { Icon } from 'components/common';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import type { StepKey } from './StepsOverview';

const StyledButtonToolbar = styled(ButtonGroup)(({ theme }) => css`
  margin-bottom: ${theme.spacings.sm};
  display: flex;
  justify-content: space-between;
`);

type Props = {
  onClickNext?: () => void,
  setStep: React.Dispatch<React.SetStateAction<StepKey>>,
  nextButtonText?: React.ReactNode,
}

const StepNavigation = ({ onClickNext = undefined, nextButtonText = undefined, setStep }: Props) => {
  const sendTelemetry = useSendTelemetry();

  const onClickPrev = () => {
    setStep('overview');

    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.THREAT_COVERAGE.IMPROVE_COVERAGE_STEP_BACK, {
      app_pathname: 'security',
      app_section: 'threat-coverage',
    });
  };

  return (
    <StyledButtonToolbar>
      <Button onClick={onClickPrev}>
        <Icon name="arrow_left_alt" size="sm" /> Back to overview
      </Button>
      {typeof onClickNext === 'function' && <Button onClick={onClickNext}>{nextButtonText ?? 'Next'}</Button>}
    </StyledButtonToolbar>
  );
};

export default StepNavigation;
