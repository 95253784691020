import React from 'react';

import ArchiveConfig from 'archive/components/ArchiveConfig';
import ArchiveCreationForm from 'archive/components/ArchiveCreationForm';
import ArchiveActions from 'archive/ArchiveActions';
import type { Indices } from 'stores/indices/IndicesStore';
import { Spinner, IfPermitted } from 'components/common';
import { Row, Col } from 'components/bootstrap';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { ARCHIVE_LICENSE_WARNING_TEXT, ARCHIVE_LICENSE_WARNING_TITLE } from 'archive/constants';

const _createArchive = (indexName: string) => {
  ArchiveActions.createArchive(indexName);
};

type Props = {
  indices?: Indices
};

const ArchiveCreationSection = ({ indices }: Props) => (
  <IfPermitted permissions={['archive:create', 'archiveconfig:read']} anyPermissions>
    <Row className="content">
      <IfPermitted permissions="archive:create">
        <Col md={6}>
          <LicenseCheck text={ARCHIVE_LICENSE_WARNING_TEXT}
                        title={ARCHIVE_LICENSE_WARNING_TITLE}
                        bsStyle="danger"
                        licenseSubject={LICENSE_SUBJECTS.archive}
                        hideChildren>
            {!indices?.length ? <Spinner text="Loading indices" /> : <ArchiveCreationForm indices={indices} createArchive={_createArchive} />}
          </LicenseCheck>
        </Col>
      </IfPermitted>
      <IfPermitted permissions="archiveconfig:read">
        <Col md={6}>
          <ArchiveConfig />
        </Col>
      </IfPermitted>
    </Row>
  </IfPermitted>
);

export default ArchiveCreationSection;
