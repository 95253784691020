import React from 'react';

import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { ARCHIVE_LICENSE_WARNING_TEXT, ARCHIVE_LICENSE_WARNING_TITLE } from 'archive/constants';
import { TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY } from 'stores/indices/IndicesStore';

type Props = {
  config: {
    max_number_of_indices: number,
    index_action: string,
  },
  rotationStrategyClass?: string
};

const ArchiveRetentionStrategySummary = ({ config: { index_action, max_number_of_indices }, rotationStrategyClass }: Props) => (
  <div>
    <dl>
      <dt>Index retention strategy:</dt>
      <dd>Archive</dd>
      {rotationStrategyClass !== TIME_BASED_SIZE_OPTIMIZING_ROTATION_STRATEGY && (
      <>
        <dt>Max number of indices:</dt>
        <dd>{max_number_of_indices}</dd>
      </>
      )}
      <dt>Index action:</dt>
      <dd>{index_action}</dd>
    </dl>
    <LicenseCheck text={ARCHIVE_LICENSE_WARNING_TEXT}
                  title={ARCHIVE_LICENSE_WARNING_TITLE}
                  bsStyle="danger"
                  licenseSubject={LICENSE_SUBJECTS.archive} />
  </div>
);

export default ArchiveRetentionStrategySummary;
