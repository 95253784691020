import React, { useContext } from 'react';

import CounterRate from './CounterRate';

import ForwardersContext from '../forwarders/ForwardersContext';

type Props = {
 forwarderId: string;
};

const ForwarderMetricContainer = ({ forwarderId }: Props) => {
  const { getForwarderContext } = useContext(ForwardersContext);
  const { metrics: forwarderMetrics } = getForwarderContext(forwarderId);

  const hasResults = forwarderMetrics.length > 0;

  if (!hasResults) {
    return <span>Not available</span>;
  }

  return (
    <span><CounterRate metric={forwarderMetrics[0]} suffix="msg/s" /></span>
  );
};

export default ForwarderMetricContainer;
