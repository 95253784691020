import React from 'react';

import { Spinner } from 'components/common';
import connect from 'stores/connect';
import { EventDefinitionsActions, EventDefinitionsStore } from 'stores/event-definitions/EventDefinitionsStore';

import CorrelationForm from './CorrelationForm';
import EventsLicenseStatus from './EventsLicenseStatus';
import { EventsLicenseStore, EventsLicenseActions } from './EventsLicenseStore';

type CorrelationFormContainerProps = {
  action: 'create' | 'edit';
  eventDefinition: any;
  validation: any;
  eventDefinitions?: any;
  eventsLicenseStatus: any;
  entityTypes: any;
  onChange: (...args: any[]) => void;
};

class CorrelationFormContainer extends React.Component<CorrelationFormContainerProps, {
  [key: string]: any;
}> {
  static defaultProps = {
    eventDefinitions: undefined,
  };

  componentDidMount() {
    EventDefinitionsActions.listAll();
    EventsLicenseActions.getLicenseStatus();
  }

  render() {
    const { eventDefinitions, eventsLicenseStatus, ...otherProps } = this.props;
    const isLoading = !eventDefinitions.all || eventsLicenseStatus.loading;

    if (isLoading) {
      return <Spinner text="Loading Event Definitions..." />;
    }

    return (
      <>
        <EventsLicenseStatus licenseStatus={eventsLicenseStatus} />
        <CorrelationForm {...otherProps} eventDefinitions={eventDefinitions.all} />
      </>
    );
  }
}

export default connect(CorrelationFormContainer, {
  eventDefinitions: EventDefinitionsStore,
  eventsLicenseStatus: EventsLicenseStore,
});
