import React from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import { Input } from 'components/bootstrap';
import { getValueFromInput } from 'util/FormsUtils';

type ScriptNotificationFormProps = {
  config: {
    script_path?: string;
    script_args?: string;
    script_send_stdin?: boolean;
    script_timeout?: number;
  };
  validation: {
    failed: boolean;
    errors?: {
      script_path?: string[];
      script_args?: string[];
      script_send_stdin?: string[];
      script_timeout?: string[];
    };
    error_context?: any;
  };
  onChange: (...args: any[]) => void;
};

class ScriptNotificationForm extends React.Component<ScriptNotificationFormProps, {
  [key: string]: any;
}> {
  static defaultConfig = {
    script_path: '',
    // eslint-disable-next-line no-template-curly-in-string
    script_args: '${event_definition_title} ${event_definition_type} ${event.timestamp} ${event.message} ${event.source}',
    script_send_stdin: false,
    script_timeout: 3000,
  };

  handleChange = (event) => {
    const { name } = event.target;
    this.propagateChange(name, getValueFromInput(event.target));
  };

  propagateChange = (key, value) => {
    const { config, onChange } = this.props;
    const nextConfig = cloneDeep(config);
    nextConfig[key] = value;
    onChange(nextConfig);
  };

  render() {
    const { config, validation } = this.props;

    return (
      <>
        <Input id="script-notification-v1-script_path"
               name="script_path"
               label="Script Path"
               type="text"
               bsStyle={validation.errors.script_path ? 'error' : null}
               help={get(validation, 'errors.script_path[0]', 'The path to the script within your Graylog scripts directory.')}
               value={config.script_path}
               onChange={this.handleChange}
               required />
        <Input id="script-notification-v1-script_args"
               name="script_args"
               label="Script Arguments"
               type="text"
               bsStyle={validation.errors.script_args ? 'error' : null}
               help={get(validation, 'errors.script_args[0]', 'The script arguments. Arguments should be space separated. See documentation.')}
               value={config.script_args}
               onChange={this.handleChange}
               required />
        <Input id="script-notification-v1-script_send_stdin"
               name="script_send_stdin"
               label="Send Alert Data Through STDIN"
               type="checkbox"
               bsStyle={validation.errors.script_send_stdin ? 'error' : null}
               help={get(validation, 'errors.script_send_stdin[0]', 'Send a JSON object containing alert data to the script through STDIN. See documentation for parsing information.')}
               value={config.script_send_stdin}
               checked={!!config.script_send_stdin}
               onChange={this.handleChange} />
        <Input id="script-notification-v1-script_timeout"
               name="script_timeout"
               label="Script Timeout (milliseconds)"
               type="text"
               bsStyle={validation.errors.script_timeout ? 'error' : null}
               help={get(validation, 'errors.script_timeout[0]', 'The script timeout in milliseconds.')}
               value={config.script_timeout}
               onChange={this.handleChange}
               required />
      </>
    );
  }
}

export default ScriptNotificationForm;
