import * as React from 'react';
import { useState, useCallback } from 'react';

import UserNotification from 'util/UserNotification';
import { Button } from 'components/bootstrap';
import { ConfirmDialog } from 'components/common';
import { deleteDataWarehouseBackend } from 'data-warehouse/data-warehouse-backend-config/hooks/useDataWarehouseBackendMutation';
import type { Backend } from 'archive/types';

type Props = {
  refetchDataWarehouseBackend: () => void,
  backend: Backend,
};

const DeleteDataWarehouseAction = ({ backend, refetchDataWarehouseBackend }: Props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const onDeleteSubmit = useCallback(() => {
    deleteDataWarehouseBackend(backend.id).then(() => {
    }).catch((error) => {
      UserNotification.error(`An error occurred while deleting Data Warehouse backend. ${error}`);
    }).finally(() => {
      setShowConfirmDelete(false);
      refetchDataWarehouseBackend();
    });
  }, [backend, refetchDataWarehouseBackend]);

  const onDelete = () => {
    setShowConfirmDelete(true);
  };

  return (
    <>
      <Button bsStyle="danger"
              onClick={onDelete}>Delete
      </Button>
      {showConfirmDelete && (
      <ConfirmDialog title={`Deleting ${backend.title} Data Warehouse backend, are you sure?`}
                     show
                     onConfirm={onDeleteSubmit}
                     onCancel={() => setShowConfirmDelete(false)}>
        <p>{`You are about to remove ${backend.title} Data Warehouse backend. This action cannot be undone.`}</p>
      </ConfirmDialog>
      )}
    </>
  );
};

export default DeleteDataWarehouseAction;
