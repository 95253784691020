import React from 'react';

import { LinkContainer } from 'components/common/router';
import { Col } from 'components/bootstrap';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';

import EditInputProfileForm from './EditInputProfileForm';

import type { InputProfile } from '../Types';
import { StyledHorizontalDl } from '../CommonStyledComponents';

type Props = {
    inputProfile: InputProfile
    onUpdate: () => void,
};

const InputProfileDetailsComponent = ({ inputProfile, onUpdate }: Props) => (
  <>
    <Col md={12}>
      <div className="pull-right">
        <IfPermitted permissions="inputprofiles:edit">
          <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
            <EditInputProfileForm inputProfile={inputProfile} onSave={onUpdate} buttonBsStyle="success" />
          </LinkContainer>
        </IfPermitted>
      </div>
      <h2>Details</h2>
    </Col>
    <Col md={6}>
      <StyledHorizontalDl>
        <dt>Title:</dt>
        <dd>{inputProfile.title}</dd>
        <dt>Description:</dt>
        <dd>{inputProfile.description || 'No description given'}</dd>
      </StyledHorizontalDl>
    </Col>
  </>
);

export default InputProfileDetailsComponent;
