import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { Button, Col, Row } from 'components/bootstrap';
import type { InputFormContent, InputProfile } from 'forwarder/Types';
import InputForm from 'forwarder/inputs/InputForm';
import InputsTable from 'forwarder/inputs/InputsTable';
import { ForwarderInputsActions } from 'forwarder/stores/ForwarderInputsStore';

import { ForwarderWizardContext } from '../ForwarderWizardProvider';
import { StyledTitle } from '../StyledWizardComponents';

const StyledAddInputButton = styled(Button)`
    display: block;
    margin-bottom: 20px;
`;

type Props = {
  inputProfile: InputProfile,
  onInputsCreated: () => void,
};

const ACTIONS = { NEW: 'new', LIST: 'list', EDIT: 'edit' };

const InputsManagement = ({ inputProfile, onInputsCreated }: Props) => {
  const { inputTypes, inputDescriptions } = useContext(ForwarderWizardContext);
  const [inputs, setInputs] = useState([]);
  const [action, setAction] = useState(ACTIONS.NEW);
  const [selectedInput, setSelectedInput] = useState(undefined);

  const toggleNewInputForm = () => {
    setAction(action === ACTIONS.NEW ? ACTIONS.LIST : ACTIONS.NEW);
  };

  const showEditInputForm = (input) => {
    setSelectedInput(input);
    setAction(ACTIONS.EDIT);
  };

  const hideEditInputForm = () => {
    setSelectedInput(undefined);
    setAction(ACTIONS.LIST);
  };

  const handleInputCreate = (input: InputFormContent) => {
    ForwarderInputsActions.create(inputProfile.id, input)
      .then((persistedInput) => {
        setInputs([...inputs, persistedInput]);
        toggleNewInputForm();
      });
  };

  const handleInputUpdate = (updatedInput: InputFormContent) => {
    if (!selectedInput) {
      throw new Error('Tried to update input but no input was selected.');
    }

    ForwarderInputsActions.update(inputProfile.id, selectedInput.id, updatedInput)
      .then((persistedInput) => {
        setInputs(inputs.map((input) => (input.id === persistedInput.id ? persistedInput : input)));
        hideEditInputForm();
      });
  };

  const handleInputDelete = (input) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete input ${input.title}? This action cannot be undone.`)) {
      ForwarderInputsActions.delete(inputProfile.id, input)
        .then(() => {
          setInputs(inputs.filter((i) => i.id !== input.id));
        });
    }
  };

  switch (action) {
    case ACTIONS.NEW:
      return (
        <Row>
          <Col md={12}>
            <StyledTitle>Add Input to {inputProfile.title}</StyledTitle>
            <InputForm action="create"
                       inputTypes={inputTypes}
                       inputDescriptions={inputDescriptions}
                       onCancel={toggleNewInputForm}
                       onSubmit={handleInputCreate} />
          </Col>
        </Row>
      );

    // @ts-expect-error fallthrough is on purpose
    case ACTIONS.EDIT:
      if (selectedInput) {
        return (
          <Row>
            <Col md={12}>
              <StyledTitle>
                Edit Input {selectedInput.title}
                <small> ({inputTypes[selectedInput.type]}) </small>
                in {inputProfile.title}
              </StyledTitle>
              <InputForm action="edit"
                         input={selectedInput}
                         inputTypes={inputTypes}
                         inputDescriptions={inputDescriptions}
                         onCancel={hideEditInputForm}
                         onSubmit={handleInputUpdate} />
            </Col>
          </Row>
        );
      }

    // Default to inputs table if selectedInput is not set
    // eslint-disable-next-line no-fallthrough
    default:
      // Render inputs table by default
      return (
        <Row>
          <Col md={7}>
            <StyledTitle>Inputs in {inputProfile.title}</StyledTitle>
            <InputsTable inputs={inputs}
                         inputTypes={inputTypes}
                         onInputEdit={showEditInputForm}
                         onInputDelete={handleInputDelete}
                         withActions />
            <StyledAddInputButton onClick={toggleNewInputForm}>Add another Input</StyledAddInputButton>
            <Button bsStyle="primary" onClick={onInputsCreated}>Save Inputs</Button>
          </Col>
        </Row>
      );
  }
};

export default InputsManagement;
