import React from 'react';
import { useFormikContext } from 'formik';

import { FormikInput } from 'components/common';
import type { DataTieringFormValues } from 'components/indices/data-tiering';
import { LICENSE_SUBJECTS } from 'license/constants';
import LicenseCheck from 'license/LicenseCheck';
import { DATA_TIERING_LICENSE_TITLE, DATA_TIERING_LICENSE_TEXT, DATA_TIERING_UPSELL_TITLE, DATA_TIERING_UPSELL_TEXT } from 'data-tiering/constants';

import useDataTiering from './hooks/useDataTiering';
import RepositoryManagement from './RepositoryManagement';
import WarmTierReadinessInfo from './WarmTierReadinessInfo';

type DataTiering = {
  data_tiering: DataTieringFormValues
}

type FormValues<T extends string | undefined> = T extends undefined ? DataTiering : T extends string ? { [Key in T]: DataTiering } : never

const TiersConfigurationFields = <ValuesPrefix extends string | undefined, >({ valuesPrefix } : { valuesPrefix?: ValuesPrefix }) => {
  const { readiness } = useDataTiering();

  const { values } = useFormikContext<FormValues<ValuesPrefix>>();

  const formValue = (field: keyof DataTieringFormValues) => {
    if (valuesPrefix) {
      return values[valuesPrefix as string]?.data_tiering?.[field];
    }

    return values?.data_tiering?.[field];
  };

  const fieldName = (field: keyof DataTieringFormValues) => {
    if (valuesPrefix) {
      return `${valuesPrefix}.data_tiering.${field}`;
    }

    return `data_tiering.${field}`;
  };

  const validateMinDaysInHotTier = (value: number) => {
    const errors = [];

    if (value < 0) {
      errors.push('Negative numbers are not allowed.');
    }

    if (value > formValue('index_lifetime_min')) {
      errors.push('Min. days in hot tier needs to be smaller than min. days in storage.');
    }

    if (errors.length > 0) {
      return errors.join(' ');
    }

    return '';
  };

  return (
    <>
      <WarmTierReadinessInfo />
      {readiness.data?.ready && (
      <>
        {formValue('warm_tier_enabled') ? (
          <LicenseCheck text={DATA_TIERING_LICENSE_TEXT}
                        title={DATA_TIERING_LICENSE_TITLE}
                        licenseSubject={LICENSE_SUBJECTS.enterprise}
                        bsStyle="danger" />
        ) : (
          <LicenseCheck text={DATA_TIERING_UPSELL_TEXT}
                        title={DATA_TIERING_UPSELL_TITLE}
                        licenseSubject={LICENSE_SUBJECTS.enterprise} />
        )}
        <FormikInput type="checkbox"
                     id="data-tiering-warm-tier-enabled"
                     label="Enable Warm Tier"
                     name={fieldName('warm_tier_enabled')}
                     help="Enable Warm Tier and use Searchable Snapshots?" />
        {formValue('warm_tier_enabled') && (
        <>
          <FormikInput type="number"
                       id="data-tiering-index-hot_lifetime_min"
                       label="Min. days in Hot Tier"
                       name={fieldName('index_hot_lifetime_min')}
                       min={0}
                       max={formValue('index_lifetime_min')}
                       validate={(value) => validateMinDaysInHotTier(parseInt(value, 10))}
                       help="How many days at minimum your data should be stored in the Hot Tier?"
                       required />
          <RepositoryManagement valuesPrefix={valuesPrefix} />
        </>
        )}
      </>
      )}
    </>
  );
};

export default TiersConfigurationFields;
