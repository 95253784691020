import React, { useContext, useState } from 'react';

import { Input } from 'components/bootstrap';

import FormAdvancedOptions from './FormAdvancedOptions';

import type {
  ErrorMessageType,
  FormDataContextType,
  HandleFieldUpdateType,
  HandleSubmitType,
} from '../common/utils/types';
import FormWrap from '../common/FormWrap';
import { FormDataContext } from '../common/context/FormData';

interface Props {
  onSubmit: HandleSubmitType;
  onChange: HandleFieldUpdateType;
}

const StepSubscribe = (
  {
    onSubmit,
    onChange,
  }: Props,
) => {
  const { formData } = useContext<FormDataContextType>(FormDataContext);
  const [formError, setFormError] = useState<ErrorMessageType>(null);
  const { ingestAlerts, pollingInterval } = formData;

  const handleSubmit = () => {
    if (pollingInterval.value >= 1) {
      setFormError(null);
      onSubmit();
    } else {
      setFormError({
        full_message: 'Please provide valid polling interval',
        nice_message:
          'Minimum allowable polling interval is 1 minutes.',
      });
    }
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Proceed"
              title=""
              error={formError}
              disabled={!!formError}
              description="">
      <Input id="ingestAlerts"
             type="checkbox"
             value="ingest-alerts"
             defaultChecked={ingestAlerts?.value}
             onChange={onChange}
             label="Ingest Alerts"
             help="Ingest Sophos Alerts in addition to Events." />
      <Input id="pollingInterval"
             name="pollingInterval"
             type="number"
             value={pollingInterval.value || pollingInterval.defaultValue || ''}
             min="1"
             max="60"
             step="1"
             onChange={onChange}
             required
             help="Determines how often (in minutes) Graylog will check for new logs from Sophos Central. The smallest allowable interval is 1 minute."
             label="Polling Interval" />
      <FormAdvancedOptions onChange={onChange} />
    </FormWrap>
  );
};

export default StepSubscribe;
