import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Row, Col } from 'security-app/components/common/FormBaseStyles';

const CollapsibleContent = styled(Col)<{ $maxHeight: number }>`
  max-height: ${({ $maxHeight }) => $maxHeight || 0}px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
`;

const TitleRow = styled(Row)`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacings.sm};
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondaryNav};
  }
`;

const Arrow = styled(Icon)<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(${({ $expanded }) => ($expanded ? '-180' : '0')}deg);
  transition: transform 0.3s ease-out;
  margin-left: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

type Props = {
  children: React.ReactNode,
  title?: React.ReactNode,
  disabled?: boolean,
};

function Collapsible({ title = undefined, disabled = false, children }: Props) {
  const ref = React.useRef(null);
  const [show, setShow] = React.useState<boolean>(false);
  const [maxHeight, setMaxHeight] = React.useState<number>(0);

  React.useEffect(() => {
    if (show) setMaxHeight(ref.current.scrollHeight);
    else setMaxHeight(0);
  }, [show]);

  const onClick = () => {
    if (disabled) return;
    setShow(!show);
  };

  return (
    <Col $width="100%" $gap="0.3rem">
      <TitleRow $align="center"
                $justify="space-between"
                $width="100%"
                onClick={onClick}>
        {title}
        <Arrow title="toggle details"
               name={disabled ? 'unknown_med' : 'keyboard_arrow_down'}
               type="regular"
               $expanded={show} />
      </TitleRow>
      <CollapsibleContent ref={ref} $maxHeight={maxHeight} $width="100%">
        {children}
      </CollapsibleContent>
    </Col>
  );
}

export default Collapsible;
