import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedGmailApp from './EmbeddedGmailApp';

type ExternalLinkProps = {
  href: string;
  title: string;
};

const ExternalLink = ({
  href,
  title,
}: ExternalLinkProps) => <a href={href} rel="noopener noreferrer" target="_blank">{title}</a>;

const GmailApp = () => (
  <>
    <PageHeader title="Google Workspace Gmail Integrations">
      <span>This feature retrieves log records from Google Workspace Gmail Logs</span>
      <p>
        You need to have <ExternalLink href="https://workspace.google.com/" title="Google Workspace Account" />,{' '}
        You need to have <ExternalLink href="https://console.cloud.google.com/" title="Google Cloud Platform" />,{' '}
      </p>
    </PageHeader>
    <EmbeddedGmailApp />
  </>
);

export default GmailApp;
