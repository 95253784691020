import React from 'react';

import PageHeader from 'components/common/PageHeader';

import EmbeddedSophosApp from './EmbeddedSophosApp';

const SophosApp = () => (
  <>
    <PageHeader title="Sophos Input">
      <span>This feature retrieves log messages from Sophos central.</span>
    </PageHeader>
    <EmbeddedSophosApp />
  </>
);

export default SophosApp;
