import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import UserNotification from 'util/UserNotification';
import { qualifyUrl } from 'util/URLUtils';
import { DATA_WAREHOUSE_API_ROUTES, DATA_WAREHOUSE_BACKEND_CONFIG_QUERY_KEYS, DATA_WAREHOUSE_CONFIG_QUERY_KEYS } from 'data-warehouse/Constants';
import type { DataWarehouseActiveBackend } from 'data-warehouse/Types';

const updateDataWarehouseBackendConfig = async (activeBackend: string) => (
  fetch('PUT', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backendConfig.base().url), { active_backend: activeBackend })
);

const fetchDataWarehouseBackendConfig = async () => (
  fetch('GET', qualifyUrl(DATA_WAREHOUSE_API_ROUTES.DataWarehouseAPIController.backendConfig.base().url))
);

const useUpdateDataWarehouseBackendConfig = (successHandler: () => void = undefined) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, error } = useMutation(updateDataWarehouseBackendConfig, {
    onSuccess: () => {
      UserNotification.success('Successfully updated Data Warehouse backend config');
      queryClient.invalidateQueries(DATA_WAREHOUSE_CONFIG_QUERY_KEYS);

      if (successHandler) {
        successHandler();
      }
    },
    onError: (err: Error) => {
      UserNotification.error(`Error updating Data Warehouse backend config: ${err.toString()}`, 'Unable to update Data Warehouse backend config.');
    },
  });

  return {
    mutateAsync,
    isLoading,
    error,
  };
};

const useDataWarehouseBackendConfig = () => {
  const { data, refetch, isInitialLoading, isSuccess, error } = useQuery<DataWarehouseActiveBackend, Error>(
    [...DATA_WAREHOUSE_BACKEND_CONFIG_QUERY_KEYS],
    () => fetchDataWarehouseBackendConfig(),
    {
      keepPreviousData: true,
    },
  );

  return ({
    data,
    refetch,
    isInitialLoading,
    isSuccess,
    error,
  });
};

export {
  useDataWarehouseBackendConfig,
  useUpdateDataWarehouseBackendConfig,
};
