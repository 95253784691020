import * as React from 'react';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { REPORT_TZ_FALLBACK, DEFAULT_ORIENTATION } from 'report/Constants';
import AutoRefreshDisabledProvider from 'report/common/AutoRefreshDisabledProvider';
import UserDateTimeProvider from 'contexts/UserDateTimeProvider';
import ReportParameters from 'report/report-render-page/ReportParameters';
import ReportWidgets from 'report/report-render-page/ReportWidgets';
import type { WidgetRef } from 'report/types';
import GlobalPDFStyles from 'search/ExportWidgetAction/pdf-export-widget-renderer/GlobalPDFStyles';
import { REPORT_PREVIEW_ID } from 'report/report-creation/ReportPreview';
import type { ReportFormValues } from 'report/report-creation/ReportFormContent';
import CoverPage from 'report/common/CoverPage';
import Toc from 'report/common/Toc';
import usePaperDimensions from 'report/report-creation/common/usePaperDimensions';

const InvisibleDiv = styled.div`
  display: none;
`;

const useUpdateRenderComplete = (widgets: Array<WidgetRef> = []) => {
  const [renderedWidgets, setRenderedWidgets] = useState<Array<string>>([]);

  const onWidgetRenderComplete = useCallback((widgetId: string) => () => {
    if (!renderedWidgets.includes(widgetId)) {
      setRenderedWidgets((prevWidgets) => ([...prevWidgets, widgetId]));
    }
  }, [renderedWidgets]);

  return {
    renderComplete: widgets?.length === 0 || widgets.length === renderedWidgets.length,
    onWidgetRenderComplete,
  };
};

type Props = {
  handleTocClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
  preview?: boolean,
  report: ReportFormValues,
  widgetResultProvider: React.ComponentType<{ widgetId: string, dashboardId: string, children: React.ReactNode }>,
  width?: number,
}

const Page = styled.div<{ $height: number }>(({ $height }) => css`
  page-break-after: always;
  ${$height ? `min-height: ${$height}px;` : ''}
`);

const RenderedReport = ({ report, preview = false, width, handleTocClick, widgetResultProvider }: Props) => {
  const reportTz = report.timezone ?? REPORT_TZ_FALLBACK;

  const { onWidgetRenderComplete, renderComplete } = useUpdateRenderComplete(report?.widgets);
  const paperDimensions = usePaperDimensions(report.layout?.pageSize, report.layout?.orientation);
  const previewHeight = preview ? paperDimensions.height : undefined;

  return (
    <AutoRefreshDisabledProvider>
      <UserDateTimeProvider tz={reportTz}>
        <GlobalPDFStyles $orientation={report.layout?.orientation ?? DEFAULT_ORIENTATION}
                         $rootSelector={preview ? `#${REPORT_PREVIEW_ID}` : undefined}
                         $printHeader={report.layout?.printHeader}
                         $printFooter={report.layout?.printFooter} />
        <Page $height={previewHeight}>
          <CoverPage title={report.title}
                     subtitle={report.subtitle}
                     description={report.description}
                     logo={report.logo} />
        </Page>
        {report.layout?.printToc && report.widgets.length > 0 && (
          <Page $height={previewHeight}>
            <Toc widgets={report.widgets} handleTocClick={handleTocClick} />
          </Page>
        )}
        {report.widgets.length > 0 && (
          <>
            {report.parameterValues && (
              <ReportParameters parameters={report.parameters}
                                parameterValues={report.parameterValues} />
            )}
            <ReportWidgets widgets={report.widgets}
                           width={width}
                           widgetResultProvider={widgetResultProvider}
                           positions={report.positions}
                           orientation={report.layout?.orientation ?? DEFAULT_ORIENTATION}
                           hideWidgetQuery={report.hideWidgetQuery}
                           hideWidgetDescription={report.hideWidgetDescription}
                           onWidgetRenderComplete={onWidgetRenderComplete} />
          </>
        )}
        {renderComplete && <InvisibleDiv id="render-complete" />}
      </UserDateTimeProvider>
    </AutoRefreshDisabledProvider>
  );
};

export default RenderedReport;
