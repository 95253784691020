import React, { useCallback, useContext, useMemo } from 'react';
import { Form, Formik } from 'formik';

import Routes from 'routing/Routes';
import FormWarningsContext from 'contexts/FormWarningsContext';
import { Col, Row } from 'components/bootstrap';
import { FormSubmit } from 'components/common';
import FormWarningsProvider from 'contexts/FormWarningsProvider';
import type { SearchFilter } from 'views/types';
import validateSearchFilterForm from 'search-filter/validateForm';
import TitleFormGroup from 'search-filter/components/form-elements/TitleFormGroup';
import DescriptionFormGroup from 'search-filter/components/form-elements/DescriptionFormGroup';
import useUserDateTime from 'hooks/useUserDateTime';
import PluggableStoreProvider from 'components/PluggableStoreProvider';
import View from 'views/logic/views/View';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import Search from 'views/logic/search/Search';
import useHistory from 'routing/useHistory';

import QueryStringFormGroup from '../form-elements/QueryStringFromGroup';

export type FormValues = Pick<SearchFilter, 'queryString' | 'title' | 'description'>

type Props = {
  children?: React.ReactNode,
  disableSubmitButton?: boolean,
  initialValues?: Partial<FormValues>,
  submitButtonText?: string,
  submitLoadingText?: string,
  onSubmit: (searchFilter: FormValues) => Promise<void>
}

const PseudoStoreProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const view = useMemo(() => View.builder().search(Search.create()).build(), []);
  const executionState = useMemo(() => SearchExecutionState.empty(), []);

  return (
    <PluggableStoreProvider view={view} isNew initialQuery="none" executionState={executionState}>
      {children}
    </PluggableStoreProvider>
  );
};

const UserSearchFilterForm = ({
  children,
  initialValues = {},
  submitButtonText = 'Save',
  submitLoadingText = 'Saving...',
  onSubmit,
  disableSubmitButton = false,
}: Props) => {
  const { setFieldWarning } = useContext(FormWarningsContext);
  const { userTimezone } = useUserDateTime();
  const history = useHistory();

  const handleCancel = useCallback(() => history.push(Routes.pluginRoute('MY-FILTERS')), [history]);
  const validate = useCallback((values: FormValues) => validateSearchFilterForm(values, { setFieldWarning, userTimezone }), [userTimezone, setFieldWarning]);

  return (
    <Formik onSubmit={onSubmit}
            validateOnMount
            validate={validate}
            initialValues={initialValues}>
      {({ isValid, isSubmitting, values }) => {
        const disableSubmit = disableSubmitButton || !isValid || !values.queryString;

        return (
          <Form className="form form-horizontal">
            <PseudoStoreProvider>
              <QueryStringFormGroup />
            </PseudoStoreProvider>
            <TitleFormGroup />
            <DescriptionFormGroup />
            {children}
            <Row>
              <Col md={9} mdOffset={3}>
                <FormSubmit submitButtonText={submitButtonText}
                            disabledSubmit={disableSubmit}
                            submitLoadingText={submitLoadingText}
                            isSubmitting={isSubmitting}
                            isAsyncSubmit
                            onCancel={handleCancel} />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const UserSearchFilterFormWrapper = (props: Props) => (
  <FormWarningsProvider>
    <UserSearchFilterForm {...props} />
  </FormWarningsProvider>
);

export default UserSearchFilterFormWrapper;
