import UserNotification from 'util/UserNotification';
import { AuthenticationActions } from 'stores/authentication/AuthenticationStore';
import { onOidcBackendUpdate } from 'authentication/components/oidc/bindings';

import type { ConfigFormValues, OidcSubmitPayload } from '../types';

export const handleCreate = async (payload: OidcSubmitPayload, formValues: ConfigFormValues, _backendGroupSyncIsActive: boolean, shouldUpdateGroupSync: boolean | undefined = true) => {
  const notifyOnSuccess = () => UserNotification.success('Authentication service was updated successfully.', 'Success');
  const notifyOnError = (error) => UserNotification.error(`Updating authentication service failed with status: ${error}`, 'Error');

  try {
    const result = await AuthenticationActions.create(payload);

    if (result.backend && formValues.synchronizeGroups && shouldUpdateGroupSync) {
      return onOidcBackendUpdate(false, formValues, result.backend.id, formValues.type).then(notifyOnSuccess);
    }

    notifyOnSuccess();

    return result;
  } catch (error) {
    notifyOnError(error);
    throw error;
  }
};

export const handleUpdate = async (payload: OidcSubmitPayload, backendId: string, formValues: ConfigFormValues, backendGroupSyncIsActive: boolean, shouldUpdateGroupSync: boolean | undefined = true) => {
  const notifyOnSuccess = () => UserNotification.success('Authentication service was updated successfully.', 'Success');
  const notifyOnError = (error) => UserNotification.error(`Updating authentication service failed with status: ${error}`, 'Error');

  try {
    const result = await AuthenticationActions.update(backendId, {
      ...payload,
      id: backendId,
    });

    if (result.backend && shouldUpdateGroupSync) {
      return onOidcBackendUpdate(backendGroupSyncIsActive, formValues, result.backend.id, formValues.type).then(notifyOnSuccess);
    }

    notifyOnSuccess();

    return result;
  } catch (error) {
    notifyOnError(error);
    throw error;
  }
};
