import * as React from 'react';

import { ReadOnlyFormGroup } from 'components/common';
import { usePagination } from 'security-app/components/common/contexts';
import {
  useGetInvestigations,
  useGetUsers,
  useGetTeams,
  useGetPriorities,
} from 'security-app/hooks/useInvestigationsAPI';
import type { EventNotificationTypes } from 'components/event-notifications/types';

function CreateInvestigationNotificationDetails({ notification }: React.ComponentProps<EventNotificationTypes['detailsComponent']>) {
  const localPagination = usePagination();

  const { investigations } = useGetInvestigations(localPagination);
  const investigationsMap = React.useMemo(() => ({
    ...investigations.reduce((acc, investigation) => ({
      ...acc,
      [investigation.id]: { label: investigation.name },
    }), {}),
  }), [investigations]);

  const { users } = useGetUsers(true);
  const { teams } = useGetTeams(true);
  const usersTeamsMap = React.useMemo(() => ({
    ...users.reduce((acc, user) => ({ ...acc, [user.id]: { label: user.full_name, icon: 'user' } }), {}),
    ...teams.reduce((acc, team) => ({ ...acc, [team.id]: { label: team.name, icon: 'users' } }), {}),
  }), [users, teams]);

  const { priorities } = useGetPriorities(true);
  const getPriorityText = React.useCallback((value: number) => (value ? priorities.find((priority) => priority.priority === value)?.text : ''), [priorities]);

  return (
    <>
      <ReadOnlyFormGroup label="Create a New Investigation for Every Alert"
                         value={notification.config.create_new_investigation ? <em>True</em> : <em>False</em>} />
      {!notification.config.create_new_investigation && (
        <ReadOnlyFormGroup label="Investigation"
                           value={investigationsMap[notification.config.investigation_id]?.label} />
      )}
      <ReadOnlyFormGroup label="Assign Investigation To"
                         value={usersTeamsMap[notification.config.investigation_assigned_to]?.label} />
      <ReadOnlyFormGroup label="Investigation Priority"
                         value={getPriorityText(notification.config.investigation_priority)} />
      <ReadOnlyFormGroup label="Investigation Status" value={notification.config.investigation_status} />
    </>
  );
}

export default CreateInvestigationNotificationDetails;
