import { useQuery } from '@tanstack/react-query';

import fetch from 'logic/rest/FetchProvider';
import { qualifyUrl } from 'util/URLUtils';
import type { TimeRange } from 'views/logic/queries/Query';
import type FetchError from 'logic/errors/FetchError';
import { defaultCompare } from 'logic/DefaultCompare';
import type { LogViewMessage } from 'logview/types';

export const PAGE_SIZE = 100;
const searchResultsUrl = (streamId: string) => qualifyUrl(`/plugins/org.graylog.plugins.datawarehouse/data_warehouse/query/${streamId}?limit=${PAGE_SIZE}`);

const formatResponse = (
  response: { messages: Array<{ message: { id: string, timestamp: string } & { [fieldName: string]: unknown } }>},
): Array<LogViewMessage> => response.messages?.sort(
  ({ message: { timestamp: timestamp1 } },
    { message: { timestamp: timestamp2 } }) => defaultCompare(timestamp2, timestamp1),
).map(({ message }) => {
  const { id, ...rest } = message;

  return ({ message: { ...rest, _id: id } });
});

export const fetchSearchResults = (
  { fields, stream, timerange, queryString, searchAfter }:
  { fields: Array<string>; stream: string, timerange: TimeRange, queryString: string, searchAfter?: { id: string, timestamp: string }
}) => fetch('POST', searchResultsUrl(stream), {
  timerange,
  fields,
  query: queryString,
  search_after: searchAfter,
}).then(formatResponse);

const useSearchResults = (
  searchFilters: { stream: string, timerange: TimeRange, queryString: string },
  fields: Array<string>,
  enabled: boolean,
): {
  error: FetchError,
  data: Array<LogViewMessage>,
  refetch: () => void,
  isFetching: boolean
} => useQuery({
  queryKey: ['data-warehouse', searchFilters],
  queryFn: () => fetchSearchResults({
    fields,
    stream: searchFilters.stream,
    timerange: searchFilters.timerange,
    queryString: searchFilters.queryString,
  }),
  keepPreviousData: true,
  retry: 0,
  enabled,
});

export default useSearchResults;
