import React from 'react';
import styled from 'styled-components';

import { Label } from 'components/bootstrap';
import IconButton from 'components/common/IconButton';
import type Role from 'logic/roles/Role';
import type Team from 'security/logic/Team';

type Props = {
  team: Team,
  onDeleteItem?: (team: Team) => void,
  roles: Array<Role>,
}
const StyledLabel = styled(Label)`
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
`;

const PaginatedTeamItem = ({ team, onDeleteItem, roles }: Props) => {
  const deleteButton = typeof onDeleteItem === 'function'
    ? <IconButton onClick={() => onDeleteItem(team)} name="close" title={`Remove ${team.name}`} />
    : null;

  return (
    <tbody>
      <tr>
        <td>{team.name}</td>
        <td>{team.description}</td>
        <td>{roles.map((role) => <StyledLabel key={role.id}>{role.name}</StyledLabel>)}</td>
        {deleteButton ? <td>{deleteButton}</td> : <td />}
      </tr>
    </tbody>
  );
};

export default PaginatedTeamItem;
