import * as React from 'react';

import { INPUT_WIZARD_STEPS } from 'components/inputs/InputSetupWizard';

import { ActivateIlluminateStep, SelectCategoryStep } from './steps';

export default {
  inputSetupWizard: {
    steps: {
      [INPUT_WIZARD_STEPS.SELECT_CATEGORY]: {
        key: INPUT_WIZARD_STEPS.SELECT_CATEGORY,
        title: (
          <>
            Select Category
          </>
        ),
        component: (
          <SelectCategoryStep />
        ),
      },
      [INPUT_WIZARD_STEPS.ACTIVATE_ILLUMINATE]: {
        key: INPUT_WIZARD_STEPS.ACTIVATE_ILLUMINATE,
        title: (
          <>
            Activate Illuminate
          </>
        ),
        component: (
          <ActivateIlluminateStep />
        ),
      },
    },
  },
};
