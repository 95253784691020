import React from 'react';

import { Panel } from 'components/bootstrap';
import { Icon } from 'components/common';

import { StyledPanel } from '../../CommonStyledComponents';

const InputProfileHelpPanel = () => (
  <StyledPanel bsStyle="info">
    <Panel.Heading>
      <Panel.Title componentClass="h3"><Icon name="info" /> Input Profiles</Panel.Title>
    </Panel.Heading>
    <Panel.Body>
      <p>
        An Input Profile is a set of Inputs that can be shared across many Forwarders. Using Input Profiles
        you can quickly add new Forwarders without the need of configure separate Inputs for each Forwarder.
      </p>
    </Panel.Body>
  </StyledPanel>
);

export default InputProfileHelpPanel;
